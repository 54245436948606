import "moment/locale/pt-br";

import moment, { Moment } from "moment";

const format = {
	currency: (centsValue: number) => (centsValue / 100).toLocaleString(
		window !== undefined ? window.navigator.language : "pt-BR",
		{
			maximumFractionDigits: 2,
			minimumFractionDigits: 2,
		},
	),
	formatCurrencyBR: (currentNumber: number) => new Intl.NumberFormat("pt-br", {
		style: "currency",
		currency: "BRL",
	}).format(currentNumber),
	currencyForBR: (centsValue: number) => (centsValue / 100).toLocaleString("pt-BR", {
		currency: "BRL",
		style: "currency",
		maximumFractionDigits: 2,
		minimumFractionDigits: 2,
	}),
	decimal: (value: number) => ((value / 100).toLocaleString(
		"pt-BR",
		{
			style: "decimal",
			maximumFractionDigits: 2,
			minimumFractionDigits: 2,
		})
	),
	dateCalculator: (initialDate: any, endDate: any) => {
		const diffInMs  = initialDate - endDate;
		const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
		const result = Math.abs(diffInDays);
		return parseInt(result.toString());
	},
	phone: (phone?: string) => {
		if (phone){
			const numberFormat = phone.replace(/\D/g,"");
			const formatNumber = numberFormat.length === 11 ? numberFormat.match(/(\d{2})(\d{5})(\d{4})/) : numberFormat.match(/(\d{2})(\d{4})(\d{4})/);
			return formatNumber !== null ? "("+ formatNumber[1]+") "+formatNumber[2]+"-"+formatNumber[3] : "";
		} else {
			return "";
		}
	},
	expiryDate: (date: Date) => `${moment(date).endOf("hour").fromNow()}`,
	dateWithHour: (date: Date | null) => moment(date).format("DD/MM/YYYY HH[h]"),
	date: (date: Date | null | undefined) => `${moment(date).format("L")}`,
	dateWithoutYear: (date: Date | null | undefined) => `${moment(date).format("DD/MM")}`,
	utcDate: (date: Date | null | undefined) => `${moment.utc(date).format("L")}`,
	hour: (date: Date | null | Moment) => `${moment(date).format("h:mm a")}`,
	timezoneConverter: (hour: string | null) => {
		const getDate = moment(`1001-01-01 ${hour}`).subtract(3, "hours");
		const convertToHour = format.hour(getDate);
		return convertToHour;
	},
	documentNumber:(cpf: string) => {
		cpf = cpf.replace(/[^\d]/g, "");
		return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
	},
	verifyIfHasExpired: (date: Date) => moment().isAfter(moment(date), "minute"),
	removeCharactersExceptLetterNumber: (character: string) => character.replace(/[^a-zA-Z0-9]/g, ""),
	cleanDecimal: (unCleanAmount: string) => unCleanAmount.replace(/\D/g, ""),
	convertStringToDate: (dateString: string) => {
		const minutes = dateString.slice(3,-3);
		const hours = dateString.slice(0,-6);

		const convert = moment({hour: +hours, minute: +minutes});
		const convertToDate = convert.toDate();
		return convertToDate;
	},

};

export default format;
