import React from "react";
import { observer } from "mobx-react-lite";
import { Grid } from "@chakra-ui/react";
import { EnumSelect, TextInput } from "~/components";
import strings from "~/resources/strings";
import api from "~/resources/api";

type FieldType = "name"|"streetNumber" | "phone" | "street" | "complementary" | "neighborhood"  | "city" | "zipcode" | "email"|"documentNumber" | "services";

interface FormValues {
	field: (field: FieldType) => {
		name: FieldType;
		onChange: (element: {
			target: {
				value: string;
			};
		}) => void;
		value: string;
		error: string | null;
	};
}
interface IProps {
	isLoading: boolean;
	formValues: FormValues;
	state: api.StateUF;
	onChangeEnum: (item: api.StateUF) => void;
}

export const ClinicAdressForm: React.FC<IProps> = observer((props) => {
	const {
		formValues,
		isLoading,
		state,
		onChangeEnum,
	} = props;

	const pageStrings = strings.common.fields;

	return (
		<Grid
			gridTemplateColumns={{
				md:"repeat(2,1fr)",
			}}
			gap={5}
		>
			<TextInput
				labelText={pageStrings.street}
				labelProps={{ fontWeight: "bold" }}
				variant="filled"
				type="text"
				isDisabled={isLoading}
				errorText={formValues.field("street").error}
				{...formValues.field("street")}
			/>
			<TextInput
				labelText={pageStrings.streetNumber}
				labelProps={{ fontWeight: "bold" }}
				variant="filled"
				type="number"
				isDisabled={isLoading}
				errorText={formValues.field("streetNumber").error}
				{...formValues.field("streetNumber")}
			/>
			<TextInput
				labelText={pageStrings.complementary}
				labelProps={{ fontWeight: "bold" }}
				variant="filled"
				type="text"
				isDisabled={isLoading}
				errorText={formValues.field("complementary").error}
				{...formValues.field("complementary")}
			/>
			<TextInput
				labelText={pageStrings.neighborhood}
				labelProps={{ fontWeight: "bold" }}
				variant="filled"
				type="text"
				isDisabled={isLoading}
				errorText={formValues.field("neighborhood").error}
				{...formValues.field("neighborhood")}
			/>
			<TextInput
				labelText={pageStrings.city}
				labelProps={{ fontWeight: "bold" }}
				variant="filled"
				type="text"
				isDisabled={isLoading}
				errorText={formValues.field("city").error}
				{...formValues.field("city")}
			/>
			<TextInput
				labelText={pageStrings.zipcode}
				labelProps={{ fontWeight: "bold" }}
				variant="filled"
				type="text"
				mask="99999-999"
				isDisabled={isLoading}
				errorText={formValues.field("zipcode").error}
				{...formValues.field("zipcode")}
			/>
			<EnumSelect
				items={api.allValuesStateUF()}
				tranlateEnum={api.translateStateUF}
				onChangeEnum={onChangeEnum}
				currentValue={state}
				label={pageStrings.state}
			/>
		</Grid>
	);
});
