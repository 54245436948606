import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import {
	Button,
	Flex,
	Heading,
} from "@chakra-ui/react";
import {
	CentralizedCard,
	ClinicAdressForm,
	ImagePicker,
	Label,
	TextArea,
	TextInput,
} from "~/components";
import strings from "~/resources/strings";
import { useHistory } from "~/hooks/useHistory";
import Store from "./store";

const CreateOrEdit: React.FC = () => {
	const { id } = useParams<{id: string}>();
	const history = useHistory();
	const store = useLocalObservable(() => new Store(id || ""));
	const commonStrings = strings.common;
	const pageStrings = strings.pages.clinics;

	const onSucess = () => history.goBack();

	return (
		<CentralizedCard
			button={(
				<Button
					minW={{ base: "100%", md: 280 }}
					size="lg"
					mt={10}
					isLoading={store.loader.isLoading}
					onClick={() => store.createOrEditClinic(onSucess)}
				>
					{commonStrings.buttons.saveOrEdit(!!id)}
				</Button>
			)}
		>
			<Heading fontSize="md" color="primary.500">{pageStrings.createOrEdit.title(!!id)}</Heading>
			<TextInput
				labelText={commonStrings.fields.name}
				labelProps={{ fontWeight: "bold" }}
				variant="filled"
				type="text"
				isDisabled={store.loader.isLoading}
				errorText={store.formShelf.field("name").error}
				{...store.formShelf.field("name")}
			/>
			<TextInput
				labelText={commonStrings.fields.cnpj}
				labelProps={{ fontWeight: "bold" }}
				variant="filled"
				type="text"
				mask="99.999.999/9999-99"
				isDisabled={store.loader.isLoading}
				errorText={store.formShelf.field("documentNumber").error}
				{...store.formShelf.field("documentNumber")}
			/>
			<TextInput
				labelText={commonStrings.fields.email}
				labelProps={{ fontWeight: "bold" }}
				variant="filled"
				type="email"
				isDisabled={store.loader.isLoading}
				errorText={store.formShelf.field("email").error}
				{...store.formShelf.field("email")}
			/>
			<TextArea
				labelText={commonStrings.fields.services}
				labelProps={{ fontWeight: "bold" , color: "gray.800"}}
				variant="filled"
				isDisabled={store.loader.isLoading}
				errorText={store.formShelf.field("services").error}
				{...store.formShelf.field("services")}
			/>
			<TextInput
				labelText={commonStrings.fields.phone}
				labelProps={{ fontWeight: "bold" }}
				variant="filled"
				type="text"
				mask="(99) 9999-9999"
				isDisabled={store.loader.isLoading}
				errorText={store.formShelf.field("phone").error}
				{...store.formShelf.field("phone")}
			/>
			<Heading fontSize="md" color="primary.500">{commonStrings.fields.address}</Heading>
			<ClinicAdressForm
				formValues={{
					field: store.formShelf.field,
				}}
				isLoading={store.loader.isLoading}
				state={store.state.value}
				onChangeEnum={store.state.setValue}
			/>
			<Flex direction="column">
				<Label
					fontWeight="bold"
					marginBottom={1}
					color="primary.500"
				>
					{strings.components.imagePicker.selectAnImage}
				</Label>
				<ImagePicker
					pickImage={store.imageShelf.getPickerFields().pick}
					src={store.imageShelf.src}
				/>
			</Flex>
		</CentralizedCard>
	);
};

export default observer(CreateOrEdit);
