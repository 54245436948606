import Details from "./Details";
import TableView from "./TableView";
import CreateOrEdit from "./CreateOrEdit";

export const ClinicsShowInSite = {
	TableView,
	CreateOrEdit,
	Details,
};

