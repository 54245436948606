import React from "react";
import { observer } from "mobx-react-lite";
import { Flex, Td } from "@chakra-ui/react";
import strings from "~/resources/strings";
import api from "~/resources/api";
import format from "~/resources/format";
import { TableModel } from "~/components";

const TableView: React.FC = () => {
	const pageStrings = strings.pages.clinics;

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<TableModel
				header={pageStrings.table.header}
				tableTitle={pageStrings.table.title}
				initStoreSettings={{
					apiGetAllMethod: api.getAllShowInSiteClinicAdminUsers,
				}}
				renderRow={(item) => (
					<>
						<Td whiteSpace="nowrap">{item.name}</Td>
						<Td>{item.clinic?.code}</Td>
						<Td>{item.email}</Td>
						<Td whiteSpace="nowrap">{format.phone(item.clinic?.phone)}</Td>
						<Td whiteSpace="nowrap">{item.documentNumber}</Td>
					</>
				)}
				hasEdit
				onAdd
				hasDetails
			/>
		</Flex>
	);
};

export default observer(TableView);
