import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "~/resources/api";
import strings from "~/resources/strings";
import { showInfoToast } from "~/resources/toast";
import { useGlobalStore } from "../contexts/useGlobalContext";

export const useAuthAdminMarketingMasterRoute = ( onSuccess?: () => void) => {
	const { authStore } = useGlobalStore();
	const navigate = useNavigate();

	useEffect(() => {
		if (!authStore.loader.isLoading && authStore.currentAdminUser) {
			if (authStore.currentAdminUser.type !== api.AdminUserType.marketingMaster) {
				navigate("/login", { replace: true });
				showInfoToast(strings.error.currentUserIsNotAdminMarketingMaster);
				authStore.logout(() => {});
			}
			if (onSuccess) {
				onSuccess();
			}
		}
	}, [authStore.loader.isLoading, authStore.currentAdminUser]);

	return authStore.loader.isLoading;
};

