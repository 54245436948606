import { useGlobalStore } from "~/contexts/useGlobalContext";
import api from "~/resources/api";
import strings from "~/resources/strings";

interface ILinkMenu {
	text: string;
	path: string;
}

const useMainRoutes = ():  ILinkMenu[] => {
	const routeMarketing = (path?: string) => `/marketing${path ? "/" + path : ""}`;
	const routeAdmin = (path?: string) => `/admin${path ? "/" + path : ""}`;
	const { authStore } = useGlobalStore();

	const adminRoutes = [
		{
			text: strings.nav.home,
			path: routeAdmin(),
		},
		{
			text: strings.nav.adminMarketingMaster,
			path: routeAdmin("adminMarketingMaster"),
		},
		{
			text: strings.nav.adminMarketing,
			path: routeAdmin("adminMarketing"),
		},
		{
			text: strings.nav.vetsShowInSite,
			path: routeAdmin("vetsshowinsite"),
		},
		{
			text: strings.nav.clinics,
			path: routeAdmin("clinics"),
		},
		{
			text: strings.nav.pendingPosts,
			path: routeAdmin("pendingPosts"),
		},
		{
			text: strings.nav.reprovedPosts,
			path: routeAdmin("reprovedPosts"),
		},
		{
			text: strings.nav.posts,
			path: routeAdmin("posts"),
		},
	];

	const marketingRoutes = [
		{
			text: strings.nav.home,
			path: routeMarketing(),
		},
		{
			text: strings.nav.vetsShowInSite,
			path: routeMarketing("vetsshowinsite"),
		},
		{
			text: strings.nav.clinics,
			path: routeMarketing("clinics"),
		},
		{
			text: strings.nav.reprovedPosts,
			path: routeMarketing("reprovedPosts"),
		},
		{
			text: strings.nav.posts,
			path: routeMarketing("posts"),
		},
	];

	if (!authStore.currentAdminUser) {
		return [];
	}

	return authStore.currentAdminUser.type === api.AdminUserType.marketingMaster ? adminRoutes : marketingRoutes;
};

export default useMainRoutes;
