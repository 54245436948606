import React from "react";
import { observer } from "mobx-react-lite";
import { Td } from "@chakra-ui/react";
import { TableModel } from "~/components";
import strings from "~/resources/strings";
import api from "~/resources/api";
import format from "~/resources/format";

const TableView: React.FC = () => {
	const pageStrings = strings.pages.adminMarketing;

	return (
		<TableModel
			header={pageStrings.table.header}
			tableTitle={pageStrings.table.title}
			onAdd
			initStoreSettings={{
				apiGetAllMethod: api.getAllMarketingAdminUsers,
				deleteShelfInitValues: {
					apiMethod: api.deleteAdminUser,
				},
			}}
			onSuccessMessage={pageStrings.table.delete}
			renderRow={(item) => (
				<>
					<Td>{item.name}</Td>
					<Td>{item.email}</Td>
					<Td>{format.documentNumber(item.documentNumber)}</Td>
				</>
			)}
			hasDelete
			hasDetails
			hasEdit
		/>
	);
};

export default observer(TableView);
