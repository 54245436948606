import { makeAutoObservable } from "mobx";
import {
	AttributeShelf,
	FetchModelShelf,
	LoaderShelf,
	PaginatedListShelf,
} from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";
import { showSuccessToast } from "~/resources/toast";
import strings from "~/resources/strings";
import { FormShelf } from "@startapp/mobx-utils/src/web";

const pageStrings = strings.pages.pendingPost;

export default class PendingPostShelf {
	public paginetedListShelf: PaginatedListShelf<api.PostWithoutDescription>;
	public loader = new LoaderShelf();
	public modalReproveIsOpen = new AttributeShelf<boolean>(false);
	public fetchModelShelf: FetchModelShelf<api.Post>;

	public formShelf = new FormShelf({
		reason: "",
	});

	constructor() {
		makeAutoObservable(this);
	}

	public approvePost = async (id: string, onSuccess: () => void) => {
		this.loader.start();
		try {
			await api.approvePost(id);
			showSuccessToast(pageStrings.success);
			onSuccess();
		} catch (e) {
			onFetchError(e);
		} finally {
			this.loader.end();
		}
	};

	public refusePost = async (onSuccess: () => void, id?: string) => {
		this.loader.start();
		const formValue = this.formShelf.getValue("reason");
		try {
			await api.reprovePost(id || "", formValue);
			showSuccessToast(pageStrings.refuse);
			onSuccess();
		} catch (e) {
			onFetchError(e);
		} finally {
			this.loader.end();
		}
	};
}
