import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
	Tooltip,
	Avatar,
	Image,
} from "@chakra-ui/react";
import { Label, ModalComponent } from "~/components";
import strings from "~/resources/strings";
import ImagePlaceHolder from "../../../static/pick_image.svg";

export interface IProps {
	src: string | undefined;
	label?: string;
}

export const ImageWithModal: React.FC<IProps> = observer((props) => {
	const { src, label } = props;
	const [modalImgIsOpen, setModalImgIsOpen] = useState(false);
	const commonStrings = strings.common;

	return (
		<>
			{label &&(
				<Label fontWeight="bold" marginBottom={1}>
					{label}
				</Label>
			)}
			<Tooltip label={strings.components.tooltip}>
				<Avatar
					cursor="pointer"
					onClick={() => setModalImgIsOpen(!modalImgIsOpen)}
					src={src}
					w={40}
					h={40}
				/>
			</Tooltip>
			<ModalComponent
				size="xl"
				title={commonStrings.fields.photo}
				isOpen={modalImgIsOpen}
				onClose={() => setModalImgIsOpen(!modalImgIsOpen)}
			>
				<Image
					mx="auto"
					w="95%"
					objectFit="cover"
					src={src ?
						src
						: ImagePlaceHolder}
					rounded="lg"
				/>
			</ModalComponent>
		</>

	);
});
