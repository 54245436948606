import { makeAutoObservable } from "mobx";
import { FormShelf, ImagePickerShelf } from "@startapp/mobx-utils/src/web";
import { AttributeShelf, LoaderShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { onFetchError } from "~/resources/fetchError";

const pageStrings = strings.pages.vetsShowInSite;

export default class Store {

	public formShelf = new FormShelf({
		name: "",
		description: "",
	});

	public id = new AttributeShelf("");

	public loader = new LoaderShelf();
	public imageShelf = new ImagePickerShelf(api.uploadImage);

	constructor(id?: string) {
		makeAutoObservable(this);

		if (id) {
			this.id.setValue(id);
			this.getVet(this.id.value);
		}
	}

	public getVet = async (id: string) => {
		this.loader.start();
		try {
			const vet = await api.getVetById(id);
			this.setInitValues(vet);
		} catch (e) {
			onFetchError(e);
		} finally {
			this.loader.end();
		}
	};

	public setInitValues = (vet: api.Vet) => {
		this.formShelf = new FormShelf({
			name: vet.name,
			description: vet.description || "",
		});
		this.imageShelf.getPickerFields().setUploadedImage(vet.image || null);
	};

	public get vetData() {
		const data = this.formShelf.getValues();
		return {
			name: data.name,
			description: data.description,
			image: this.imageShelf.uncertainfiedImage,
		};
	}

	public createOrEditVet = async (onSuccess: () => void) => {
		this.loader.start();
		try {
			if (this.id.value) {
				await api.editVetShowInSite(this.id.value, this.vetData);
				showSuccessToast(pageStrings.createOrEdit.success(!!this.id.value));
				onSuccess();
			} else {
				await api.createVetShowInSite(this.vetData);
				showSuccessToast(pageStrings.createOrEdit.success(!!this.id.value));
				onSuccess();
			}
		} catch (e) {
			const error = Errors.treatError(e);
			if (error.type === api.ErrorType.Validation) {
				this.formShelf.fieldError.cleanAndAddErrors(JSON.parse((e as Error).message));
			} else {
				const errorMessage = Errors.handleError(e);
				showErrorToast(errorMessage);
			}
		} finally {
			this.loader.end();
		}
	};
}
