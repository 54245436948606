import { makeAutoObservable } from "mobx";
import { FetchModelShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";

export class Store {

	public fetchModelShelf: FetchModelShelf<api.Post>;

	constructor(id: string, isMaster: (id: string, progress?: ((progress: number) => void) | undefined) => Promise<api.Post>) {
		makeAutoObservable(this);
		this.fetchModelShelf = new FetchModelShelf(id, isMaster,
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		);
	}

	public get post(){
		return this.fetchModelShelf.model.value;
	}
}
