import React from "react";
import { observer } from "mobx-react-lite";
import { Routes, Route, useLocation } from "react-router-dom";
import { useAuthRoute } from "~/hooks/useAuthRoute";
import { Loading, Login, NewPassword, Recovery } from "~/pages";
import { useHistory } from "~/hooks/useHistory";
import MarketingRoutes from "./MarketingRoutes";
import api from "~/resources/api";
import AdminMarketingRoutes from "./AdminMarketingRoutes";

const App = observer(() => {
	const history = useHistory();
	const location = useLocation();
	const onSuccess = (adminUser: api.AdminUser) => {
		const routesToBeRedirect = ["/", "/login"];
		const hasToBeRedirected = routesToBeRedirect.includes(location.pathname);
		if (hasToBeRedirected) {
			if (adminUser.type === api.AdminUserType.marketingMaster) {
				return history.push("/admin");
			} else {
				return history.push("/marketing");
			}
		}
	};

	useAuthRoute(onSuccess);

	return (
		<Routes>
			<Route
				index
				element={<Loading />}
			/>
			<Route
				path="/admin/*"
				element={<AdminMarketingRoutes />}
			/>
			<Route
				path="/marketing/*"
				element={<MarketingRoutes />}
			/>
			<Route
				path="/login"
				element={<Login />}
			/>
			<Route
				path="/recovery"
				element={<Recovery />}
			/>
			<Route
				path="/recovery/token/:token"
				element={<NewPassword />}
			/>
		</Routes>
	);
});

export default App;
