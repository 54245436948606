import { makeAutoObservable } from "mobx";
import { LoaderShelf } from "@startapp/mobx-utils";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";

export type ApiMethod<DataType> = (id: string) => Promise<DataType>;

export interface DeleteRecordInitialValues<DataType> {
	apiMethod: ApiMethod<DataType>;
	showSuccessMessage?: (item: DataType) => string;
	onSuccess?: (item: DataType) => void;
}

export default class DeleteRecordShelf<DataType> {

	public loader = new LoaderShelf();

	public apiMethod: ApiMethod<DataType>;

	public showSuccessMessage: ((item: DataType) => string) | null;

	public onSuccess: ((item: DataType) => void) | null;

	constructor(deleteRecordShelf: DeleteRecordInitialValues<DataType>) {
		makeAutoObservable(this);
		this.apiMethod = deleteRecordShelf.apiMethod;
		this.showSuccessMessage = deleteRecordShelf.showSuccessMessage || null;
		this.onSuccess = deleteRecordShelf.onSuccess || null;
	}

	public deleteDataType = async (id: string) => {
		this.loader.tryStart();
		try {
			const deleteItem = await this.apiMethod(id);

			if (this.showSuccessMessage) {
				showSuccessToast(this.showSuccessMessage(deleteItem));
			}
			if (this.onSuccess) {

				this.onSuccess(deleteItem);
			}
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
