import React from "react";
import { observer } from "mobx-react-lite";
import { Card, TextInput } from "~/components";
import strings from "~/resources/strings";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import { Button, Flex, useToast, VStack } from "@chakra-ui/react";
import { useHistory } from "~/hooks/useHistory";
import { showSuccessToast } from "~/resources/toast";
import { useParams } from "react-router-dom";

export const NewPassword: React.FC = observer(() => {
	const [hasToken, setHasToken] = React.useState(false);
	const pageStrings = strings.pages.recoveryPage;

	const { token } = useParams<{ token: string }>();

	const toast = useToast();
	const { authStore } = useGlobalStore();

	const history = useHistory();

	React.useEffect(() => {
		if (token && !authStore.receivedToken) {
			authStore.validateTokenUrl(
				() => { },
				() => history.push("/recovery"),
				token,
			);
		}
	}, []);

	return (
		<Flex
			justifyContent="center"
			alignItems="center"
			w="100%"
			minH="100vh"
			bg="gray.200"
		>
			<Card
				display="flex"
				alignItems="center"
				maxW={{ base: "100vw", sm: "28rem" }}
				minH={{ base: "100vh", sm: "30rem" }}
				rounded={{ base: 0, sm: 10 }}
				w="100%"
				bgColor="white"
			>
				<VStack spacing={8} w="100%">
					<TextInput
						type="password"
						labelText={pageStrings.newPassword}
						isDisabled={authStore.loader.isLoading}
						{...authStore.form.field("newPassword")}
					/>
					<TextInput
						type="password"
						labelText={pageStrings.confirmNewPassword}
						isDisabled={authStore.loader.isLoading}
						{...authStore.form.field("confirmNewPassword")}
					/>
					<Button
						isLoading={authStore.loader.isLoading}
						onClick={() => {
							setHasToken(!hasToken);
							authStore.resetPassword(() => {
								showSuccessToast(pageStrings.successPassword);
								history.push("/login");
							},
							(errorMessage: string) => toast({ status: "error", title: errorMessage }),
							);
						}}
					>
						{pageStrings.newPasswordButton}
					</Button>
				</VStack>
			</Card>
		</Flex>
	);
});
