import { makeAutoObservable } from "mobx";
import { FormShelf, ImagePickerShelf } from "@startapp/mobx-utils/src/web";
import { AttributeShelf, LoaderShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";
import { showErrorToast, showSuccessToast } from "~/resources/toast";

const pageStrings = strings.pages.clinics.createOrEdit;

export default class Store {

	public state = new AttributeShelf<api.StateUF>(api.StateUF.AC);
	public imageShelf = new ImagePickerShelf(api.uploadImage);
	public services = new AttributeShelf("");

	public formShelf = new FormShelf({
		phone: "",
		name: "",
		email: "",
		documentNumber: "",
		zipcode: "",
		street: "",
		streetNumber: "",
		complementary: "",
		services: "",
		neighborhood: "",
		city: "",
	});

	public loader = new LoaderShelf();

	public id = new AttributeShelf("");

	constructor(id: string){
		makeAutoObservable(this);

		if (id) {
			this.id.setValue(id);
			this.getClinic(id);
		}
	}

	public clinicData = () => {
		const data = this.formShelf.getValues();
		return {
			name: data.name,
			email: data.email,
			documentNumber: data.documentNumber,
			clinic: {
				phone: data.phone,
				services: data.services,
				image: {
					bytes: this.imageShelf.uncertainfiedImage?.bytes || null,
					image: this.imageShelf.uncertainfiedImage?.image || null,
				},
				address: {
					zipcode: data.zipcode,
					street: data.street,
					streetNumber: data.streetNumber,
					complementary: data.complementary,
					neighborhood: data.neighborhood,
					city: data.city,
					state: this.state.value,
				},
			},
		};
	};
	public createOrEditClinic = async (onSuccess: () => void) => {
		this.loader.start();
		try {
			if (this.id.value) {
				await api.editShowInSiteClinicAdminUser(this.id.value, this.clinicData());
			} else {
				await api.createClinicAdminUserToShowInSite(this.clinicData());
			}
			showSuccessToast(pageStrings.success(!!this.id.value));
			onSuccess();
		} catch (e) {
			const error = Errors.treatError(e);
			if (error.type === api.ErrorType.Validation) {
				this.formShelf.fieldError.cleanAndAddErrors(JSON.parse((e as Error).message));
			} else {
				const errorMessage = Errors.handleError(e);
				showErrorToast(errorMessage);
			}
		} finally {
			this.loader.end();
		}
	};
	public getClinic = async (id: string) => {
		this.loader.tryStart();
		try {
			const clinic = await api.getAdminUser(id);
			this.setInitValues(clinic);
		} catch (e) {
			const error = Errors.handleError(e);
			showErrorToast(error);
		} finally {
			this.loader.end();
		}
	};
	public setInitValues = (admin: api.AdminUser) => {
		if (admin.clinic !== null){
			this.formShelf = new FormShelf({
				phone: admin.clinic?.phone,
				name: admin.name,
				email: admin.email,
				documentNumber: admin.documentNumber,
				services: admin.clinic?.services || "",
				zipcode: admin.clinic?.address.zipcode,
				street: admin.clinic?.address.street,
				streetNumber: admin.clinic?.address.streetNumber,
				complementary: admin.clinic?.address.complementary,
				neighborhood: admin.clinic?.address.neighborhood,
				city: admin.clinic?.address.city,
			});
			if (admin.clinic.services) {
				this.services.setValue(admin.clinic?.services);
			}
			this.state.setValue(admin.clinic.address.state);
			this.imageShelf.getPickerFields().setUploadedImage(admin.clinic?.image || null);
		}
	};
}
