import { makeAutoObservable } from "mobx";
import { FormShelf, ImagePickerShelf } from "@startapp/mobx-utils/src/web";
import { AttributeShelf, LoaderShelf } from "@startapp/mobx-utils";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { CustomError } from "~/resources/customError";
import api from "~/resources/api";

const pageStrings = strings.pages.posts.createOrEdit;

export default class Store {

	public loader = new LoaderShelf();
	public imageShelf = new ImagePickerShelf(api.uploadImage);
	public id = new AttributeShelf("");
	public slug = new AttributeShelf("");
	public description = new AttributeShelf("");
	public isMarketing = new AttributeShelf<boolean | null>(null);
	public imageShelfQuill = new ImagePickerShelf(api.uploadImage);

	public formShelf = new FormShelf({
		title: "",
		slug: "",
	});

	constructor(id: string, isMarketing: boolean) {
		makeAutoObservable(this);
		this.isMarketing.setValue(!!isMarketing);
		if (id) {
			this.id.setValue(id);
			this.getPost(id, isMarketing);
		}
	}

	public getPost = async (id: string, isMarketing: boolean) => {
		this.loader.tryStart();
		try {
			const post = isMarketing ? await api.getPostByIdForAdminMarketing(id) : await api.getPostById(id);
			this.setInitValues(post);
		} catch (e) {
			const error = Errors.handleError(e);
			showErrorToast(error);
		} finally {
			this.loader.end();
		}
	};

	public setInitValues = (data: api.Post) => {
		this.formShelf = new FormShelf({
			title: data.title,
			slug: data.slug || "",
		});

		this.description.setValue(data.description);
		this.imageShelf.getPickerFields().setUploadedImage(data.image || null);
	};

	public get postData() {
		const data = this.formShelf.getValues();
		return {
			title: data.title,
			description: this.description.value,
			slug: data.slug || null,
			image: this.imageShelf.uncertainfiedImage!,
			review: null,
			approvedAt: new Date(),
		};
	}

	public createOrEditPost = async (onSuccess: () => void, methodForCreatePost: (newPost: api.NewPost) => Promise<api.Post>) => {
		this.loader.start();
		try {
			if (this.id.value) {
				await api.editPost(this.id.value, this.postData);
			} else {
				await methodForCreatePost(this.postData);
			}

			showSuccessToast(this.isMarketing.value ? pageStrings.successMarketing(!!this.id.value) : pageStrings.success(!!this.id.value));
			onSuccess();
		} catch (e) {
			const error = Errors.treatError(e);
			if (error.type === api.ErrorType.Validation) {
				this.formShelf.fieldError.cleanAndAddErrors(JSON.parse((e as Error).message));
			} else {
				const errorMessage = Errors.handleError(e);
				if (!this.postData.image) {
					const customError = new CustomError(api.ErrorType.MissingArgument, strings.error.selectImage);
					showErrorToast(customError.message);
				} else {
					showErrorToast(errorMessage);
				}
			}
		} finally {
			this.loader.end();
		}
	};
	public get editorModules() {
		return {
			toolbar: {
				// Buttons we want at the Quill's editor toolbar.
				container: [
					[{ header: [1, 2, false] }],
					["bold", "italic", "underline", "strike", "blockquote"],
					[
						{ list: "ordered" },
						{ list: "bullet" },
						{ indent: "-" },
						{ indent: "+1" },
					],
					["link", "image"],
				],
				// Overriding the image button click
				handlers: {
					image: this.imageShelfQuill.getPickerFields().setUploadedImage(this.imageShelfQuill.uploadedImage),
				},
			},
		};
	}
}
