import React from "react";
import { Store } from "./store";
import { useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	IconButton,
	Tooltip,
	Flex,
} from "@chakra-ui/react";
import {
	ButtonBack,
	CentralizedCard,
	ClinicAddressDetails,
	DetailsRow,
	ImageWithModal,
} from "~/components";
import strings from "~/resources/strings";

const Details: React.FC = () => {
	const commonStrings = strings.common;
	const { id } = useParams<{id: string}>();
	const store = useLocalObservable(() => new Store(id || ""));

	return (
		<Flex justifyContent="center" flexDirection="column" alignItems="center">
			<>
				<CentralizedCard
					title={{
						text: commonStrings.details,
						helper: (
							<Tooltip label={strings.common.edit}>
								<IconButton
									variant="icon"
									aria-label="Voltar"
									size="lg"
								/>
							</Tooltip>
						),
					}}
					isLoading={store.fetchModelShelf.initialLoader.isLoading}
				>
					{
						store.fetchModelShelf.model.value &&
							<>
								<ImageWithModal src={store.fetchModelShelf.model.value.clinic?.image.url} />
								<DetailsRow
									label={commonStrings.fields.id}
									value={store.fetchModelShelf.model.value.id}
								/>
								<DetailsRow
									label={commonStrings.fields.name}
									value={store.fetchModelShelf.model.value.name}
								/>
								<DetailsRow
									label={commonStrings.fields.email}
									value={store.fetchModelShelf.model.value.email}
								/>
								{
									store.fetchModelShelf.fetchedModel.clinic?.services &&
										<DetailsRow
											label={commonStrings.fields.services}
											value={store.fetchModelShelf.fetchedModel.clinic.services}
										/>
								}
								<DetailsRow
									label={commonStrings.fields.cnpj}
									value={store.fetchModelShelf.model.value.documentNumber}
								/>
								{store.fetchModelShelf.model.value.clinic &&
									<ClinicAddressDetails address={store.fetchModelShelf.fetchedModel.clinic?.address} />}
							</>
					}
				</CentralizedCard>
				<ButtonBack />
			</>
		</Flex>
	);
};

export default observer(Details);
