import React from "react";
import { observer } from "mobx-react-lite";
import { Td, Text } from "@chakra-ui/react";
import { TableModel } from "~/components";
import strings from "~/resources/strings";
import api from "~/resources/api";

const TableView: React.FC = () => {
	const pageStrings = strings.pages.vetsShowInSite;

	return (
		<TableModel
			header={pageStrings.table.header}
			tableTitle={pageStrings.table.title}
			onAdd
			onSuccessMessage={pageStrings.table.delete}
			initStoreSettings={{
				apiGetAllMethod: api.getAllVetsToShowInSite,
				deleteShelfInitValues: {
					apiMethod: api.deleteVet,
				},
			}}
			renderRow={(item) => (
				<>
					<Td>
						<Text>{item.name}</Text>
					</Td>
					<Td >
						<Text noOfLines={2}>{item.description}</Text>
					</Td>
				</>
			)}
			hasDelete
			hasEdit
		/>
	);
};

export default observer(TableView);
