import { makeAutoObservable } from "mobx";

import {
	AttributeShelf,
	LoaderShelf,
	PaginatedListShelf,
} from "@startapp/mobx-utils";

import { onFetchError } from "~/resources/fetchError";

import DeleteRecordShelf, { DeleteRecordInitialValues } from "~/shelves/DeleteRecordShelf";

export type PaginatedListShelfMethod<DataType> = (page: number) => Promise<DataType[]>;

export interface TableModelStore<DataType> {
	apiGetAllMethod: PaginatedListShelfMethod<DataType>;
	deleteShelfInitValues?: DeleteRecordInitialValues<DataType>;
}

export default class Store<DataType> {
	public paginetedListShelf: PaginatedListShelf<DataType>;

	public loader = new LoaderShelf();

	public deleteShelf = new AttributeShelf<DeleteRecordShelf<DataType> | null>(null);

	constructor(tableModelStore: TableModelStore<DataType>) {
		makeAutoObservable(this);
		this.paginetedListShelf = new PaginatedListShelf(
			tableModelStore.apiGetAllMethod,
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		);

		if (tableModelStore.deleteShelfInitValues) {
			this.deleteShelf.setValue(new DeleteRecordShelf({
				...tableModelStore.deleteShelfInitValues,
				onSuccess: () => {
					this.paginetedListShelf.refresh();
				},
			}));
		}
	}
}
