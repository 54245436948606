import React from "react";
import { useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	IconButton,
	Tooltip,
	Flex,
} from "@chakra-ui/react";
import {
	ButtonBack,
	CentralizedCard,
	DetailsRow,
} from "~/components";
import { Store } from "./store";
import strings from "~/resources/strings";
import format from "~/resources/format";

const Details: React.FC = () => {
	const commonStrings = strings.common;
	const { id } = useParams<"id">();
	const store = useLocalObservable(() => new Store(id || ""));

	return (
		<Flex
			justifyContent="center"
			flexDirection="column"
			alignItems="center"
		>
			<CentralizedCard
				title={{
					text: commonStrings.details,
					helper: (
						<Tooltip label={strings.common.edit}>
							<IconButton
								variant="icon"
								aria-label="Voltar"
								size="lg"
							/>
						</Tooltip>
					),
				}}
				isLoading={store.fetchModelShelf.initialLoader.isLoading}
			>
				{
					store.fetchModelShelf.model.value &&
						<>
							<DetailsRow
								label={commonStrings.fields.name}
								value={store.fetchModelShelf.model.value.name}
							/>
							<DetailsRow
								label={commonStrings.fields.email}
								value={store.fetchModelShelf.model.value.email}
							/>
							<DetailsRow
								label={commonStrings.fields.cpf}
								value={format.documentNumber(store.fetchModelShelf.model.value.documentNumber)}
							/>
						</>
				}
			</CentralizedCard>
			<ButtonBack />
		</Flex>
	);
};

export default observer(Details);
