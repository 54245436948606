import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Box, Button } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import {
	CentralizedCard,
	ImagePicker,
	Label,
	TextArea,
	TextInput,
} from "~/components";
import strings from "~/resources/strings";
import Store from "./store";
import { useHistory } from "~/hooks/useHistory";

const CreateOrEdit: React.FC = () => {
	const history = useHistory();
	const { id } = useParams<"id">();
	const store = useLocalObservable(() => new Store(id || ""));
	const pageStrings = strings.pages.vetsShowInSite.createOrEdit;
	const commonStrings = strings.common;

	const onSucess = () => {
		history.goBack();
	};

	return (
		<CentralizedCard
			title={{ text: pageStrings.title(!!id)}}
			button={(
				<Button
					minW={{ base: "100%", md: 280 }}
					size="lg"
					mt={10}
					isLoading={store.loader.isLoading}
					onClick={() => store.createOrEditVet(onSucess)}
				>
					{commonStrings.buttons.saveOrEdit(!!id)}
				</Button>
			)}
		>
			<Box>
				<Label fontWeight="bold" marginBottom={1}>
					{strings.components.imagePicker.selectAnImage}
				</Label>
				<ImagePicker
					pickImage={store.imageShelf.getPickerFields().pick}
					src={store.imageShelf.src}
				/>
			</Box>
			<TextInput
				labelText={commonStrings.fields.name}
				labelProps={{ fontWeight: "bold" }}
				labelColor="primary"
				type="text"
				isDisabled={store.loader.isLoading}
				errorText={store.formShelf.field("name").error}
				{...store.formShelf.field("name")}
			/>
			<Box paddingBottom={45}>
				<Box>
					<TextArea
						labelText={commonStrings.fields.description}
						labelProps={{ fontWeight: "bold" }}
						labelColor="primary"
						isDisabled={store.loader.isLoading}
						errorText={store.formShelf.field("description").error}
						{...store.formShelf.field("description")}
					/>
				</Box>
			</Box>
		</CentralizedCard>
	);
};

export default observer(CreateOrEdit);
