import React from "react";
import { observer } from "mobx-react-lite";
import { Text, Box, Flex, Link } from "@chakra-ui/react";
import strings from "../../../resources/strings";
import { useGlobalStore } from "../../../contexts/useGlobalContext";
import { useHistory } from "~/hooks/useHistory";
import { Link as RouterLink} from "react-router-dom";
import useMainRoutes from "~/hooks/useMainRoutes";

interface IProps {
	onClose: () => void;
}

export const DrawerLinks: React.FC<IProps> = observer((props) => {

	const { authStore } = useGlobalStore();
	const routes = useMainRoutes();
	const fontSizeBreakPoint = { base: "sm", lg: "md" };
	const history = useHistory();
	const { onClose } = props;
	const onSuccess = () => {
		history.push("/login");
	};

	return (
		<>
			<Box
				display="flex"
				mt={8}
				flexDirection="column"
			>
				{ routes && routes.map((mainLink) => (
					mainLink &&
						<Link
							as={RouterLink}
							key={mainLink.text}
							to={mainLink.path}
							onClick={onClose}
							display="flex"
							_hover={{
								textDecoration: "none",
							}}
							justifyContent="center"
							alignItems="center"
							mb={4}
						>
							<Text
								fontSize={fontSizeBreakPoint}
								fontWeight="bold"
							>
								{mainLink.text}
							</Text>
						</Link>
				))}
				<Flex
					justifyContent="center"
					alignItems="center"
					onClick={() => authStore.logout(onSuccess)}
					mt="35px"
				>
					<Text
						color="gray.300"
						fontSize={fontSizeBreakPoint}
						cursor="pointer"
						textDecoration="underline"
					>
						{strings.nav?.logout}
					</Text>
				</Flex>
			</Box>
		</>
	);
});
