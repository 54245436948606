import React from "react";
import { Image } from "@chakra-ui/react";
import strings from "~/resources/strings";

export const Logo: React.FC = () => {
	const pageStrings = strings.pages.recoveryPage;
	return (
		<Image
			w={200}
			src="./logo.svg"
			alt={pageStrings.altImg}
			mb={5}
		/>
	);
};
