import { LocalizedStringsMethods } from "localized-strings";
import { UAParser } from "ua-parser-js";

let baseUrl: string = "localhost:8000";
let strings: (LocalizedStringsMethods & any) | null = null;

export function setUrl(url: string): void {
    baseUrl = url;
}

export function setStrings(newStrings: (LocalizedStringsMethods & {}) | null): void {
    strings = newStrings;
}
export interface LatLng {
    lat: number;
    lng: number;
}

export interface Address {
    zipcode: string;
    street: string;
    streetNumber: string;
    complementary: string;
    neighborhood: string;
    city: string;
    state: StateUF;
}

export interface Image {
    thumb: SimpleImage;
    width: number;
    height: number;
    url: string;
}

export interface SimpleImage {
    width: number;
    height: number;
    url: string;
}

export interface FilterByPeriod {
    startDate: Date | null;
    endDate: Date | null;
}

export interface UncertainImage {
    bytes: Buffer | null;
    image: Image | null;
}

export interface UncertainFile {
    bytes: Buffer | null;
    url: string | null;
}

export interface ClinicDetails {
    id: string;
    image: Image;
    name: string;
    email: string;
    documentNumber: string;
    phone: string;
    showInSite: boolean;
    address: Address;
    createdAt: Date;
    code: number;
    codeFormat: string;
    services: string | null;
}

export interface Clinic {
    id: string;
    image: Image;
    phone: string;
    showInSite: boolean;
    address: Address;
    code: number;
    codeFormat: string;
    services: string | null;
    createdAt: Date;
    adminUser: ClinicOwner | null;
}

export interface EditClinic {
    image: UncertainImage;
    phone: string;
    address: Address;
}

export interface ClinicOwner {
    name: string;
    documentNumber: string;
}

export interface ClinicStats {
    pets: number;
    hospitalizedPets: number;
    tutors: number;
    consultations: number;
}

export interface ClinicStatsForAdmin {
    clinics: number;
    pets: number;
    hospitalizedPets: number;
    tutors: number;
    consultations: number;
}

export interface EditAdminUser {
    name: string;
    email: string;
    documentNumber: string;
}

export interface AdminUserToSite {
    id: string;
    clinic: Clinic | null;
    createdAt: Date;
    name: string;
    email: string;
    documentNumber: string;
}

export interface AdminUser {
    id: string;
    clinic: Clinic | null;
    type: AdminUserType;
    createdAt: Date;
    name: string;
    email: string;
    documentNumber: string;
}

export interface NewAdminUser {
    password: string;
    name: string;
    email: string;
    documentNumber: string;
}

export interface NewClinicAdmin {
    clinic: EditClinic;
    password: string;
    name: string;
    email: string;
    documentNumber: string;
}

export interface EditClinicAdmin {
    clinic: EditClinic;
    name: string;
    email: string;
    documentNumber: string;
}

export interface MarketingAdmin {
    id: string;
    clinic: Clinic | null;
    type: AdminUserType;
    createdAt: Date;
    name: string;
    email: string;
    documentNumber: string;
}

export interface NewClinicAdminToSite {
    clinic: EditClinicToSite;
    name: string;
    email: string;
    documentNumber: string;
}

export interface EditClinicToSite {
    phone: string;
    image: UncertainImage;
    services: string;
    address: Address;
}

export interface AdministeredMedication {
    id: string;
    name: string;
    code: number | null;
    startConsumptionDate: Date;
    endConsumptionDate: Date;
    consumptionHours: Date[];
    dosage: string;
    image: Image | null;
    document: string | null;
    createdAt: Date;
}

export interface NewAdministeredMedication {
    name: string;
    code: number | null;
    startConsumptionDate: Date;
    endConsumptionDate: Date;
    consumptionHours: Date[];
    dosage: string;
    image: Image | null;
    document: string | null;
}

export interface UserTutor {
    id: string;
    email: string;
    name: string;
    password: string;
    documentNumber: string | null;
    phone: string;
    image: Image | null;
    petParents: PetParents;
}

export interface Anamnese {
    heartRate: string | null;
    respiratoryFrequency: string | null;
    tpc: string | null;
    mucous: string | null;
    pulse: string | null;
    rectalTemperature: string | null;
    cardiopulmonaryAuscultation: string | null;
    abdominalPalpation: string | null;
    bodyScore: string | null;
    otherSystems: string | null;
    complementaryExams: string | null;
    suspicions: string | null;
    diagnosis: string | null;
    treatment: string | null;
    description: string | null;
}

export interface FluidTherapys {
    solution: string | null;
    fluidtherapyRate: string | null;
    velocity: string | null;
}

export interface Pet {
    id: string;
    name: string;
    image: Image;
    age: string;
    species: Species;
    animalSex: AnimalSex;
    isCastrated: boolean;
    code: number;
    breed: string;
    birthdate: Date;
    codeFormat: string;
}

export interface NewVet {
    name: string;
    documentNumber: string | null;
}

export interface NewVetForShowInSite {
    name: string;
    image: UncertainImage | null;
    description: string | null;
}

export interface EditVet {
    name: string;
    documentNumber: string | null;
}

export interface EditVetForShowInSite {
    name: string;
    image: UncertainImage | null;
    description: string | null;
}

export interface Vet {
    id: string;
    name: string;
    image: Image | null;
    description: string | null;
    documentNumber: string | null;
    showInSite: boolean;
    clinic: Clinic | null;
}

export interface RatedUser {
    id: string;
    email: string;
    name: string;
    documentNumber: string | null;
    phone: string;
}

export interface RatedConsultation {
    id: string;
    date: Date;
    hour: string;
    description: string;
    finishedAt: Date | null;
}

export interface RatedVet {
    id: string;
    name: string;
    description: string | null;
}

export interface Rating {
    id: string;
    status: RatingStatus;
    user: RatedUser;
    consultation: RatedConsultation | null;
    vet: RatedVet;
    vetRate: number | null;
    consultationRate: number | null;
    vetRatedAt: Date | null;
    consultationRatedAt: Date | null;
    createdAt: Date;
}

export interface NewConsultation {
    vetId: string;
    petId: string;
    date: Date;
    hour: Date;
    description: string;
    anamnese: Anamnese;
    fluidtherapys: FluidTherapys[];
}

export interface EditConsultation {
    date: Date;
    hour: Date;
    description: string;
    anamnese: Anamnese;
    fluidtherapys: FluidTherapys[];
}

export interface ConsultationWithRating {
    ratings: Rating;
    id: string;
    user: UserTutor;
    pet: Pet;
    vet: Vet;
    date: Date;
    hour: string;
    description: string;
    clinic: Clinic;
    anamnese: Anamnese;
    fluidtherapys: FluidTherapys[];
    finishedAt: Date | null;
    createdAt: Date;
}

export interface Consultation {
    id: string;
    user: UserTutor;
    pet: Pet;
    vet: Vet;
    date: Date;
    hour: string;
    description: string;
    clinic: Clinic;
    anamnese: Anamnese;
    fluidtherapys: FluidTherapys[];
    finishedAt: Date | null;
    createdAt: Date;
}

export interface ConsultationForClinic {
    id: string;
    user: UserTutor;
    pet: Pet;
    vet: Vet;
    date: Date;
    hour: string;
    description: string;
    clinic: Clinic;
    anamnese: Anamnese;
    fluidtherapys: FluidTherapys[];
    finishedAt: Date | null;
    createdAt: Date;
}

export interface NewContact {
    name: string;
    email: string;
    phone: string;
    message: string;
}

export interface Exam {
    id: string;
    name: string;
    code: number | null;
    type: string;
    image: Image | null;
    pdf: string | null;
    createdAt: Date | null;
}

export interface EditExam {
    name: string;
    code: number | null;
    type: string;
    image: Image | null;
    pdf: UncertainFile | null;
}

export interface Hospitalization {
    id: string;
    startDate: Date;
    endDate: Date | null;
    finishedAt: Date | null;
    entryTime: string;
    exitTime: string | null;
    images: Image[] | null;
    pet: Pet;
    clinic: Clinic | null;
}

export interface HospitalizationForClinic {
    id: string;
    startDate: Date;
    endDate: Date | null;
    finishedAt: Date | null;
    entryTime: string;
    exitTime: string | null;
    images: Image[] | null;
    pet: Pet;
    clinic: Clinic | null;
}

export interface NewHospitalization {
    petId: string;
    startDate: Date;
    endDate: Date | null;
    entryTime: Date;
    exitTime: Date | null;
    images: Image[] | null;
}

export interface EditHospitalization {
    startDate: Date;
    endDate: Date | null;
    entryTime: Date;
    exitTime: Date | null;
    images: Image[] | null;
}

export interface Notification {
    id: string;
    type: NotificationType;
    title: string | null;
    body: string;
    visualizedAt: Date | null;
    createdAt: Date;
    petId: string | null;
}

export interface NewPet {
    name: string;
    image: UncertainImage;
    age: string;
    species: Species;
    animalSex: AnimalSex;
    isCastrated: boolean;
    breed: string;
    birthdate: Date;
    userId: string;
}

export interface EditPet {
    name: string;
    image: UncertainImage;
    age: string;
    species: Species;
    animalSex: AnimalSex;
    isCastrated: boolean;
    breed: string;
    birthdate: Date;
}

export interface Post {
    id: string;
    image: Image;
    title: string;
    slug: string | null;
    description: string;
    adminUser: AdminUser;
    review: string | null;
    createdAt: Date;
    approvedAt: Date | null;
}

export interface PostWithoutDescription {
    id: string;
    image: Image;
    title: string;
    slug: string | null;
    adminUser: AdminUser;
    review: string | null;
    approvedAt: Date | null;
    createdAt: Date;
}

export interface NewPost {
    image: UncertainImage;
    title: string;
    slug: string | null;
    description: string;
    review: string | null;
}

export interface User {
    id: string;
    email: string;
    name: string;
    password: string;
    documentNumber: string | null;
    phone: string;
    image: Image | null;
    petParents: PetParents;
    createdAt: Date;
}

export interface UserWithPets {
    pets: Pet[];
    id: string;
    email: string;
    name: string;
    password: string;
    documentNumber: string | null;
    phone: string;
    image: Image | null;
    petParents: PetParents;
    createdAt: Date;
}

export interface UserDeleteRequest {
    id: string;
    user: User;
    acceptedAt: Date | null;
    createdAt: Date;
    deletedAt: Date | null;
}

export interface Vaccine {
    id: string;
    name: string;
    code: number | null;
    nextVaccinationAt: Date | null;
    expirationDateAt: Date;
    manufacturingDateAt: Date;
    batch: number;
    image: Image | null;
    appliedAt: Date;
    createdAt: Date;
}

export interface EditVaccine {
    name: string;
    code: number | null;
    nextVaccinationAt: Date | null;
    appliedAt: Date;
    expirationDateAt: Date;
    manufacturingDateAt: Date;
    batch: number;
    image: Image | null;
}

export enum StateUF {
    AC = "AC",
    AL = "AL",
    AP = "AP",
    AM = "AM",
    BA = "BA",
    CE = "CE",
    DF = "DF",
    ES = "ES",
    GO = "GO",
    MA = "MA",
    MT = "MT",
    MS = "MS",
    MG = "MG",
    PA = "PA",
    PB = "PB",
    PR = "PR",
    PE = "PE",
    PI = "PI",
    RJ = "RJ",
    RN = "RN",
    RS = "RS",
    RO = "RO",
    RR = "RR",
    SC = "SC",
    SP = "SP",
    SE = "SE",
    TO = "TO",
}

export function translateStateUF(enumStateUF: StateUF): string {
    switch (enumStateUF) {
        case StateUF.AC: {
            return strings ? strings["enum"]["StateUF"]["AC"] || StateUF.AC : StateUF.AC;
        }
        case StateUF.AL: {
            return strings ? strings["enum"]["StateUF"]["AL"] || StateUF.AL : StateUF.AL;
        }
        case StateUF.AP: {
            return strings ? strings["enum"]["StateUF"]["AP"] || StateUF.AP : StateUF.AP;
        }
        case StateUF.AM: {
            return strings ? strings["enum"]["StateUF"]["AM"] || StateUF.AM : StateUF.AM;
        }
        case StateUF.BA: {
            return strings ? strings["enum"]["StateUF"]["BA"] || StateUF.BA : StateUF.BA;
        }
        case StateUF.CE: {
            return strings ? strings["enum"]["StateUF"]["CE"] || StateUF.CE : StateUF.CE;
        }
        case StateUF.DF: {
            return strings ? strings["enum"]["StateUF"]["DF"] || StateUF.DF : StateUF.DF;
        }
        case StateUF.ES: {
            return strings ? strings["enum"]["StateUF"]["ES"] || StateUF.ES : StateUF.ES;
        }
        case StateUF.GO: {
            return strings ? strings["enum"]["StateUF"]["GO"] || StateUF.GO : StateUF.GO;
        }
        case StateUF.MA: {
            return strings ? strings["enum"]["StateUF"]["MA"] || StateUF.MA : StateUF.MA;
        }
        case StateUF.MT: {
            return strings ? strings["enum"]["StateUF"]["MT"] || StateUF.MT : StateUF.MT;
        }
        case StateUF.MS: {
            return strings ? strings["enum"]["StateUF"]["MS"] || StateUF.MS : StateUF.MS;
        }
        case StateUF.MG: {
            return strings ? strings["enum"]["StateUF"]["MG"] || StateUF.MG : StateUF.MG;
        }
        case StateUF.PA: {
            return strings ? strings["enum"]["StateUF"]["PA"] || StateUF.PA : StateUF.PA;
        }
        case StateUF.PB: {
            return strings ? strings["enum"]["StateUF"]["PB"] || StateUF.PB : StateUF.PB;
        }
        case StateUF.PR: {
            return strings ? strings["enum"]["StateUF"]["PR"] || StateUF.PR : StateUF.PR;
        }
        case StateUF.PE: {
            return strings ? strings["enum"]["StateUF"]["PE"] || StateUF.PE : StateUF.PE;
        }
        case StateUF.PI: {
            return strings ? strings["enum"]["StateUF"]["PI"] || StateUF.PI : StateUF.PI;
        }
        case StateUF.RJ: {
            return strings ? strings["enum"]["StateUF"]["RJ"] || StateUF.RJ : StateUF.RJ;
        }
        case StateUF.RN: {
            return strings ? strings["enum"]["StateUF"]["RN"] || StateUF.RN : StateUF.RN;
        }
        case StateUF.RS: {
            return strings ? strings["enum"]["StateUF"]["RS"] || StateUF.RS : StateUF.RS;
        }
        case StateUF.RO: {
            return strings ? strings["enum"]["StateUF"]["RO"] || StateUF.RO : StateUF.RO;
        }
        case StateUF.RR: {
            return strings ? strings["enum"]["StateUF"]["RR"] || StateUF.RR : StateUF.RR;
        }
        case StateUF.SC: {
            return strings ? strings["enum"]["StateUF"]["SC"] || StateUF.SC : StateUF.SC;
        }
        case StateUF.SP: {
            return strings ? strings["enum"]["StateUF"]["SP"] || StateUF.SP : StateUF.SP;
        }
        case StateUF.SE: {
            return strings ? strings["enum"]["StateUF"]["SE"] || StateUF.SE : StateUF.SE;
        }
        case StateUF.TO: {
            return strings ? strings["enum"]["StateUF"]["TO"] || StateUF.TO : StateUF.TO;
        }
    }
    return "";
}

export function allValuesStateUF(): StateUF[] {
    return [
        StateUF.AC,
        StateUF.AL,
        StateUF.AP,
        StateUF.AM,
        StateUF.BA,
        StateUF.CE,
        StateUF.DF,
        StateUF.ES,
        StateUF.GO,
        StateUF.MA,
        StateUF.MT,
        StateUF.MS,
        StateUF.MG,
        StateUF.PA,
        StateUF.PB,
        StateUF.PR,
        StateUF.PE,
        StateUF.PI,
        StateUF.RJ,
        StateUF.RN,
        StateUF.RS,
        StateUF.RO,
        StateUF.RR,
        StateUF.SC,
        StateUF.SP,
        StateUF.SE,
        StateUF.TO,
    ];
}

export function allTranslatedValuesStateUF(): string[] {
    return [
        translateStateUF(StateUF.AC),
        translateStateUF(StateUF.AL),
        translateStateUF(StateUF.AP),
        translateStateUF(StateUF.AM),
        translateStateUF(StateUF.BA),
        translateStateUF(StateUF.CE),
        translateStateUF(StateUF.DF),
        translateStateUF(StateUF.ES),
        translateStateUF(StateUF.GO),
        translateStateUF(StateUF.MA),
        translateStateUF(StateUF.MT),
        translateStateUF(StateUF.MS),
        translateStateUF(StateUF.MG),
        translateStateUF(StateUF.PA),
        translateStateUF(StateUF.PB),
        translateStateUF(StateUF.PR),
        translateStateUF(StateUF.PE),
        translateStateUF(StateUF.PI),
        translateStateUF(StateUF.RJ),
        translateStateUF(StateUF.RN),
        translateStateUF(StateUF.RS),
        translateStateUF(StateUF.RO),
        translateStateUF(StateUF.RR),
        translateStateUF(StateUF.SC),
        translateStateUF(StateUF.SP),
        translateStateUF(StateUF.SE),
        translateStateUF(StateUF.TO),
    ];
}

export function allDisplayableValuesStateUF(): string[] {
    return allTranslatedValuesStateUF().sort();
}

export function valueFromTranslationStateUF(translation: string): StateUF {
    const index = allTranslatedValuesStateUF().indexOf(translation);
    return allValuesStateUF()[index] || StateUF.AC;
}

export enum PaymentMethod {
    pix = "pix",
    cash = "cash",
    creditCard = "creditCard",
    debitCard = "debitCard",
}

export function translatePaymentMethod(enumPaymentMethod: PaymentMethod): string {
    switch (enumPaymentMethod) {
        case PaymentMethod.pix: {
            return strings ? strings["enum"]["PaymentMethod"]["pix"] || PaymentMethod.pix : PaymentMethod.pix;
        }
        case PaymentMethod.cash: {
            return strings ? strings["enum"]["PaymentMethod"]["cash"] || PaymentMethod.cash : PaymentMethod.cash;
        }
        case PaymentMethod.creditCard: {
            return strings ? strings["enum"]["PaymentMethod"]["creditCard"] || PaymentMethod.creditCard : PaymentMethod.creditCard;
        }
        case PaymentMethod.debitCard: {
            return strings ? strings["enum"]["PaymentMethod"]["debitCard"] || PaymentMethod.debitCard : PaymentMethod.debitCard;
        }
    }
    return "";
}

export function allValuesPaymentMethod(): PaymentMethod[] {
    return [
        PaymentMethod.pix,
        PaymentMethod.cash,
        PaymentMethod.creditCard,
        PaymentMethod.debitCard,
    ];
}

export function allTranslatedValuesPaymentMethod(): string[] {
    return [
        translatePaymentMethod(PaymentMethod.pix),
        translatePaymentMethod(PaymentMethod.cash),
        translatePaymentMethod(PaymentMethod.creditCard),
        translatePaymentMethod(PaymentMethod.debitCard),
    ];
}

export function allDisplayableValuesPaymentMethod(): string[] {
    return allTranslatedValuesPaymentMethod().sort();
}

export function valueFromTranslationPaymentMethod(translation: string): PaymentMethod {
    const index = allTranslatedValuesPaymentMethod().indexOf(translation);
    return allValuesPaymentMethod()[index] || PaymentMethod.pix;
}

export enum AnimalSex {
    male = "male",
    female = "female",
}

export function translateAnimalSex(enumAnimalSex: AnimalSex): string {
    switch (enumAnimalSex) {
        case AnimalSex.male: {
            return strings ? strings["enum"]["AnimalSex"]["male"] || AnimalSex.male : AnimalSex.male;
        }
        case AnimalSex.female: {
            return strings ? strings["enum"]["AnimalSex"]["female"] || AnimalSex.female : AnimalSex.female;
        }
    }
    return "";
}

export function allValuesAnimalSex(): AnimalSex[] {
    return [
        AnimalSex.male,
        AnimalSex.female,
    ];
}

export function allTranslatedValuesAnimalSex(): string[] {
    return [
        translateAnimalSex(AnimalSex.male),
        translateAnimalSex(AnimalSex.female),
    ];
}

export function allDisplayableValuesAnimalSex(): string[] {
    return allTranslatedValuesAnimalSex().sort();
}

export function valueFromTranslationAnimalSex(translation: string): AnimalSex {
    const index = allTranslatedValuesAnimalSex().indexOf(translation);
    return allValuesAnimalSex()[index] || AnimalSex.male;
}

export enum RatingStatus {
    pending = "pending",
    rated = "rated",
}

export function translateRatingStatus(enumRatingStatus: RatingStatus): string {
    switch (enumRatingStatus) {
        case RatingStatus.pending: {
            return strings ? strings["enum"]["RatingStatus"]["pending"] || RatingStatus.pending : RatingStatus.pending;
        }
        case RatingStatus.rated: {
            return strings ? strings["enum"]["RatingStatus"]["rated"] || RatingStatus.rated : RatingStatus.rated;
        }
    }
    return "";
}

export function allValuesRatingStatus(): RatingStatus[] {
    return [
        RatingStatus.pending,
        RatingStatus.rated,
    ];
}

export function allTranslatedValuesRatingStatus(): string[] {
    return [
        translateRatingStatus(RatingStatus.pending),
        translateRatingStatus(RatingStatus.rated),
    ];
}

export function allDisplayableValuesRatingStatus(): string[] {
    return allTranslatedValuesRatingStatus().sort();
}

export function valueFromTranslationRatingStatus(translation: string): RatingStatus {
    const index = allTranslatedValuesRatingStatus().indexOf(translation);
    return allValuesRatingStatus()[index] || RatingStatus.pending;
}

export enum PetParents {
    father = "father",
    mother = "mother",
}

export function translatePetParents(enumPetParents: PetParents): string {
    switch (enumPetParents) {
        case PetParents.father: {
            return strings ? strings["enum"]["PetParents"]["father"] || PetParents.father : PetParents.father;
        }
        case PetParents.mother: {
            return strings ? strings["enum"]["PetParents"]["mother"] || PetParents.mother : PetParents.mother;
        }
    }
    return "";
}

export function allValuesPetParents(): PetParents[] {
    return [
        PetParents.father,
        PetParents.mother,
    ];
}

export function allTranslatedValuesPetParents(): string[] {
    return [
        translatePetParents(PetParents.father),
        translatePetParents(PetParents.mother),
    ];
}

export function allDisplayableValuesPetParents(): string[] {
    return allTranslatedValuesPetParents().sort();
}

export function valueFromTranslationPetParents(translation: string): PetParents {
    const index = allTranslatedValuesPetParents().indexOf(translation);
    return allValuesPetParents()[index] || PetParents.father;
}

export enum Species {
    dog = "dog",
    cat = "cat",
    rodents = "rodents",
    reptiles = "reptiles",
    fish = "fish",
}

export function translateSpecies(enumSpecies: Species): string {
    switch (enumSpecies) {
        case Species.dog: {
            return strings ? strings["enum"]["Species"]["dog"] || Species.dog : Species.dog;
        }
        case Species.cat: {
            return strings ? strings["enum"]["Species"]["cat"] || Species.cat : Species.cat;
        }
        case Species.rodents: {
            return strings ? strings["enum"]["Species"]["rodents"] || Species.rodents : Species.rodents;
        }
        case Species.reptiles: {
            return strings ? strings["enum"]["Species"]["reptiles"] || Species.reptiles : Species.reptiles;
        }
        case Species.fish: {
            return strings ? strings["enum"]["Species"]["fish"] || Species.fish : Species.fish;
        }
    }
    return "";
}

export function allValuesSpecies(): Species[] {
    return [
        Species.dog,
        Species.cat,
        Species.rodents,
        Species.reptiles,
        Species.fish,
    ];
}

export function allTranslatedValuesSpecies(): string[] {
    return [
        translateSpecies(Species.dog),
        translateSpecies(Species.cat),
        translateSpecies(Species.rodents),
        translateSpecies(Species.reptiles),
        translateSpecies(Species.fish),
    ];
}

export function allDisplayableValuesSpecies(): string[] {
    return allTranslatedValuesSpecies().sort();
}

export function valueFromTranslationSpecies(translation: string): Species {
    const index = allTranslatedValuesSpecies().indexOf(translation);
    return allValuesSpecies()[index] || Species.dog;
}

export enum NotificationType {
    consultation = "consultation",
    hospitalization = "hospitalization",
    exam = "exam",
    vaccine = "vaccine",
    medication = "medication",
}

export function translateNotificationType(enumNotificationType: NotificationType): string {
    switch (enumNotificationType) {
        case NotificationType.consultation: {
            return strings ? strings["enum"]["NotificationType"]["consultation"] || NotificationType.consultation : NotificationType.consultation;
        }
        case NotificationType.hospitalization: {
            return strings ? strings["enum"]["NotificationType"]["hospitalization"] || NotificationType.hospitalization : NotificationType.hospitalization;
        }
        case NotificationType.exam: {
            return strings ? strings["enum"]["NotificationType"]["exam"] || NotificationType.exam : NotificationType.exam;
        }
        case NotificationType.vaccine: {
            return strings ? strings["enum"]["NotificationType"]["vaccine"] || NotificationType.vaccine : NotificationType.vaccine;
        }
        case NotificationType.medication: {
            return strings ? strings["enum"]["NotificationType"]["medication"] || NotificationType.medication : NotificationType.medication;
        }
    }
    return "";
}

export function allValuesNotificationType(): NotificationType[] {
    return [
        NotificationType.consultation,
        NotificationType.hospitalization,
        NotificationType.exam,
        NotificationType.vaccine,
        NotificationType.medication,
    ];
}

export function allTranslatedValuesNotificationType(): string[] {
    return [
        translateNotificationType(NotificationType.consultation),
        translateNotificationType(NotificationType.hospitalization),
        translateNotificationType(NotificationType.exam),
        translateNotificationType(NotificationType.vaccine),
        translateNotificationType(NotificationType.medication),
    ];
}

export function allDisplayableValuesNotificationType(): string[] {
    return allTranslatedValuesNotificationType().sort();
}

export function valueFromTranslationNotificationType(translation: string): NotificationType {
    const index = allTranslatedValuesNotificationType().indexOf(translation);
    return allValuesNotificationType()[index] || NotificationType.consultation;
}

export enum AdminUserType {
    master = "master",
    clinic = "clinic",
    marketing = "marketing",
    marketingMaster = "marketingMaster",
}

export function translateAdminUserType(enumAdminUserType: AdminUserType): string {
    switch (enumAdminUserType) {
        case AdminUserType.master: {
            return strings ? strings["enum"]["AdminUserType"]["master"] || AdminUserType.master : AdminUserType.master;
        }
        case AdminUserType.clinic: {
            return strings ? strings["enum"]["AdminUserType"]["clinic"] || AdminUserType.clinic : AdminUserType.clinic;
        }
        case AdminUserType.marketing: {
            return strings ? strings["enum"]["AdminUserType"]["marketing"] || AdminUserType.marketing : AdminUserType.marketing;
        }
        case AdminUserType.marketingMaster: {
            return strings ? strings["enum"]["AdminUserType"]["marketingMaster"] || AdminUserType.marketingMaster : AdminUserType.marketingMaster;
        }
    }
    return "";
}

export function allValuesAdminUserType(): AdminUserType[] {
    return [
        AdminUserType.master,
        AdminUserType.clinic,
        AdminUserType.marketing,
        AdminUserType.marketingMaster,
    ];
}

export function allTranslatedValuesAdminUserType(): string[] {
    return [
        translateAdminUserType(AdminUserType.master),
        translateAdminUserType(AdminUserType.clinic),
        translateAdminUserType(AdminUserType.marketing),
        translateAdminUserType(AdminUserType.marketingMaster),
    ];
}

export function allDisplayableValuesAdminUserType(): string[] {
    return allTranslatedValuesAdminUserType().sort();
}

export function valueFromTranslationAdminUserType(translation: string): AdminUserType {
    const index = allTranslatedValuesAdminUserType().indexOf(translation);
    return allValuesAdminUserType()[index] || AdminUserType.master;
}

export enum ImageFormat {
    png = "png",
    jpeg = "jpeg",
}

export function translateImageFormat(enumImageFormat: ImageFormat): string {
    switch (enumImageFormat) {
        case ImageFormat.png: {
            return strings ? strings["enum"]["ImageFormat"]["png"] || ImageFormat.png : ImageFormat.png;
        }
        case ImageFormat.jpeg: {
            return strings ? strings["enum"]["ImageFormat"]["jpeg"] || ImageFormat.jpeg : ImageFormat.jpeg;
        }
    }
    return "";
}

export function allValuesImageFormat(): ImageFormat[] {
    return [
        ImageFormat.png,
        ImageFormat.jpeg,
    ];
}

export function allTranslatedValuesImageFormat(): string[] {
    return [
        translateImageFormat(ImageFormat.png),
        translateImageFormat(ImageFormat.jpeg),
    ];
}

export function allDisplayableValuesImageFormat(): string[] {
    return allTranslatedValuesImageFormat().sort();
}

export function valueFromTranslationImageFormat(translation: string): ImageFormat {
    const index = allTranslatedValuesImageFormat().indexOf(translation);
    return allValuesImageFormat()[index] || ImageFormat.png;
}

export enum FileFormat {
    pdf = "pdf",
}

export function translateFileFormat(enumFileFormat: FileFormat): string {
    switch (enumFileFormat) {
        case FileFormat.pdf: {
            return strings ? strings["enum"]["FileFormat"]["pdf"] || FileFormat.pdf : FileFormat.pdf;
        }
    }
    return "";
}

export function allValuesFileFormat(): FileFormat[] {
    return [
        FileFormat.pdf,
    ];
}

export function allTranslatedValuesFileFormat(): string[] {
    return [
        translateFileFormat(FileFormat.pdf),
    ];
}

export function allDisplayableValuesFileFormat(): string[] {
    return allTranslatedValuesFileFormat().sort();
}

export function valueFromTranslationFileFormat(translation: string): FileFormat {
    const index = allTranslatedValuesFileFormat().indexOf(translation);
    return allValuesFileFormat()[index] || FileFormat.pdf;
}

export enum ErrorType {
    NotFound = "NotFound",
    MissingArgument = "MissingArgument",
    InvalidArgument = "InvalidArgument",
    InvalidPermission = "InvalidPermission",
    BadFormattedResponse = "BadFormattedResponse",
    InternalError = "InternalError",
    Validation = "Validation",
    EmailOrPasswordWrong = "EmailOrPasswordWrong",
    AccessNotAllowed = "AccessNotAllowed",
    NotLoggedIn = "NotLoggedIn",
    EmailAlreadyRegistered = "EmailAlreadyRegistered",
    AlreadyRegistered = "AlreadyRegistered",
    AlreadyFinished = "AlreadyFinished",
    IsAfterDate = "IsAfterDate",
    IsBeforeDate = "IsBeforeDate",
    Fatal = "Fatal",
    Connection = "Connection",
}

export function translateErrorType(enumErrorType: ErrorType): string {
    switch (enumErrorType) {
        case ErrorType.NotFound: {
            return strings ? strings["enum"]["ErrorType"]["NotFound"] || ErrorType.NotFound : ErrorType.NotFound;
        }
        case ErrorType.MissingArgument: {
            return strings ? strings["enum"]["ErrorType"]["MissingArgument"] || ErrorType.MissingArgument : ErrorType.MissingArgument;
        }
        case ErrorType.InvalidArgument: {
            return strings ? strings["enum"]["ErrorType"]["InvalidArgument"] || ErrorType.InvalidArgument : ErrorType.InvalidArgument;
        }
        case ErrorType.InvalidPermission: {
            return strings ? strings["enum"]["ErrorType"]["InvalidPermission"] || ErrorType.InvalidPermission : ErrorType.InvalidPermission;
        }
        case ErrorType.BadFormattedResponse: {
            return strings ? strings["enum"]["ErrorType"]["BadFormattedResponse"] || ErrorType.BadFormattedResponse : ErrorType.BadFormattedResponse;
        }
        case ErrorType.InternalError: {
            return strings ? strings["enum"]["ErrorType"]["InternalError"] || ErrorType.InternalError : ErrorType.InternalError;
        }
        case ErrorType.Validation: {
            return strings ? strings["enum"]["ErrorType"]["Validation"] || ErrorType.Validation : ErrorType.Validation;
        }
        case ErrorType.EmailOrPasswordWrong: {
            return strings ? strings["enum"]["ErrorType"]["EmailOrPasswordWrong"] || ErrorType.EmailOrPasswordWrong : ErrorType.EmailOrPasswordWrong;
        }
        case ErrorType.AccessNotAllowed: {
            return strings ? strings["enum"]["ErrorType"]["AccessNotAllowed"] || ErrorType.AccessNotAllowed : ErrorType.AccessNotAllowed;
        }
        case ErrorType.NotLoggedIn: {
            return strings ? strings["enum"]["ErrorType"]["NotLoggedIn"] || ErrorType.NotLoggedIn : ErrorType.NotLoggedIn;
        }
        case ErrorType.EmailAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["EmailAlreadyRegistered"] || ErrorType.EmailAlreadyRegistered : ErrorType.EmailAlreadyRegistered;
        }
        case ErrorType.AlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyRegistered"] || ErrorType.AlreadyRegistered : ErrorType.AlreadyRegistered;
        }
        case ErrorType.AlreadyFinished: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyFinished"] || ErrorType.AlreadyFinished : ErrorType.AlreadyFinished;
        }
        case ErrorType.IsAfterDate: {
            return strings ? strings["enum"]["ErrorType"]["IsAfterDate"] || ErrorType.IsAfterDate : ErrorType.IsAfterDate;
        }
        case ErrorType.IsBeforeDate: {
            return strings ? strings["enum"]["ErrorType"]["IsBeforeDate"] || ErrorType.IsBeforeDate : ErrorType.IsBeforeDate;
        }
        case ErrorType.Fatal: {
            return strings ? strings["enum"]["ErrorType"]["Fatal"] || ErrorType.Fatal : ErrorType.Fatal;
        }
        case ErrorType.Connection: {
            return strings ? strings["enum"]["ErrorType"]["Connection"] || ErrorType.Connection : ErrorType.Connection;
        }
    }
    return "";
}

export function allValuesErrorType(): ErrorType[] {
    return [
        ErrorType.NotFound,
        ErrorType.MissingArgument,
        ErrorType.InvalidArgument,
        ErrorType.InvalidPermission,
        ErrorType.BadFormattedResponse,
        ErrorType.InternalError,
        ErrorType.Validation,
        ErrorType.EmailOrPasswordWrong,
        ErrorType.AccessNotAllowed,
        ErrorType.NotLoggedIn,
        ErrorType.EmailAlreadyRegistered,
        ErrorType.AlreadyRegistered,
        ErrorType.AlreadyFinished,
        ErrorType.IsAfterDate,
        ErrorType.IsBeforeDate,
        ErrorType.Fatal,
        ErrorType.Connection,
    ];
}

export function allTranslatedValuesErrorType(): string[] {
    return [
        translateErrorType(ErrorType.NotFound),
        translateErrorType(ErrorType.MissingArgument),
        translateErrorType(ErrorType.InvalidArgument),
        translateErrorType(ErrorType.InvalidPermission),
        translateErrorType(ErrorType.BadFormattedResponse),
        translateErrorType(ErrorType.InternalError),
        translateErrorType(ErrorType.Validation),
        translateErrorType(ErrorType.EmailOrPasswordWrong),
        translateErrorType(ErrorType.AccessNotAllowed),
        translateErrorType(ErrorType.NotLoggedIn),
        translateErrorType(ErrorType.EmailAlreadyRegistered),
        translateErrorType(ErrorType.AlreadyRegistered),
        translateErrorType(ErrorType.AlreadyFinished),
        translateErrorType(ErrorType.IsAfterDate),
        translateErrorType(ErrorType.IsBeforeDate),
        translateErrorType(ErrorType.Fatal),
        translateErrorType(ErrorType.Connection),
    ];
}

export function allDisplayableValuesErrorType(): string[] {
    return allTranslatedValuesErrorType().sort();
}

export function valueFromTranslationErrorType(translation: string): ErrorType {
    const index = allTranslatedValuesErrorType().indexOf(translation);
    return allValuesErrorType()[index] || ErrorType.NotFound;
}

export async function uploadUncertainFile(file: UncertainFile, fileFormat: FileFormat | null, progress?: (progress: number) => void): Promise<string> {
    const args = {
        file: {
            bytes: file.bytes === null || file.bytes === undefined ? null : file.bytes.toString("base64"),
            url: file.url === null || file.url === undefined ? null : file.url,
        },
        fileFormat: fileFormat === null || fileFormat === undefined ? null : fileFormat,
    };
    const ret = await makeRequest({name: "uploadUncertainFile", args, progress});
    return ret;
}

export async function uploadImage(image: Buffer, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncertainImage(image: UncertainImage, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: {
            bytes: image.bytes === null || image.bytes === undefined ? null : image.bytes.toString("base64"),
            image: image.image === null || image.image === undefined ? null : {
                thumb: {
                    width: image.image.thumb.width || 0,
                    height: image.image.thumb.height || 0,
                    url: image.image.thumb.url,
                },
                width: image.image.width || 0,
                height: image.image.height || 0,
                url: image.image.url,
            },
        },
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncertainImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncompressedImage(image: Buffer, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncompressedImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncompressedUncertainImage(image: UncertainImage, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: {
            bytes: image.bytes === null || image.bytes === undefined ? null : image.bytes.toString("base64"),
            image: image.image === null || image.image === undefined ? null : {
                thumb: {
                    width: image.image.thumb.width || 0,
                    height: image.image.thumb.height || 0,
                    url: image.image.thumb.url,
                },
                width: image.image.width || 0,
                height: image.image.height || 0,
                url: image.image.url,
            },
        },
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncompressedUncertainImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function getStatsForClinic(clinicId: string, filterByPeriod: FilterByPeriod, progress?: (progress: number) => void): Promise<ClinicStats> {
    const args = {
        clinicId: clinicId,
        filterByPeriod: {
            startDate: filterByPeriod.startDate === null || filterByPeriod.startDate === undefined ? null : (typeof filterByPeriod.startDate === "string" && (filterByPeriod.startDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterByPeriod.startDate as any).replace("Z", "") : filterByPeriod.startDate.toISOString().replace("Z", "")),
            endDate: filterByPeriod.endDate === null || filterByPeriod.endDate === undefined ? null : (typeof filterByPeriod.endDate === "string" && (filterByPeriod.endDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterByPeriod.endDate as any).replace("Z", "") : filterByPeriod.endDate.toISOString().replace("Z", "")),
        },
    };
    const ret = await makeRequest({name: "getStatsForClinic", args, progress});
    return {
        pets: ret.pets || 0,
        hospitalizedPets: ret.hospitalizedPets || 0,
        tutors: ret.tutors || 0,
        consultations: ret.consultations || 0,
    };
}

export async function getStatsForAdmin(filterByPeriod: FilterByPeriod, progress?: (progress: number) => void): Promise<ClinicStatsForAdmin> {
    const args = {
        filterByPeriod: {
            startDate: filterByPeriod.startDate === null || filterByPeriod.startDate === undefined ? null : (typeof filterByPeriod.startDate === "string" && (filterByPeriod.startDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterByPeriod.startDate as any).replace("Z", "") : filterByPeriod.startDate.toISOString().replace("Z", "")),
            endDate: filterByPeriod.endDate === null || filterByPeriod.endDate === undefined ? null : (typeof filterByPeriod.endDate === "string" && (filterByPeriod.endDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterByPeriod.endDate as any).replace("Z", "") : filterByPeriod.endDate.toISOString().replace("Z", "")),
        },
    };
    const ret = await makeRequest({name: "getStatsForAdmin", args, progress});
    return {
        clinics: ret.clinics || 0,
        pets: ret.pets || 0,
        hospitalizedPets: ret.hospitalizedPets || 0,
        tutors: ret.tutors || 0,
        consultations: ret.consultations || 0,
    };
}

export async function editClinic(id: string, editClinic: EditClinic, progress?: (progress: number) => void): Promise<Clinic> {
    const args = {
        id: id,
        editClinic: {
            image: {
                bytes: editClinic.image.bytes === null || editClinic.image.bytes === undefined ? null : editClinic.image.bytes.toString("base64"),
                image: editClinic.image.image === null || editClinic.image.image === undefined ? null : {
                    thumb: {
                        width: editClinic.image.image.thumb.width || 0,
                        height: editClinic.image.image.thumb.height || 0,
                        url: editClinic.image.image.thumb.url,
                    },
                    width: editClinic.image.image.width || 0,
                    height: editClinic.image.image.height || 0,
                    url: editClinic.image.image.url,
                },
            },
            phone: editClinic.phone,
            address: {
                zipcode: editClinic.address.zipcode,
                street: editClinic.address.street,
                streetNumber: editClinic.address.streetNumber,
                complementary: editClinic.address.complementary,
                neighborhood: editClinic.address.neighborhood,
                city: editClinic.address.city,
                state: editClinic.address.state,
            },
        },
    };
    const ret = await makeRequest({name: "editClinic", args, progress});
    return {
        id: ret.id,
        image: {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        phone: ret.phone,
        showInSite: !!ret.showInSite,
        address: {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
        },
        code: ret.code || 0,
        codeFormat: ret.codeFormat,
        services: ret.services === null || ret.services === undefined ? null : ret.services,
        createdAt: new Date(ret.createdAt + "Z"),
        adminUser: ret.adminUser === null || ret.adminUser === undefined ? null : {
            name: ret.adminUser.name,
            documentNumber: ret.adminUser.documentNumber,
        },
    };
}

export async function getAdminUser(id: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getAdminUser", args, progress});
    return {
        id: ret.id,
        clinic: ret.clinic === null || ret.clinic === undefined ? null : {
            id: ret.clinic.id,
            image: {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            phone: ret.clinic.phone,
            showInSite: !!ret.clinic.showInSite,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
            },
            code: ret.clinic.code || 0,
            codeFormat: ret.clinic.codeFormat,
            services: ret.clinic.services === null || ret.clinic.services === undefined ? null : ret.clinic.services,
            createdAt: new Date(ret.clinic.createdAt + "Z"),
            adminUser: ret.clinic.adminUser === null || ret.clinic.adminUser === undefined ? null : {
                name: ret.clinic.adminUser.name,
                documentNumber: ret.clinic.adminUser.documentNumber,
            },
        },
        type: ret.type,
        createdAt: new Date(ret.createdAt + "Z"),
        name: ret.name,
        email: ret.email,
        documentNumber: ret.documentNumber,
    };
}

export async function getCurrentAdminLogged(progress?: (progress: number) => void): Promise<AdminUser> {
    const ret = await makeRequest({name: "getCurrentAdminLogged", args: {}, progress});
    return {
        id: ret.id,
        clinic: ret.clinic === null || ret.clinic === undefined ? null : {
            id: ret.clinic.id,
            image: {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            phone: ret.clinic.phone,
            showInSite: !!ret.clinic.showInSite,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
            },
            code: ret.clinic.code || 0,
            codeFormat: ret.clinic.codeFormat,
            services: ret.clinic.services === null || ret.clinic.services === undefined ? null : ret.clinic.services,
            createdAt: new Date(ret.clinic.createdAt + "Z"),
            adminUser: ret.clinic.adminUser === null || ret.clinic.adminUser === undefined ? null : {
                name: ret.clinic.adminUser.name,
                documentNumber: ret.clinic.adminUser.documentNumber,
            },
        },
        type: ret.type,
        createdAt: new Date(ret.createdAt + "Z"),
        name: ret.name,
        email: ret.email,
        documentNumber: ret.documentNumber,
    };
}

export async function getAllAdminUsers(pageOffset: number, progress?: (progress: number) => void): Promise<AdminUser[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllAdminUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        clinic: e.clinic === null || e.clinic === undefined ? null : {
            id: e.clinic.id,
            image: {
                thumb: {
                    width: e.clinic.image.thumb.width || 0,
                    height: e.clinic.image.thumb.height || 0,
                    url: e.clinic.image.thumb.url,
                },
                width: e.clinic.image.width || 0,
                height: e.clinic.image.height || 0,
                url: e.clinic.image.url,
            },
            phone: e.clinic.phone,
            showInSite: !!e.clinic.showInSite,
            address: {
                zipcode: e.clinic.address.zipcode,
                street: e.clinic.address.street,
                streetNumber: e.clinic.address.streetNumber,
                complementary: e.clinic.address.complementary,
                neighborhood: e.clinic.address.neighborhood,
                city: e.clinic.address.city,
                state: e.clinic.address.state,
            },
            code: e.clinic.code || 0,
            codeFormat: e.clinic.codeFormat,
            services: e.clinic.services === null || e.clinic.services === undefined ? null : e.clinic.services,
            createdAt: new Date(e.clinic.createdAt + "Z"),
            adminUser: e.clinic.adminUser === null || e.clinic.adminUser === undefined ? null : {
                name: e.clinic.adminUser.name,
                documentNumber: e.clinic.adminUser.documentNumber,
            },
        },
        type: e.type,
        createdAt: new Date(e.createdAt + "Z"),
        name: e.name,
        email: e.email,
        documentNumber: e.documentNumber,
    }));
}

export async function createAdminUser(newAdminUser: NewAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        newAdminUser: {
            password: newAdminUser.password,
            name: newAdminUser.name,
            email: newAdminUser.email,
            documentNumber: newAdminUser.documentNumber,
        },
    };
    const ret = await makeRequest({name: "createAdminUser", args, progress});
    return {
        id: ret.id,
        clinic: ret.clinic === null || ret.clinic === undefined ? null : {
            id: ret.clinic.id,
            image: {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            phone: ret.clinic.phone,
            showInSite: !!ret.clinic.showInSite,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
            },
            code: ret.clinic.code || 0,
            codeFormat: ret.clinic.codeFormat,
            services: ret.clinic.services === null || ret.clinic.services === undefined ? null : ret.clinic.services,
            createdAt: new Date(ret.clinic.createdAt + "Z"),
            adminUser: ret.clinic.adminUser === null || ret.clinic.adminUser === undefined ? null : {
                name: ret.clinic.adminUser.name,
                documentNumber: ret.clinic.adminUser.documentNumber,
            },
        },
        type: ret.type,
        createdAt: new Date(ret.createdAt + "Z"),
        name: ret.name,
        email: ret.email,
        documentNumber: ret.documentNumber,
    };
}

export async function editAdminUser(id: string, editAdminUser: EditAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
        editAdminUser: {
            name: editAdminUser.name,
            email: editAdminUser.email,
            documentNumber: editAdminUser.documentNumber,
        },
    };
    const ret = await makeRequest({name: "editAdminUser", args, progress});
    return {
        id: ret.id,
        clinic: ret.clinic === null || ret.clinic === undefined ? null : {
            id: ret.clinic.id,
            image: {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            phone: ret.clinic.phone,
            showInSite: !!ret.clinic.showInSite,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
            },
            code: ret.clinic.code || 0,
            codeFormat: ret.clinic.codeFormat,
            services: ret.clinic.services === null || ret.clinic.services === undefined ? null : ret.clinic.services,
            createdAt: new Date(ret.clinic.createdAt + "Z"),
            adminUser: ret.clinic.adminUser === null || ret.clinic.adminUser === undefined ? null : {
                name: ret.clinic.adminUser.name,
                documentNumber: ret.clinic.adminUser.documentNumber,
            },
        },
        type: ret.type,
        createdAt: new Date(ret.createdAt + "Z"),
        name: ret.name,
        email: ret.email,
        documentNumber: ret.documentNumber,
    };
}

export async function deleteAdminUser(id: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteAdminUser", args, progress});
    return {
        id: ret.id,
        clinic: ret.clinic === null || ret.clinic === undefined ? null : {
            id: ret.clinic.id,
            image: {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            phone: ret.clinic.phone,
            showInSite: !!ret.clinic.showInSite,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
            },
            code: ret.clinic.code || 0,
            codeFormat: ret.clinic.codeFormat,
            services: ret.clinic.services === null || ret.clinic.services === undefined ? null : ret.clinic.services,
            createdAt: new Date(ret.clinic.createdAt + "Z"),
            adminUser: ret.clinic.adminUser === null || ret.clinic.adminUser === undefined ? null : {
                name: ret.clinic.adminUser.name,
                documentNumber: ret.clinic.adminUser.documentNumber,
            },
        },
        type: ret.type,
        createdAt: new Date(ret.createdAt + "Z"),
        name: ret.name,
        email: ret.email,
        documentNumber: ret.documentNumber,
    };
}

export async function adminLogin(email: string, password: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        email: email,
        password: password,
    };
    const ret = await makeRequest({name: "adminLogin", args, progress});
    return {
        id: ret.id,
        clinic: ret.clinic === null || ret.clinic === undefined ? null : {
            id: ret.clinic.id,
            image: {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            phone: ret.clinic.phone,
            showInSite: !!ret.clinic.showInSite,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
            },
            code: ret.clinic.code || 0,
            codeFormat: ret.clinic.codeFormat,
            services: ret.clinic.services === null || ret.clinic.services === undefined ? null : ret.clinic.services,
            createdAt: new Date(ret.clinic.createdAt + "Z"),
            adminUser: ret.clinic.adminUser === null || ret.clinic.adminUser === undefined ? null : {
                name: ret.clinic.adminUser.name,
                documentNumber: ret.clinic.adminUser.documentNumber,
            },
        },
        type: ret.type,
        createdAt: new Date(ret.createdAt + "Z"),
        name: ret.name,
        email: ret.email,
        documentNumber: ret.documentNumber,
    };
}

export async function logoutAdminUser(progress?: (progress: number) => void): Promise<void> {
    await makeRequest({name: "logoutAdminUser", args: {}, progress});
    return undefined;
}

export async function createClinicAdminUser(newClinic: NewClinicAdmin, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        newClinic: {
            clinic: {
                image: {
                    bytes: newClinic.clinic.image.bytes === null || newClinic.clinic.image.bytes === undefined ? null : newClinic.clinic.image.bytes.toString("base64"),
                    image: newClinic.clinic.image.image === null || newClinic.clinic.image.image === undefined ? null : {
                        thumb: {
                            width: newClinic.clinic.image.image.thumb.width || 0,
                            height: newClinic.clinic.image.image.thumb.height || 0,
                            url: newClinic.clinic.image.image.thumb.url,
                        },
                        width: newClinic.clinic.image.image.width || 0,
                        height: newClinic.clinic.image.image.height || 0,
                        url: newClinic.clinic.image.image.url,
                    },
                },
                phone: newClinic.clinic.phone,
                address: {
                    zipcode: newClinic.clinic.address.zipcode,
                    street: newClinic.clinic.address.street,
                    streetNumber: newClinic.clinic.address.streetNumber,
                    complementary: newClinic.clinic.address.complementary,
                    neighborhood: newClinic.clinic.address.neighborhood,
                    city: newClinic.clinic.address.city,
                    state: newClinic.clinic.address.state,
                },
            },
            password: newClinic.password,
            name: newClinic.name,
            email: newClinic.email,
            documentNumber: newClinic.documentNumber,
        },
    };
    const ret = await makeRequest({name: "createClinicAdminUser", args, progress});
    return {
        id: ret.id,
        clinic: ret.clinic === null || ret.clinic === undefined ? null : {
            id: ret.clinic.id,
            image: {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            phone: ret.clinic.phone,
            showInSite: !!ret.clinic.showInSite,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
            },
            code: ret.clinic.code || 0,
            codeFormat: ret.clinic.codeFormat,
            services: ret.clinic.services === null || ret.clinic.services === undefined ? null : ret.clinic.services,
            createdAt: new Date(ret.clinic.createdAt + "Z"),
            adminUser: ret.clinic.adminUser === null || ret.clinic.adminUser === undefined ? null : {
                name: ret.clinic.adminUser.name,
                documentNumber: ret.clinic.adminUser.documentNumber,
            },
        },
        type: ret.type,
        createdAt: new Date(ret.createdAt + "Z"),
        name: ret.name,
        email: ret.email,
        documentNumber: ret.documentNumber,
    };
}

export async function editClinicAdminUser(id: string, editClinic: EditClinicAdmin, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
        editClinic: {
            clinic: {
                image: {
                    bytes: editClinic.clinic.image.bytes === null || editClinic.clinic.image.bytes === undefined ? null : editClinic.clinic.image.bytes.toString("base64"),
                    image: editClinic.clinic.image.image === null || editClinic.clinic.image.image === undefined ? null : {
                        thumb: {
                            width: editClinic.clinic.image.image.thumb.width || 0,
                            height: editClinic.clinic.image.image.thumb.height || 0,
                            url: editClinic.clinic.image.image.thumb.url,
                        },
                        width: editClinic.clinic.image.image.width || 0,
                        height: editClinic.clinic.image.image.height || 0,
                        url: editClinic.clinic.image.image.url,
                    },
                },
                phone: editClinic.clinic.phone,
                address: {
                    zipcode: editClinic.clinic.address.zipcode,
                    street: editClinic.clinic.address.street,
                    streetNumber: editClinic.clinic.address.streetNumber,
                    complementary: editClinic.clinic.address.complementary,
                    neighborhood: editClinic.clinic.address.neighborhood,
                    city: editClinic.clinic.address.city,
                    state: editClinic.clinic.address.state,
                },
            },
            name: editClinic.name,
            email: editClinic.email,
            documentNumber: editClinic.documentNumber,
        },
    };
    const ret = await makeRequest({name: "editClinicAdminUser", args, progress});
    return {
        id: ret.id,
        clinic: ret.clinic === null || ret.clinic === undefined ? null : {
            id: ret.clinic.id,
            image: {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            phone: ret.clinic.phone,
            showInSite: !!ret.clinic.showInSite,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
            },
            code: ret.clinic.code || 0,
            codeFormat: ret.clinic.codeFormat,
            services: ret.clinic.services === null || ret.clinic.services === undefined ? null : ret.clinic.services,
            createdAt: new Date(ret.clinic.createdAt + "Z"),
            adminUser: ret.clinic.adminUser === null || ret.clinic.adminUser === undefined ? null : {
                name: ret.clinic.adminUser.name,
                documentNumber: ret.clinic.adminUser.documentNumber,
            },
        },
        type: ret.type,
        createdAt: new Date(ret.createdAt + "Z"),
        name: ret.name,
        email: ret.email,
        documentNumber: ret.documentNumber,
    };
}

export async function getAllClinicAdminUsers(pageOffset: number, progress?: (progress: number) => void): Promise<AdminUser[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllClinicAdminUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        clinic: e.clinic === null || e.clinic === undefined ? null : {
            id: e.clinic.id,
            image: {
                thumb: {
                    width: e.clinic.image.thumb.width || 0,
                    height: e.clinic.image.thumb.height || 0,
                    url: e.clinic.image.thumb.url,
                },
                width: e.clinic.image.width || 0,
                height: e.clinic.image.height || 0,
                url: e.clinic.image.url,
            },
            phone: e.clinic.phone,
            showInSite: !!e.clinic.showInSite,
            address: {
                zipcode: e.clinic.address.zipcode,
                street: e.clinic.address.street,
                streetNumber: e.clinic.address.streetNumber,
                complementary: e.clinic.address.complementary,
                neighborhood: e.clinic.address.neighborhood,
                city: e.clinic.address.city,
                state: e.clinic.address.state,
            },
            code: e.clinic.code || 0,
            codeFormat: e.clinic.codeFormat,
            services: e.clinic.services === null || e.clinic.services === undefined ? null : e.clinic.services,
            createdAt: new Date(e.clinic.createdAt + "Z"),
            adminUser: e.clinic.adminUser === null || e.clinic.adminUser === undefined ? null : {
                name: e.clinic.adminUser.name,
                documentNumber: e.clinic.adminUser.documentNumber,
            },
        },
        type: e.type,
        createdAt: new Date(e.createdAt + "Z"),
        name: e.name,
        email: e.email,
        documentNumber: e.documentNumber,
    }));
}

export async function createClinicAdminUserToShowInSite(newClinic: NewClinicAdminToSite, progress?: (progress: number) => void): Promise<AdminUserToSite> {
    const args = {
        newClinic: {
            clinic: {
                phone: newClinic.clinic.phone,
                image: {
                    bytes: newClinic.clinic.image.bytes === null || newClinic.clinic.image.bytes === undefined ? null : newClinic.clinic.image.bytes.toString("base64"),
                    image: newClinic.clinic.image.image === null || newClinic.clinic.image.image === undefined ? null : {
                        thumb: {
                            width: newClinic.clinic.image.image.thumb.width || 0,
                            height: newClinic.clinic.image.image.thumb.height || 0,
                            url: newClinic.clinic.image.image.thumb.url,
                        },
                        width: newClinic.clinic.image.image.width || 0,
                        height: newClinic.clinic.image.image.height || 0,
                        url: newClinic.clinic.image.image.url,
                    },
                },
                services: newClinic.clinic.services,
                address: {
                    zipcode: newClinic.clinic.address.zipcode,
                    street: newClinic.clinic.address.street,
                    streetNumber: newClinic.clinic.address.streetNumber,
                    complementary: newClinic.clinic.address.complementary,
                    neighborhood: newClinic.clinic.address.neighborhood,
                    city: newClinic.clinic.address.city,
                    state: newClinic.clinic.address.state,
                },
            },
            name: newClinic.name,
            email: newClinic.email,
            documentNumber: newClinic.documentNumber,
        },
    };
    const ret = await makeRequest({name: "createClinicAdminUserToShowInSite", args, progress});
    return {
        id: ret.id,
        clinic: ret.clinic === null || ret.clinic === undefined ? null : {
            id: ret.clinic.id,
            image: {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            phone: ret.clinic.phone,
            showInSite: !!ret.clinic.showInSite,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
            },
            code: ret.clinic.code || 0,
            codeFormat: ret.clinic.codeFormat,
            services: ret.clinic.services === null || ret.clinic.services === undefined ? null : ret.clinic.services,
            createdAt: new Date(ret.clinic.createdAt + "Z"),
            adminUser: ret.clinic.adminUser === null || ret.clinic.adminUser === undefined ? null : {
                name: ret.clinic.adminUser.name,
                documentNumber: ret.clinic.adminUser.documentNumber,
            },
        },
        createdAt: new Date(ret.createdAt + "Z"),
        name: ret.name,
        email: ret.email,
        documentNumber: ret.documentNumber,
    };
}

export async function getAllShowInSiteClinicAdminUsers(pageOffset: number, progress?: (progress: number) => void): Promise<AdminUserToSite[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllShowInSiteClinicAdminUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        clinic: e.clinic === null || e.clinic === undefined ? null : {
            id: e.clinic.id,
            image: {
                thumb: {
                    width: e.clinic.image.thumb.width || 0,
                    height: e.clinic.image.thumb.height || 0,
                    url: e.clinic.image.thumb.url,
                },
                width: e.clinic.image.width || 0,
                height: e.clinic.image.height || 0,
                url: e.clinic.image.url,
            },
            phone: e.clinic.phone,
            showInSite: !!e.clinic.showInSite,
            address: {
                zipcode: e.clinic.address.zipcode,
                street: e.clinic.address.street,
                streetNumber: e.clinic.address.streetNumber,
                complementary: e.clinic.address.complementary,
                neighborhood: e.clinic.address.neighborhood,
                city: e.clinic.address.city,
                state: e.clinic.address.state,
            },
            code: e.clinic.code || 0,
            codeFormat: e.clinic.codeFormat,
            services: e.clinic.services === null || e.clinic.services === undefined ? null : e.clinic.services,
            createdAt: new Date(e.clinic.createdAt + "Z"),
            adminUser: e.clinic.adminUser === null || e.clinic.adminUser === undefined ? null : {
                name: e.clinic.adminUser.name,
                documentNumber: e.clinic.adminUser.documentNumber,
            },
        },
        createdAt: new Date(e.createdAt + "Z"),
        name: e.name,
        email: e.email,
        documentNumber: e.documentNumber,
    }));
}

export async function editShowInSiteClinicAdminUser(id: string, editClinic: NewClinicAdminToSite, progress?: (progress: number) => void): Promise<AdminUserToSite> {
    const args = {
        id: id,
        editClinic: {
            clinic: {
                phone: editClinic.clinic.phone,
                image: {
                    bytes: editClinic.clinic.image.bytes === null || editClinic.clinic.image.bytes === undefined ? null : editClinic.clinic.image.bytes.toString("base64"),
                    image: editClinic.clinic.image.image === null || editClinic.clinic.image.image === undefined ? null : {
                        thumb: {
                            width: editClinic.clinic.image.image.thumb.width || 0,
                            height: editClinic.clinic.image.image.thumb.height || 0,
                            url: editClinic.clinic.image.image.thumb.url,
                        },
                        width: editClinic.clinic.image.image.width || 0,
                        height: editClinic.clinic.image.image.height || 0,
                        url: editClinic.clinic.image.image.url,
                    },
                },
                services: editClinic.clinic.services,
                address: {
                    zipcode: editClinic.clinic.address.zipcode,
                    street: editClinic.clinic.address.street,
                    streetNumber: editClinic.clinic.address.streetNumber,
                    complementary: editClinic.clinic.address.complementary,
                    neighborhood: editClinic.clinic.address.neighborhood,
                    city: editClinic.clinic.address.city,
                    state: editClinic.clinic.address.state,
                },
            },
            name: editClinic.name,
            email: editClinic.email,
            documentNumber: editClinic.documentNumber,
        },
    };
    const ret = await makeRequest({name: "editShowInSiteClinicAdminUser", args, progress});
    return {
        id: ret.id,
        clinic: ret.clinic === null || ret.clinic === undefined ? null : {
            id: ret.clinic.id,
            image: {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            phone: ret.clinic.phone,
            showInSite: !!ret.clinic.showInSite,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
            },
            code: ret.clinic.code || 0,
            codeFormat: ret.clinic.codeFormat,
            services: ret.clinic.services === null || ret.clinic.services === undefined ? null : ret.clinic.services,
            createdAt: new Date(ret.clinic.createdAt + "Z"),
            adminUser: ret.clinic.adminUser === null || ret.clinic.adminUser === undefined ? null : {
                name: ret.clinic.adminUser.name,
                documentNumber: ret.clinic.adminUser.documentNumber,
            },
        },
        createdAt: new Date(ret.createdAt + "Z"),
        name: ret.name,
        email: ret.email,
        documentNumber: ret.documentNumber,
    };
}

export async function createMarketingAdmin(newMarketingAdmin: NewAdminUser, progress?: (progress: number) => void): Promise<MarketingAdmin> {
    const args = {
        newMarketingAdmin: {
            password: newMarketingAdmin.password,
            name: newMarketingAdmin.name,
            email: newMarketingAdmin.email,
            documentNumber: newMarketingAdmin.documentNumber,
        },
    };
    const ret = await makeRequest({name: "createMarketingAdmin", args, progress});
    return {
        id: ret.id,
        clinic: ret.clinic === null || ret.clinic === undefined ? null : {
            id: ret.clinic.id,
            image: {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            phone: ret.clinic.phone,
            showInSite: !!ret.clinic.showInSite,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
            },
            code: ret.clinic.code || 0,
            codeFormat: ret.clinic.codeFormat,
            services: ret.clinic.services === null || ret.clinic.services === undefined ? null : ret.clinic.services,
            createdAt: new Date(ret.clinic.createdAt + "Z"),
            adminUser: ret.clinic.adminUser === null || ret.clinic.adminUser === undefined ? null : {
                name: ret.clinic.adminUser.name,
                documentNumber: ret.clinic.adminUser.documentNumber,
            },
        },
        type: ret.type,
        createdAt: new Date(ret.createdAt + "Z"),
        name: ret.name,
        email: ret.email,
        documentNumber: ret.documentNumber,
    };
}

export async function createMarketingMasterAdmin(newMarketingAdmin: NewAdminUser, progress?: (progress: number) => void): Promise<MarketingAdmin> {
    const args = {
        newMarketingAdmin: {
            password: newMarketingAdmin.password,
            name: newMarketingAdmin.name,
            email: newMarketingAdmin.email,
            documentNumber: newMarketingAdmin.documentNumber,
        },
    };
    const ret = await makeRequest({name: "createMarketingMasterAdmin", args, progress});
    return {
        id: ret.id,
        clinic: ret.clinic === null || ret.clinic === undefined ? null : {
            id: ret.clinic.id,
            image: {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            phone: ret.clinic.phone,
            showInSite: !!ret.clinic.showInSite,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
            },
            code: ret.clinic.code || 0,
            codeFormat: ret.clinic.codeFormat,
            services: ret.clinic.services === null || ret.clinic.services === undefined ? null : ret.clinic.services,
            createdAt: new Date(ret.clinic.createdAt + "Z"),
            adminUser: ret.clinic.adminUser === null || ret.clinic.adminUser === undefined ? null : {
                name: ret.clinic.adminUser.name,
                documentNumber: ret.clinic.adminUser.documentNumber,
            },
        },
        type: ret.type,
        createdAt: new Date(ret.createdAt + "Z"),
        name: ret.name,
        email: ret.email,
        documentNumber: ret.documentNumber,
    };
}

export async function editMarketingUser(id: string, editMarketingUser: EditAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
        editMarketingUser: {
            name: editMarketingUser.name,
            email: editMarketingUser.email,
            documentNumber: editMarketingUser.documentNumber,
        },
    };
    const ret = await makeRequest({name: "editMarketingUser", args, progress});
    return {
        id: ret.id,
        clinic: ret.clinic === null || ret.clinic === undefined ? null : {
            id: ret.clinic.id,
            image: {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            phone: ret.clinic.phone,
            showInSite: !!ret.clinic.showInSite,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
            },
            code: ret.clinic.code || 0,
            codeFormat: ret.clinic.codeFormat,
            services: ret.clinic.services === null || ret.clinic.services === undefined ? null : ret.clinic.services,
            createdAt: new Date(ret.clinic.createdAt + "Z"),
            adminUser: ret.clinic.adminUser === null || ret.clinic.adminUser === undefined ? null : {
                name: ret.clinic.adminUser.name,
                documentNumber: ret.clinic.adminUser.documentNumber,
            },
        },
        type: ret.type,
        createdAt: new Date(ret.createdAt + "Z"),
        name: ret.name,
        email: ret.email,
        documentNumber: ret.documentNumber,
    };
}

export async function getAllMarketingAdminUsers(pageOffset: number, progress?: (progress: number) => void): Promise<AdminUser[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllMarketingAdminUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        clinic: e.clinic === null || e.clinic === undefined ? null : {
            id: e.clinic.id,
            image: {
                thumb: {
                    width: e.clinic.image.thumb.width || 0,
                    height: e.clinic.image.thumb.height || 0,
                    url: e.clinic.image.thumb.url,
                },
                width: e.clinic.image.width || 0,
                height: e.clinic.image.height || 0,
                url: e.clinic.image.url,
            },
            phone: e.clinic.phone,
            showInSite: !!e.clinic.showInSite,
            address: {
                zipcode: e.clinic.address.zipcode,
                street: e.clinic.address.street,
                streetNumber: e.clinic.address.streetNumber,
                complementary: e.clinic.address.complementary,
                neighborhood: e.clinic.address.neighborhood,
                city: e.clinic.address.city,
                state: e.clinic.address.state,
            },
            code: e.clinic.code || 0,
            codeFormat: e.clinic.codeFormat,
            services: e.clinic.services === null || e.clinic.services === undefined ? null : e.clinic.services,
            createdAt: new Date(e.clinic.createdAt + "Z"),
            adminUser: e.clinic.adminUser === null || e.clinic.adminUser === undefined ? null : {
                name: e.clinic.adminUser.name,
                documentNumber: e.clinic.adminUser.documentNumber,
            },
        },
        type: e.type,
        createdAt: new Date(e.createdAt + "Z"),
        name: e.name,
        email: e.email,
        documentNumber: e.documentNumber,
    }));
}

export async function getAllMarketingMasterAdminUsers(pageOffset: number, progress?: (progress: number) => void): Promise<AdminUser[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllMarketingMasterAdminUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        clinic: e.clinic === null || e.clinic === undefined ? null : {
            id: e.clinic.id,
            image: {
                thumb: {
                    width: e.clinic.image.thumb.width || 0,
                    height: e.clinic.image.thumb.height || 0,
                    url: e.clinic.image.thumb.url,
                },
                width: e.clinic.image.width || 0,
                height: e.clinic.image.height || 0,
                url: e.clinic.image.url,
            },
            phone: e.clinic.phone,
            showInSite: !!e.clinic.showInSite,
            address: {
                zipcode: e.clinic.address.zipcode,
                street: e.clinic.address.street,
                streetNumber: e.clinic.address.streetNumber,
                complementary: e.clinic.address.complementary,
                neighborhood: e.clinic.address.neighborhood,
                city: e.clinic.address.city,
                state: e.clinic.address.state,
            },
            code: e.clinic.code || 0,
            codeFormat: e.clinic.codeFormat,
            services: e.clinic.services === null || e.clinic.services === undefined ? null : e.clinic.services,
            createdAt: new Date(e.clinic.createdAt + "Z"),
            adminUser: e.clinic.adminUser === null || e.clinic.adminUser === undefined ? null : {
                name: e.clinic.adminUser.name,
                documentNumber: e.clinic.adminUser.documentNumber,
            },
        },
        type: e.type,
        createdAt: new Date(e.createdAt + "Z"),
        name: e.name,
        email: e.email,
        documentNumber: e.documentNumber,
    }));
}

export async function getAllAdministeredMedicationsByPet(pageOffset: number, petId: string, progress?: (progress: number) => void): Promise<AdministeredMedication[]> {
    const args = {
        pageOffset: pageOffset || 0,
        petId: petId,
    };
    const ret = await makeRequest({name: "getAllAdministeredMedicationsByPet", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        code: e.code === null || e.code === undefined ? null : e.code || 0,
        startConsumptionDate: new Date(e.startConsumptionDate + "Z"),
        endConsumptionDate: new Date(e.endConsumptionDate + "Z"),
        consumptionHours: e.consumptionHours.map((e: any) => new Date(e + "Z")),
        dosage: e.dosage,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        document: e.document === null || e.document === undefined ? null : e.document,
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getAllAdministeredMedicationsByConsultation(pageOffset: number, consultationId: string, progress?: (progress: number) => void): Promise<AdministeredMedication[]> {
    const args = {
        pageOffset: pageOffset || 0,
        consultationId: consultationId,
    };
    const ret = await makeRequest({name: "getAllAdministeredMedicationsByConsultation", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        code: e.code === null || e.code === undefined ? null : e.code || 0,
        startConsumptionDate: new Date(e.startConsumptionDate + "Z"),
        endConsumptionDate: new Date(e.endConsumptionDate + "Z"),
        consumptionHours: e.consumptionHours.map((e: any) => new Date(e + "Z")),
        dosage: e.dosage,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        document: e.document === null || e.document === undefined ? null : e.document,
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getAllAdministeredMedicationsByHospitalization(pageOffSet: number, hospitalizationId: string, progress?: (progress: number) => void): Promise<AdministeredMedication[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
        hospitalizationId: hospitalizationId,
    };
    const ret = await makeRequest({name: "getAllAdministeredMedicationsByHospitalization", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        code: e.code === null || e.code === undefined ? null : e.code || 0,
        startConsumptionDate: new Date(e.startConsumptionDate + "Z"),
        endConsumptionDate: new Date(e.endConsumptionDate + "Z"),
        consumptionHours: e.consumptionHours.map((e: any) => new Date(e + "Z")),
        dosage: e.dosage,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        document: e.document === null || e.document === undefined ? null : e.document,
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getAdministeredMedicationById(id: string, progress?: (progress: number) => void): Promise<AdministeredMedication> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getAdministeredMedicationById", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        code: ret.code === null || ret.code === undefined ? null : ret.code || 0,
        startConsumptionDate: new Date(ret.startConsumptionDate + "Z"),
        endConsumptionDate: new Date(ret.endConsumptionDate + "Z"),
        consumptionHours: ret.consumptionHours.map((e: any) => new Date(e + "Z")),
        dosage: ret.dosage,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        document: ret.document === null || ret.document === undefined ? null : ret.document,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function deleteAdministeredMedicationById(id: string, progress?: (progress: number) => void): Promise<AdministeredMedication> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteAdministeredMedicationById", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        code: ret.code === null || ret.code === undefined ? null : ret.code || 0,
        startConsumptionDate: new Date(ret.startConsumptionDate + "Z"),
        endConsumptionDate: new Date(ret.endConsumptionDate + "Z"),
        consumptionHours: ret.consumptionHours.map((e: any) => new Date(e + "Z")),
        dosage: ret.dosage,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        document: ret.document === null || ret.document === undefined ? null : ret.document,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function createAdministeredMedicationByConsultation(newAdministeredMedication: NewAdministeredMedication, consultationId: string, progress?: (progress: number) => void): Promise<AdministeredMedication> {
    const args = {
        newAdministeredMedication: {
            name: newAdministeredMedication.name,
            code: newAdministeredMedication.code === null || newAdministeredMedication.code === undefined ? null : newAdministeredMedication.code || 0,
            startConsumptionDate: (typeof newAdministeredMedication.startConsumptionDate === "string" && (newAdministeredMedication.startConsumptionDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newAdministeredMedication.startConsumptionDate as any).replace("Z", "") : newAdministeredMedication.startConsumptionDate.toISOString().replace("Z", "")),
            endConsumptionDate: (typeof newAdministeredMedication.endConsumptionDate === "string" && (newAdministeredMedication.endConsumptionDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newAdministeredMedication.endConsumptionDate as any).replace("Z", "") : newAdministeredMedication.endConsumptionDate.toISOString().replace("Z", "")),
            consumptionHours: newAdministeredMedication.consumptionHours.map(e => (typeof e === "string" && (e as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (e as any).replace("Z", "") : e.toISOString().replace("Z", ""))),
            dosage: newAdministeredMedication.dosage,
            image: newAdministeredMedication.image === null || newAdministeredMedication.image === undefined ? null : {
                thumb: {
                    width: newAdministeredMedication.image.thumb.width || 0,
                    height: newAdministeredMedication.image.thumb.height || 0,
                    url: newAdministeredMedication.image.thumb.url,
                },
                width: newAdministeredMedication.image.width || 0,
                height: newAdministeredMedication.image.height || 0,
                url: newAdministeredMedication.image.url,
            },
            document: newAdministeredMedication.document === null || newAdministeredMedication.document === undefined ? null : newAdministeredMedication.document,
        },
        consultationId: consultationId,
    };
    const ret = await makeRequest({name: "createAdministeredMedicationByConsultation", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        code: ret.code === null || ret.code === undefined ? null : ret.code || 0,
        startConsumptionDate: new Date(ret.startConsumptionDate + "Z"),
        endConsumptionDate: new Date(ret.endConsumptionDate + "Z"),
        consumptionHours: ret.consumptionHours.map((e: any) => new Date(e + "Z")),
        dosage: ret.dosage,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        document: ret.document === null || ret.document === undefined ? null : ret.document,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function createAdministeredMedicationByHospitalization(newAdministeredMedication: NewAdministeredMedication, hospitalizationId: string, progress?: (progress: number) => void): Promise<AdministeredMedication> {
    const args = {
        newAdministeredMedication: {
            name: newAdministeredMedication.name,
            code: newAdministeredMedication.code === null || newAdministeredMedication.code === undefined ? null : newAdministeredMedication.code || 0,
            startConsumptionDate: (typeof newAdministeredMedication.startConsumptionDate === "string" && (newAdministeredMedication.startConsumptionDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newAdministeredMedication.startConsumptionDate as any).replace("Z", "") : newAdministeredMedication.startConsumptionDate.toISOString().replace("Z", "")),
            endConsumptionDate: (typeof newAdministeredMedication.endConsumptionDate === "string" && (newAdministeredMedication.endConsumptionDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newAdministeredMedication.endConsumptionDate as any).replace("Z", "") : newAdministeredMedication.endConsumptionDate.toISOString().replace("Z", "")),
            consumptionHours: newAdministeredMedication.consumptionHours.map(e => (typeof e === "string" && (e as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (e as any).replace("Z", "") : e.toISOString().replace("Z", ""))),
            dosage: newAdministeredMedication.dosage,
            image: newAdministeredMedication.image === null || newAdministeredMedication.image === undefined ? null : {
                thumb: {
                    width: newAdministeredMedication.image.thumb.width || 0,
                    height: newAdministeredMedication.image.thumb.height || 0,
                    url: newAdministeredMedication.image.thumb.url,
                },
                width: newAdministeredMedication.image.width || 0,
                height: newAdministeredMedication.image.height || 0,
                url: newAdministeredMedication.image.url,
            },
            document: newAdministeredMedication.document === null || newAdministeredMedication.document === undefined ? null : newAdministeredMedication.document,
        },
        hospitalizationId: hospitalizationId,
    };
    const ret = await makeRequest({name: "createAdministeredMedicationByHospitalization", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        code: ret.code === null || ret.code === undefined ? null : ret.code || 0,
        startConsumptionDate: new Date(ret.startConsumptionDate + "Z"),
        endConsumptionDate: new Date(ret.endConsumptionDate + "Z"),
        consumptionHours: ret.consumptionHours.map((e: any) => new Date(e + "Z")),
        dosage: ret.dosage,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        document: ret.document === null || ret.document === undefined ? null : ret.document,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function editAdministeredMedication(id: string, editAdministeredMedication: NewAdministeredMedication, progress?: (progress: number) => void): Promise<AdministeredMedication> {
    const args = {
        id: id,
        editAdministeredMedication: {
            name: editAdministeredMedication.name,
            code: editAdministeredMedication.code === null || editAdministeredMedication.code === undefined ? null : editAdministeredMedication.code || 0,
            startConsumptionDate: (typeof editAdministeredMedication.startConsumptionDate === "string" && (editAdministeredMedication.startConsumptionDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editAdministeredMedication.startConsumptionDate as any).replace("Z", "") : editAdministeredMedication.startConsumptionDate.toISOString().replace("Z", "")),
            endConsumptionDate: (typeof editAdministeredMedication.endConsumptionDate === "string" && (editAdministeredMedication.endConsumptionDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editAdministeredMedication.endConsumptionDate as any).replace("Z", "") : editAdministeredMedication.endConsumptionDate.toISOString().replace("Z", "")),
            consumptionHours: editAdministeredMedication.consumptionHours.map(e => (typeof e === "string" && (e as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (e as any).replace("Z", "") : e.toISOString().replace("Z", ""))),
            dosage: editAdministeredMedication.dosage,
            image: editAdministeredMedication.image === null || editAdministeredMedication.image === undefined ? null : {
                thumb: {
                    width: editAdministeredMedication.image.thumb.width || 0,
                    height: editAdministeredMedication.image.thumb.height || 0,
                    url: editAdministeredMedication.image.thumb.url,
                },
                width: editAdministeredMedication.image.width || 0,
                height: editAdministeredMedication.image.height || 0,
                url: editAdministeredMedication.image.url,
            },
            document: editAdministeredMedication.document === null || editAdministeredMedication.document === undefined ? null : editAdministeredMedication.document,
        },
    };
    const ret = await makeRequest({name: "editAdministeredMedication", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        code: ret.code === null || ret.code === undefined ? null : ret.code || 0,
        startConsumptionDate: new Date(ret.startConsumptionDate + "Z"),
        endConsumptionDate: new Date(ret.endConsumptionDate + "Z"),
        consumptionHours: ret.consumptionHours.map((e: any) => new Date(e + "Z")),
        dosage: ret.dosage,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        document: ret.document === null || ret.document === undefined ? null : ret.document,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function getVetById(id: string, progress?: (progress: number) => void): Promise<Vet> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getVetById", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        description: ret.description === null || ret.description === undefined ? null : ret.description,
        documentNumber: ret.documentNumber === null || ret.documentNumber === undefined ? null : ret.documentNumber,
        showInSite: !!ret.showInSite,
        clinic: ret.clinic === null || ret.clinic === undefined ? null : {
            id: ret.clinic.id,
            image: {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            phone: ret.clinic.phone,
            showInSite: !!ret.clinic.showInSite,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
            },
            code: ret.clinic.code || 0,
            codeFormat: ret.clinic.codeFormat,
            services: ret.clinic.services === null || ret.clinic.services === undefined ? null : ret.clinic.services,
            createdAt: new Date(ret.clinic.createdAt + "Z"),
            adminUser: ret.clinic.adminUser === null || ret.clinic.adminUser === undefined ? null : {
                name: ret.clinic.adminUser.name,
                documentNumber: ret.clinic.adminUser.documentNumber,
            },
        },
    };
}

export async function createVetForClinic(newVet: NewVet, clinicId: string, progress?: (progress: number) => void): Promise<Vet> {
    const args = {
        newVet: {
            name: newVet.name,
            documentNumber: newVet.documentNumber === null || newVet.documentNumber === undefined ? null : newVet.documentNumber,
        },
        clinicId: clinicId,
    };
    const ret = await makeRequest({name: "createVetForClinic", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        description: ret.description === null || ret.description === undefined ? null : ret.description,
        documentNumber: ret.documentNumber === null || ret.documentNumber === undefined ? null : ret.documentNumber,
        showInSite: !!ret.showInSite,
        clinic: ret.clinic === null || ret.clinic === undefined ? null : {
            id: ret.clinic.id,
            image: {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            phone: ret.clinic.phone,
            showInSite: !!ret.clinic.showInSite,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
            },
            code: ret.clinic.code || 0,
            codeFormat: ret.clinic.codeFormat,
            services: ret.clinic.services === null || ret.clinic.services === undefined ? null : ret.clinic.services,
            createdAt: new Date(ret.clinic.createdAt + "Z"),
            adminUser: ret.clinic.adminUser === null || ret.clinic.adminUser === undefined ? null : {
                name: ret.clinic.adminUser.name,
                documentNumber: ret.clinic.adminUser.documentNumber,
            },
        },
    };
}

export async function createVetShowInSite(newVet: NewVetForShowInSite, progress?: (progress: number) => void): Promise<Vet> {
    const args = {
        newVet: {
            name: newVet.name,
            image: newVet.image === null || newVet.image === undefined ? null : {
                bytes: newVet.image.bytes === null || newVet.image.bytes === undefined ? null : newVet.image.bytes.toString("base64"),
                image: newVet.image.image === null || newVet.image.image === undefined ? null : {
                    thumb: {
                        width: newVet.image.image.thumb.width || 0,
                        height: newVet.image.image.thumb.height || 0,
                        url: newVet.image.image.thumb.url,
                    },
                    width: newVet.image.image.width || 0,
                    height: newVet.image.image.height || 0,
                    url: newVet.image.image.url,
                },
            },
            description: newVet.description === null || newVet.description === undefined ? null : newVet.description,
        },
    };
    const ret = await makeRequest({name: "createVetShowInSite", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        description: ret.description === null || ret.description === undefined ? null : ret.description,
        documentNumber: ret.documentNumber === null || ret.documentNumber === undefined ? null : ret.documentNumber,
        showInSite: !!ret.showInSite,
        clinic: ret.clinic === null || ret.clinic === undefined ? null : {
            id: ret.clinic.id,
            image: {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            phone: ret.clinic.phone,
            showInSite: !!ret.clinic.showInSite,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
            },
            code: ret.clinic.code || 0,
            codeFormat: ret.clinic.codeFormat,
            services: ret.clinic.services === null || ret.clinic.services === undefined ? null : ret.clinic.services,
            createdAt: new Date(ret.clinic.createdAt + "Z"),
            adminUser: ret.clinic.adminUser === null || ret.clinic.adminUser === undefined ? null : {
                name: ret.clinic.adminUser.name,
                documentNumber: ret.clinic.adminUser.documentNumber,
            },
        },
    };
}

export async function editVetShowInSite(id: string, editVet: EditVetForShowInSite, progress?: (progress: number) => void): Promise<Vet> {
    const args = {
        id: id,
        editVet: {
            name: editVet.name,
            image: editVet.image === null || editVet.image === undefined ? null : {
                bytes: editVet.image.bytes === null || editVet.image.bytes === undefined ? null : editVet.image.bytes.toString("base64"),
                image: editVet.image.image === null || editVet.image.image === undefined ? null : {
                    thumb: {
                        width: editVet.image.image.thumb.width || 0,
                        height: editVet.image.image.thumb.height || 0,
                        url: editVet.image.image.thumb.url,
                    },
                    width: editVet.image.image.width || 0,
                    height: editVet.image.image.height || 0,
                    url: editVet.image.image.url,
                },
            },
            description: editVet.description === null || editVet.description === undefined ? null : editVet.description,
        },
    };
    const ret = await makeRequest({name: "editVetShowInSite", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        description: ret.description === null || ret.description === undefined ? null : ret.description,
        documentNumber: ret.documentNumber === null || ret.documentNumber === undefined ? null : ret.documentNumber,
        showInSite: !!ret.showInSite,
        clinic: ret.clinic === null || ret.clinic === undefined ? null : {
            id: ret.clinic.id,
            image: {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            phone: ret.clinic.phone,
            showInSite: !!ret.clinic.showInSite,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
            },
            code: ret.clinic.code || 0,
            codeFormat: ret.clinic.codeFormat,
            services: ret.clinic.services === null || ret.clinic.services === undefined ? null : ret.clinic.services,
            createdAt: new Date(ret.clinic.createdAt + "Z"),
            adminUser: ret.clinic.adminUser === null || ret.clinic.adminUser === undefined ? null : {
                name: ret.clinic.adminUser.name,
                documentNumber: ret.clinic.adminUser.documentNumber,
            },
        },
    };
}

export async function editVet(id: string, editVet: EditVet, progress?: (progress: number) => void): Promise<Vet> {
    const args = {
        id: id,
        editVet: {
            name: editVet.name,
            documentNumber: editVet.documentNumber === null || editVet.documentNumber === undefined ? null : editVet.documentNumber,
        },
    };
    const ret = await makeRequest({name: "editVet", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        description: ret.description === null || ret.description === undefined ? null : ret.description,
        documentNumber: ret.documentNumber === null || ret.documentNumber === undefined ? null : ret.documentNumber,
        showInSite: !!ret.showInSite,
        clinic: ret.clinic === null || ret.clinic === undefined ? null : {
            id: ret.clinic.id,
            image: {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            phone: ret.clinic.phone,
            showInSite: !!ret.clinic.showInSite,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
            },
            code: ret.clinic.code || 0,
            codeFormat: ret.clinic.codeFormat,
            services: ret.clinic.services === null || ret.clinic.services === undefined ? null : ret.clinic.services,
            createdAt: new Date(ret.clinic.createdAt + "Z"),
            adminUser: ret.clinic.adminUser === null || ret.clinic.adminUser === undefined ? null : {
                name: ret.clinic.adminUser.name,
                documentNumber: ret.clinic.adminUser.documentNumber,
            },
        },
    };
}

export async function deleteVet(id: string, progress?: (progress: number) => void): Promise<Vet> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteVet", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        description: ret.description === null || ret.description === undefined ? null : ret.description,
        documentNumber: ret.documentNumber === null || ret.documentNumber === undefined ? null : ret.documentNumber,
        showInSite: !!ret.showInSite,
        clinic: ret.clinic === null || ret.clinic === undefined ? null : {
            id: ret.clinic.id,
            image: {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            phone: ret.clinic.phone,
            showInSite: !!ret.clinic.showInSite,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
            },
            code: ret.clinic.code || 0,
            codeFormat: ret.clinic.codeFormat,
            services: ret.clinic.services === null || ret.clinic.services === undefined ? null : ret.clinic.services,
            createdAt: new Date(ret.clinic.createdAt + "Z"),
            adminUser: ret.clinic.adminUser === null || ret.clinic.adminUser === undefined ? null : {
                name: ret.clinic.adminUser.name,
                documentNumber: ret.clinic.adminUser.documentNumber,
            },
        },
    };
}

export async function autocompleteVet(name: string, pageOffset: number, progress?: (progress: number) => void): Promise<Vet[]> {
    const args = {
        name: name,
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "autocompleteVet", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        description: e.description === null || e.description === undefined ? null : e.description,
        documentNumber: e.documentNumber === null || e.documentNumber === undefined ? null : e.documentNumber,
        showInSite: !!e.showInSite,
        clinic: e.clinic === null || e.clinic === undefined ? null : {
            id: e.clinic.id,
            image: {
                thumb: {
                    width: e.clinic.image.thumb.width || 0,
                    height: e.clinic.image.thumb.height || 0,
                    url: e.clinic.image.thumb.url,
                },
                width: e.clinic.image.width || 0,
                height: e.clinic.image.height || 0,
                url: e.clinic.image.url,
            },
            phone: e.clinic.phone,
            showInSite: !!e.clinic.showInSite,
            address: {
                zipcode: e.clinic.address.zipcode,
                street: e.clinic.address.street,
                streetNumber: e.clinic.address.streetNumber,
                complementary: e.clinic.address.complementary,
                neighborhood: e.clinic.address.neighborhood,
                city: e.clinic.address.city,
                state: e.clinic.address.state,
            },
            code: e.clinic.code || 0,
            codeFormat: e.clinic.codeFormat,
            services: e.clinic.services === null || e.clinic.services === undefined ? null : e.clinic.services,
            createdAt: new Date(e.clinic.createdAt + "Z"),
            adminUser: e.clinic.adminUser === null || e.clinic.adminUser === undefined ? null : {
                name: e.clinic.adminUser.name,
                documentNumber: e.clinic.adminUser.documentNumber,
            },
        },
    }));
}

export async function getAllVetsToNotShowInSite(pageOffset: number, progress?: (progress: number) => void): Promise<Vet[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllVetsToNotShowInSite", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        description: e.description === null || e.description === undefined ? null : e.description,
        documentNumber: e.documentNumber === null || e.documentNumber === undefined ? null : e.documentNumber,
        showInSite: !!e.showInSite,
        clinic: e.clinic === null || e.clinic === undefined ? null : {
            id: e.clinic.id,
            image: {
                thumb: {
                    width: e.clinic.image.thumb.width || 0,
                    height: e.clinic.image.thumb.height || 0,
                    url: e.clinic.image.thumb.url,
                },
                width: e.clinic.image.width || 0,
                height: e.clinic.image.height || 0,
                url: e.clinic.image.url,
            },
            phone: e.clinic.phone,
            showInSite: !!e.clinic.showInSite,
            address: {
                zipcode: e.clinic.address.zipcode,
                street: e.clinic.address.street,
                streetNumber: e.clinic.address.streetNumber,
                complementary: e.clinic.address.complementary,
                neighborhood: e.clinic.address.neighborhood,
                city: e.clinic.address.city,
                state: e.clinic.address.state,
            },
            code: e.clinic.code || 0,
            codeFormat: e.clinic.codeFormat,
            services: e.clinic.services === null || e.clinic.services === undefined ? null : e.clinic.services,
            createdAt: new Date(e.clinic.createdAt + "Z"),
            adminUser: e.clinic.adminUser === null || e.clinic.adminUser === undefined ? null : {
                name: e.clinic.adminUser.name,
                documentNumber: e.clinic.adminUser.documentNumber,
            },
        },
    }));
}

export async function getAllVetsToShowInSite(pageOffset: number, progress?: (progress: number) => void): Promise<Vet[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllVetsToShowInSite", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        description: e.description === null || e.description === undefined ? null : e.description,
        documentNumber: e.documentNumber === null || e.documentNumber === undefined ? null : e.documentNumber,
        showInSite: !!e.showInSite,
        clinic: e.clinic === null || e.clinic === undefined ? null : {
            id: e.clinic.id,
            image: {
                thumb: {
                    width: e.clinic.image.thumb.width || 0,
                    height: e.clinic.image.thumb.height || 0,
                    url: e.clinic.image.thumb.url,
                },
                width: e.clinic.image.width || 0,
                height: e.clinic.image.height || 0,
                url: e.clinic.image.url,
            },
            phone: e.clinic.phone,
            showInSite: !!e.clinic.showInSite,
            address: {
                zipcode: e.clinic.address.zipcode,
                street: e.clinic.address.street,
                streetNumber: e.clinic.address.streetNumber,
                complementary: e.clinic.address.complementary,
                neighborhood: e.clinic.address.neighborhood,
                city: e.clinic.address.city,
                state: e.clinic.address.state,
            },
            code: e.clinic.code || 0,
            codeFormat: e.clinic.codeFormat,
            services: e.clinic.services === null || e.clinic.services === undefined ? null : e.clinic.services,
            createdAt: new Date(e.clinic.createdAt + "Z"),
            adminUser: e.clinic.adminUser === null || e.clinic.adminUser === undefined ? null : {
                name: e.clinic.adminUser.name,
                documentNumber: e.clinic.adminUser.documentNumber,
            },
        },
    }));
}

export async function getAllVetsByClinicToNotShowInSite(clinicId: string, pageOffset: number, progress?: (progress: number) => void): Promise<Vet[]> {
    const args = {
        clinicId: clinicId,
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllVetsByClinicToNotShowInSite", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        description: e.description === null || e.description === undefined ? null : e.description,
        documentNumber: e.documentNumber === null || e.documentNumber === undefined ? null : e.documentNumber,
        showInSite: !!e.showInSite,
        clinic: e.clinic === null || e.clinic === undefined ? null : {
            id: e.clinic.id,
            image: {
                thumb: {
                    width: e.clinic.image.thumb.width || 0,
                    height: e.clinic.image.thumb.height || 0,
                    url: e.clinic.image.thumb.url,
                },
                width: e.clinic.image.width || 0,
                height: e.clinic.image.height || 0,
                url: e.clinic.image.url,
            },
            phone: e.clinic.phone,
            showInSite: !!e.clinic.showInSite,
            address: {
                zipcode: e.clinic.address.zipcode,
                street: e.clinic.address.street,
                streetNumber: e.clinic.address.streetNumber,
                complementary: e.clinic.address.complementary,
                neighborhood: e.clinic.address.neighborhood,
                city: e.clinic.address.city,
                state: e.clinic.address.state,
            },
            code: e.clinic.code || 0,
            codeFormat: e.clinic.codeFormat,
            services: e.clinic.services === null || e.clinic.services === undefined ? null : e.clinic.services,
            createdAt: new Date(e.clinic.createdAt + "Z"),
            adminUser: e.clinic.adminUser === null || e.clinic.adminUser === undefined ? null : {
                name: e.clinic.adminUser.name,
                documentNumber: e.clinic.adminUser.documentNumber,
            },
        },
    }));
}

export async function getAllVetsInCurrentClinic(pageOffset: number, progress?: (progress: number) => void): Promise<Vet[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllVetsInCurrentClinic", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        description: e.description === null || e.description === undefined ? null : e.description,
        documentNumber: e.documentNumber === null || e.documentNumber === undefined ? null : e.documentNumber,
        showInSite: !!e.showInSite,
        clinic: e.clinic === null || e.clinic === undefined ? null : {
            id: e.clinic.id,
            image: {
                thumb: {
                    width: e.clinic.image.thumb.width || 0,
                    height: e.clinic.image.thumb.height || 0,
                    url: e.clinic.image.thumb.url,
                },
                width: e.clinic.image.width || 0,
                height: e.clinic.image.height || 0,
                url: e.clinic.image.url,
            },
            phone: e.clinic.phone,
            showInSite: !!e.clinic.showInSite,
            address: {
                zipcode: e.clinic.address.zipcode,
                street: e.clinic.address.street,
                streetNumber: e.clinic.address.streetNumber,
                complementary: e.clinic.address.complementary,
                neighborhood: e.clinic.address.neighborhood,
                city: e.clinic.address.city,
                state: e.clinic.address.state,
            },
            code: e.clinic.code || 0,
            codeFormat: e.clinic.codeFormat,
            services: e.clinic.services === null || e.clinic.services === undefined ? null : e.clinic.services,
            createdAt: new Date(e.clinic.createdAt + "Z"),
            adminUser: e.clinic.adminUser === null || e.clinic.adminUser === undefined ? null : {
                name: e.clinic.adminUser.name,
                documentNumber: e.clinic.adminUser.documentNumber,
            },
        },
    }));
}

export async function getOlderRating(progress?: (progress: number) => void): Promise<Rating | null> {
    const ret = await makeRequest({name: "getOlderRating", args: {}, progress});
    return ret === null || ret === undefined ? null : {
        id: ret.id,
        status: ret.status,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            documentNumber: ret.user.documentNumber === null || ret.user.documentNumber === undefined ? null : ret.user.documentNumber,
            phone: ret.user.phone,
        },
        consultation: ret.consultation === null || ret.consultation === undefined ? null : {
            id: ret.consultation.id,
            date: new Date(ret.consultation.date + "Z"),
            hour: ret.consultation.hour,
            description: ret.consultation.description,
            finishedAt: ret.consultation.finishedAt === null || ret.consultation.finishedAt === undefined ? null : new Date(ret.consultation.finishedAt + "Z"),
        },
        vet: {
            id: ret.vet.id,
            name: ret.vet.name,
            description: ret.vet.description === null || ret.vet.description === undefined ? null : ret.vet.description,
        },
        vetRate: ret.vetRate === null || ret.vetRate === undefined ? null : ret.vetRate || 0,
        consultationRate: ret.consultationRate === null || ret.consultationRate === undefined ? null : ret.consultationRate || 0,
        vetRatedAt: ret.vetRatedAt === null || ret.vetRatedAt === undefined ? null : new Date(ret.vetRatedAt + "Z"),
        consultationRatedAt: ret.consultationRatedAt === null || ret.consultationRatedAt === undefined ? null : new Date(ret.consultationRatedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function getRatingByConsultationId(consultationId: string, progress?: (progress: number) => void): Promise<Rating> {
    const args = {
        consultationId: consultationId,
    };
    const ret = await makeRequest({name: "getRatingByConsultationId", args, progress});
    return {
        id: ret.id,
        status: ret.status,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            documentNumber: ret.user.documentNumber === null || ret.user.documentNumber === undefined ? null : ret.user.documentNumber,
            phone: ret.user.phone,
        },
        consultation: ret.consultation === null || ret.consultation === undefined ? null : {
            id: ret.consultation.id,
            date: new Date(ret.consultation.date + "Z"),
            hour: ret.consultation.hour,
            description: ret.consultation.description,
            finishedAt: ret.consultation.finishedAt === null || ret.consultation.finishedAt === undefined ? null : new Date(ret.consultation.finishedAt + "Z"),
        },
        vet: {
            id: ret.vet.id,
            name: ret.vet.name,
            description: ret.vet.description === null || ret.vet.description === undefined ? null : ret.vet.description,
        },
        vetRate: ret.vetRate === null || ret.vetRate === undefined ? null : ret.vetRate || 0,
        consultationRate: ret.consultationRate === null || ret.consultationRate === undefined ? null : ret.consultationRate || 0,
        vetRatedAt: ret.vetRatedAt === null || ret.vetRatedAt === undefined ? null : new Date(ret.vetRatedAt + "Z"),
        consultationRatedAt: ret.consultationRatedAt === null || ret.consultationRatedAt === undefined ? null : new Date(ret.consultationRatedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function evaluateRating(id: string, vetRate: number, consultationRate: number, progress?: (progress: number) => void): Promise<Rating> {
    const args = {
        id: id,
        vetRate: vetRate || 0,
        consultationRate: consultationRate || 0,
    };
    const ret = await makeRequest({name: "evaluateRating", args, progress});
    return {
        id: ret.id,
        status: ret.status,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            documentNumber: ret.user.documentNumber === null || ret.user.documentNumber === undefined ? null : ret.user.documentNumber,
            phone: ret.user.phone,
        },
        consultation: ret.consultation === null || ret.consultation === undefined ? null : {
            id: ret.consultation.id,
            date: new Date(ret.consultation.date + "Z"),
            hour: ret.consultation.hour,
            description: ret.consultation.description,
            finishedAt: ret.consultation.finishedAt === null || ret.consultation.finishedAt === undefined ? null : new Date(ret.consultation.finishedAt + "Z"),
        },
        vet: {
            id: ret.vet.id,
            name: ret.vet.name,
            description: ret.vet.description === null || ret.vet.description === undefined ? null : ret.vet.description,
        },
        vetRate: ret.vetRate === null || ret.vetRate === undefined ? null : ret.vetRate || 0,
        consultationRate: ret.consultationRate === null || ret.consultationRate === undefined ? null : ret.consultationRate || 0,
        vetRatedAt: ret.vetRatedAt === null || ret.vetRatedAt === undefined ? null : new Date(ret.vetRatedAt + "Z"),
        consultationRatedAt: ret.consultationRatedAt === null || ret.consultationRatedAt === undefined ? null : new Date(ret.consultationRatedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function getAllConsultation(pageOffset: number, filterByPeriod: FilterByPeriod | null, progress?: (progress: number) => void): Promise<Consultation[]> {
    const args = {
        pageOffset: pageOffset || 0,
        filterByPeriod: filterByPeriod === null || filterByPeriod === undefined ? null : {
            startDate: filterByPeriod.startDate === null || filterByPeriod.startDate === undefined ? null : (typeof filterByPeriod.startDate === "string" && (filterByPeriod.startDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterByPeriod.startDate as any).replace("Z", "") : filterByPeriod.startDate.toISOString().replace("Z", "")),
            endDate: filterByPeriod.endDate === null || filterByPeriod.endDate === undefined ? null : (typeof filterByPeriod.endDate === "string" && (filterByPeriod.endDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterByPeriod.endDate as any).replace("Z", "") : filterByPeriod.endDate.toISOString().replace("Z", "")),
        },
    };
    const ret = await makeRequest({name: "getAllConsultation", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        user: {
            id: e.user.id,
            email: e.user.email,
            name: e.user.name,
            password: e.user.password,
            documentNumber: e.user.documentNumber === null || e.user.documentNumber === undefined ? null : e.user.documentNumber,
            phone: e.user.phone,
            image: e.user.image === null || e.user.image === undefined ? null : {
                thumb: {
                    width: e.user.image.thumb.width || 0,
                    height: e.user.image.thumb.height || 0,
                    url: e.user.image.thumb.url,
                },
                width: e.user.image.width || 0,
                height: e.user.image.height || 0,
                url: e.user.image.url,
            },
            petParents: e.user.petParents,
        },
        pet: {
            id: e.pet.id,
            name: e.pet.name,
            image: {
                thumb: {
                    width: e.pet.image.thumb.width || 0,
                    height: e.pet.image.thumb.height || 0,
                    url: e.pet.image.thumb.url,
                },
                width: e.pet.image.width || 0,
                height: e.pet.image.height || 0,
                url: e.pet.image.url,
            },
            age: e.pet.age,
            species: e.pet.species,
            animalSex: e.pet.animalSex,
            isCastrated: !!e.pet.isCastrated,
            code: e.pet.code || 0,
            breed: e.pet.breed,
            birthdate: new Date(e.pet.birthdate + "Z"),
            codeFormat: e.pet.codeFormat,
        },
        vet: {
            id: e.vet.id,
            name: e.vet.name,
            image: e.vet.image === null || e.vet.image === undefined ? null : {
                thumb: {
                    width: e.vet.image.thumb.width || 0,
                    height: e.vet.image.thumb.height || 0,
                    url: e.vet.image.thumb.url,
                },
                width: e.vet.image.width || 0,
                height: e.vet.image.height || 0,
                url: e.vet.image.url,
            },
            description: e.vet.description === null || e.vet.description === undefined ? null : e.vet.description,
            documentNumber: e.vet.documentNumber === null || e.vet.documentNumber === undefined ? null : e.vet.documentNumber,
            showInSite: !!e.vet.showInSite,
            clinic: e.vet.clinic === null || e.vet.clinic === undefined ? null : {
                id: e.vet.clinic.id,
                image: {
                    thumb: {
                        width: e.vet.clinic.image.thumb.width || 0,
                        height: e.vet.clinic.image.thumb.height || 0,
                        url: e.vet.clinic.image.thumb.url,
                    },
                    width: e.vet.clinic.image.width || 0,
                    height: e.vet.clinic.image.height || 0,
                    url: e.vet.clinic.image.url,
                },
                phone: e.vet.clinic.phone,
                showInSite: !!e.vet.clinic.showInSite,
                address: {
                    zipcode: e.vet.clinic.address.zipcode,
                    street: e.vet.clinic.address.street,
                    streetNumber: e.vet.clinic.address.streetNumber,
                    complementary: e.vet.clinic.address.complementary,
                    neighborhood: e.vet.clinic.address.neighborhood,
                    city: e.vet.clinic.address.city,
                    state: e.vet.clinic.address.state,
                },
                code: e.vet.clinic.code || 0,
                codeFormat: e.vet.clinic.codeFormat,
                services: e.vet.clinic.services === null || e.vet.clinic.services === undefined ? null : e.vet.clinic.services,
                createdAt: new Date(e.vet.clinic.createdAt + "Z"),
                adminUser: e.vet.clinic.adminUser === null || e.vet.clinic.adminUser === undefined ? null : {
                    name: e.vet.clinic.adminUser.name,
                    documentNumber: e.vet.clinic.adminUser.documentNumber,
                },
            },
        },
        date: new Date(e.date + "Z"),
        hour: e.hour,
        description: e.description,
        clinic: {
            id: e.clinic.id,
            image: {
                thumb: {
                    width: e.clinic.image.thumb.width || 0,
                    height: e.clinic.image.thumb.height || 0,
                    url: e.clinic.image.thumb.url,
                },
                width: e.clinic.image.width || 0,
                height: e.clinic.image.height || 0,
                url: e.clinic.image.url,
            },
            phone: e.clinic.phone,
            showInSite: !!e.clinic.showInSite,
            address: {
                zipcode: e.clinic.address.zipcode,
                street: e.clinic.address.street,
                streetNumber: e.clinic.address.streetNumber,
                complementary: e.clinic.address.complementary,
                neighborhood: e.clinic.address.neighborhood,
                city: e.clinic.address.city,
                state: e.clinic.address.state,
            },
            code: e.clinic.code || 0,
            codeFormat: e.clinic.codeFormat,
            services: e.clinic.services === null || e.clinic.services === undefined ? null : e.clinic.services,
            createdAt: new Date(e.clinic.createdAt + "Z"),
            adminUser: e.clinic.adminUser === null || e.clinic.adminUser === undefined ? null : {
                name: e.clinic.adminUser.name,
                documentNumber: e.clinic.adminUser.documentNumber,
            },
        },
        anamnese: {
            heartRate: e.anamnese.heartRate === null || e.anamnese.heartRate === undefined ? null : e.anamnese.heartRate,
            respiratoryFrequency: e.anamnese.respiratoryFrequency === null || e.anamnese.respiratoryFrequency === undefined ? null : e.anamnese.respiratoryFrequency,
            tpc: e.anamnese.tpc === null || e.anamnese.tpc === undefined ? null : e.anamnese.tpc,
            mucous: e.anamnese.mucous === null || e.anamnese.mucous === undefined ? null : e.anamnese.mucous,
            pulse: e.anamnese.pulse === null || e.anamnese.pulse === undefined ? null : e.anamnese.pulse,
            rectalTemperature: e.anamnese.rectalTemperature === null || e.anamnese.rectalTemperature === undefined ? null : e.anamnese.rectalTemperature,
            cardiopulmonaryAuscultation: e.anamnese.cardiopulmonaryAuscultation === null || e.anamnese.cardiopulmonaryAuscultation === undefined ? null : e.anamnese.cardiopulmonaryAuscultation,
            abdominalPalpation: e.anamnese.abdominalPalpation === null || e.anamnese.abdominalPalpation === undefined ? null : e.anamnese.abdominalPalpation,
            bodyScore: e.anamnese.bodyScore === null || e.anamnese.bodyScore === undefined ? null : e.anamnese.bodyScore,
            otherSystems: e.anamnese.otherSystems === null || e.anamnese.otherSystems === undefined ? null : e.anamnese.otherSystems,
            complementaryExams: e.anamnese.complementaryExams === null || e.anamnese.complementaryExams === undefined ? null : e.anamnese.complementaryExams,
            suspicions: e.anamnese.suspicions === null || e.anamnese.suspicions === undefined ? null : e.anamnese.suspicions,
            diagnosis: e.anamnese.diagnosis === null || e.anamnese.diagnosis === undefined ? null : e.anamnese.diagnosis,
            treatment: e.anamnese.treatment === null || e.anamnese.treatment === undefined ? null : e.anamnese.treatment,
            description: e.anamnese.description === null || e.anamnese.description === undefined ? null : e.anamnese.description,
        },
        fluidtherapys: e.fluidtherapys.map((e: any) => ({
            solution: e.solution === null || e.solution === undefined ? null : e.solution,
            fluidtherapyRate: e.fluidtherapyRate === null || e.fluidtherapyRate === undefined ? null : e.fluidtherapyRate,
            velocity: e.velocity === null || e.velocity === undefined ? null : e.velocity,
        })),
        finishedAt: e.finishedAt === null || e.finishedAt === undefined ? null : new Date(e.finishedAt + "Z"),
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getConsultationById(id: string, progress?: (progress: number) => void): Promise<Consultation> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getConsultationById", args, progress});
    return {
        id: ret.id,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            password: ret.user.password,
            documentNumber: ret.user.documentNumber === null || ret.user.documentNumber === undefined ? null : ret.user.documentNumber,
            phone: ret.user.phone,
            image: ret.user.image === null || ret.user.image === undefined ? null : {
                thumb: {
                    width: ret.user.image.thumb.width || 0,
                    height: ret.user.image.thumb.height || 0,
                    url: ret.user.image.thumb.url,
                },
                width: ret.user.image.width || 0,
                height: ret.user.image.height || 0,
                url: ret.user.image.url,
            },
            petParents: ret.user.petParents,
        },
        pet: {
            id: ret.pet.id,
            name: ret.pet.name,
            image: {
                thumb: {
                    width: ret.pet.image.thumb.width || 0,
                    height: ret.pet.image.thumb.height || 0,
                    url: ret.pet.image.thumb.url,
                },
                width: ret.pet.image.width || 0,
                height: ret.pet.image.height || 0,
                url: ret.pet.image.url,
            },
            age: ret.pet.age,
            species: ret.pet.species,
            animalSex: ret.pet.animalSex,
            isCastrated: !!ret.pet.isCastrated,
            code: ret.pet.code || 0,
            breed: ret.pet.breed,
            birthdate: new Date(ret.pet.birthdate + "Z"),
            codeFormat: ret.pet.codeFormat,
        },
        vet: {
            id: ret.vet.id,
            name: ret.vet.name,
            image: ret.vet.image === null || ret.vet.image === undefined ? null : {
                thumb: {
                    width: ret.vet.image.thumb.width || 0,
                    height: ret.vet.image.thumb.height || 0,
                    url: ret.vet.image.thumb.url,
                },
                width: ret.vet.image.width || 0,
                height: ret.vet.image.height || 0,
                url: ret.vet.image.url,
            },
            description: ret.vet.description === null || ret.vet.description === undefined ? null : ret.vet.description,
            documentNumber: ret.vet.documentNumber === null || ret.vet.documentNumber === undefined ? null : ret.vet.documentNumber,
            showInSite: !!ret.vet.showInSite,
            clinic: ret.vet.clinic === null || ret.vet.clinic === undefined ? null : {
                id: ret.vet.clinic.id,
                image: {
                    thumb: {
                        width: ret.vet.clinic.image.thumb.width || 0,
                        height: ret.vet.clinic.image.thumb.height || 0,
                        url: ret.vet.clinic.image.thumb.url,
                    },
                    width: ret.vet.clinic.image.width || 0,
                    height: ret.vet.clinic.image.height || 0,
                    url: ret.vet.clinic.image.url,
                },
                phone: ret.vet.clinic.phone,
                showInSite: !!ret.vet.clinic.showInSite,
                address: {
                    zipcode: ret.vet.clinic.address.zipcode,
                    street: ret.vet.clinic.address.street,
                    streetNumber: ret.vet.clinic.address.streetNumber,
                    complementary: ret.vet.clinic.address.complementary,
                    neighborhood: ret.vet.clinic.address.neighborhood,
                    city: ret.vet.clinic.address.city,
                    state: ret.vet.clinic.address.state,
                },
                code: ret.vet.clinic.code || 0,
                codeFormat: ret.vet.clinic.codeFormat,
                services: ret.vet.clinic.services === null || ret.vet.clinic.services === undefined ? null : ret.vet.clinic.services,
                createdAt: new Date(ret.vet.clinic.createdAt + "Z"),
                adminUser: ret.vet.clinic.adminUser === null || ret.vet.clinic.adminUser === undefined ? null : {
                    name: ret.vet.clinic.adminUser.name,
                    documentNumber: ret.vet.clinic.adminUser.documentNumber,
                },
            },
        },
        date: new Date(ret.date + "Z"),
        hour: ret.hour,
        description: ret.description,
        clinic: {
            id: ret.clinic.id,
            image: {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            phone: ret.clinic.phone,
            showInSite: !!ret.clinic.showInSite,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
            },
            code: ret.clinic.code || 0,
            codeFormat: ret.clinic.codeFormat,
            services: ret.clinic.services === null || ret.clinic.services === undefined ? null : ret.clinic.services,
            createdAt: new Date(ret.clinic.createdAt + "Z"),
            adminUser: ret.clinic.adminUser === null || ret.clinic.adminUser === undefined ? null : {
                name: ret.clinic.adminUser.name,
                documentNumber: ret.clinic.adminUser.documentNumber,
            },
        },
        anamnese: {
            heartRate: ret.anamnese.heartRate === null || ret.anamnese.heartRate === undefined ? null : ret.anamnese.heartRate,
            respiratoryFrequency: ret.anamnese.respiratoryFrequency === null || ret.anamnese.respiratoryFrequency === undefined ? null : ret.anamnese.respiratoryFrequency,
            tpc: ret.anamnese.tpc === null || ret.anamnese.tpc === undefined ? null : ret.anamnese.tpc,
            mucous: ret.anamnese.mucous === null || ret.anamnese.mucous === undefined ? null : ret.anamnese.mucous,
            pulse: ret.anamnese.pulse === null || ret.anamnese.pulse === undefined ? null : ret.anamnese.pulse,
            rectalTemperature: ret.anamnese.rectalTemperature === null || ret.anamnese.rectalTemperature === undefined ? null : ret.anamnese.rectalTemperature,
            cardiopulmonaryAuscultation: ret.anamnese.cardiopulmonaryAuscultation === null || ret.anamnese.cardiopulmonaryAuscultation === undefined ? null : ret.anamnese.cardiopulmonaryAuscultation,
            abdominalPalpation: ret.anamnese.abdominalPalpation === null || ret.anamnese.abdominalPalpation === undefined ? null : ret.anamnese.abdominalPalpation,
            bodyScore: ret.anamnese.bodyScore === null || ret.anamnese.bodyScore === undefined ? null : ret.anamnese.bodyScore,
            otherSystems: ret.anamnese.otherSystems === null || ret.anamnese.otherSystems === undefined ? null : ret.anamnese.otherSystems,
            complementaryExams: ret.anamnese.complementaryExams === null || ret.anamnese.complementaryExams === undefined ? null : ret.anamnese.complementaryExams,
            suspicions: ret.anamnese.suspicions === null || ret.anamnese.suspicions === undefined ? null : ret.anamnese.suspicions,
            diagnosis: ret.anamnese.diagnosis === null || ret.anamnese.diagnosis === undefined ? null : ret.anamnese.diagnosis,
            treatment: ret.anamnese.treatment === null || ret.anamnese.treatment === undefined ? null : ret.anamnese.treatment,
            description: ret.anamnese.description === null || ret.anamnese.description === undefined ? null : ret.anamnese.description,
        },
        fluidtherapys: ret.fluidtherapys.map((e: any) => ({
            solution: e.solution === null || e.solution === undefined ? null : e.solution,
            fluidtherapyRate: e.fluidtherapyRate === null || e.fluidtherapyRate === undefined ? null : e.fluidtherapyRate,
            velocity: e.velocity === null || e.velocity === undefined ? null : e.velocity,
        })),
        finishedAt: ret.finishedAt === null || ret.finishedAt === undefined ? null : new Date(ret.finishedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function getAllConsultationByPet(pageOffset: number, petId: string, progress?: (progress: number) => void): Promise<ConsultationWithRating[]> {
    const args = {
        pageOffset: pageOffset || 0,
        petId: petId,
    };
    const ret = await makeRequest({name: "getAllConsultationByPet", args, progress});
    return ret.map((e: any) => ({
        ratings: {
            id: e.ratings.id,
            status: e.ratings.status,
            user: {
                id: e.ratings.user.id,
                email: e.ratings.user.email,
                name: e.ratings.user.name,
                documentNumber: e.ratings.user.documentNumber === null || e.ratings.user.documentNumber === undefined ? null : e.ratings.user.documentNumber,
                phone: e.ratings.user.phone,
            },
            consultation: e.ratings.consultation === null || e.ratings.consultation === undefined ? null : {
                id: e.ratings.consultation.id,
                date: new Date(e.ratings.consultation.date + "Z"),
                hour: e.ratings.consultation.hour,
                description: e.ratings.consultation.description,
                finishedAt: e.ratings.consultation.finishedAt === null || e.ratings.consultation.finishedAt === undefined ? null : new Date(e.ratings.consultation.finishedAt + "Z"),
            },
            vet: {
                id: e.ratings.vet.id,
                name: e.ratings.vet.name,
                description: e.ratings.vet.description === null || e.ratings.vet.description === undefined ? null : e.ratings.vet.description,
            },
            vetRate: e.ratings.vetRate === null || e.ratings.vetRate === undefined ? null : e.ratings.vetRate || 0,
            consultationRate: e.ratings.consultationRate === null || e.ratings.consultationRate === undefined ? null : e.ratings.consultationRate || 0,
            vetRatedAt: e.ratings.vetRatedAt === null || e.ratings.vetRatedAt === undefined ? null : new Date(e.ratings.vetRatedAt + "Z"),
            consultationRatedAt: e.ratings.consultationRatedAt === null || e.ratings.consultationRatedAt === undefined ? null : new Date(e.ratings.consultationRatedAt + "Z"),
            createdAt: new Date(e.ratings.createdAt + "Z"),
        },
        id: e.id,
        user: {
            id: e.user.id,
            email: e.user.email,
            name: e.user.name,
            password: e.user.password,
            documentNumber: e.user.documentNumber === null || e.user.documentNumber === undefined ? null : e.user.documentNumber,
            phone: e.user.phone,
            image: e.user.image === null || e.user.image === undefined ? null : {
                thumb: {
                    width: e.user.image.thumb.width || 0,
                    height: e.user.image.thumb.height || 0,
                    url: e.user.image.thumb.url,
                },
                width: e.user.image.width || 0,
                height: e.user.image.height || 0,
                url: e.user.image.url,
            },
            petParents: e.user.petParents,
        },
        pet: {
            id: e.pet.id,
            name: e.pet.name,
            image: {
                thumb: {
                    width: e.pet.image.thumb.width || 0,
                    height: e.pet.image.thumb.height || 0,
                    url: e.pet.image.thumb.url,
                },
                width: e.pet.image.width || 0,
                height: e.pet.image.height || 0,
                url: e.pet.image.url,
            },
            age: e.pet.age,
            species: e.pet.species,
            animalSex: e.pet.animalSex,
            isCastrated: !!e.pet.isCastrated,
            code: e.pet.code || 0,
            breed: e.pet.breed,
            birthdate: new Date(e.pet.birthdate + "Z"),
            codeFormat: e.pet.codeFormat,
        },
        vet: {
            id: e.vet.id,
            name: e.vet.name,
            image: e.vet.image === null || e.vet.image === undefined ? null : {
                thumb: {
                    width: e.vet.image.thumb.width || 0,
                    height: e.vet.image.thumb.height || 0,
                    url: e.vet.image.thumb.url,
                },
                width: e.vet.image.width || 0,
                height: e.vet.image.height || 0,
                url: e.vet.image.url,
            },
            description: e.vet.description === null || e.vet.description === undefined ? null : e.vet.description,
            documentNumber: e.vet.documentNumber === null || e.vet.documentNumber === undefined ? null : e.vet.documentNumber,
            showInSite: !!e.vet.showInSite,
            clinic: e.vet.clinic === null || e.vet.clinic === undefined ? null : {
                id: e.vet.clinic.id,
                image: {
                    thumb: {
                        width: e.vet.clinic.image.thumb.width || 0,
                        height: e.vet.clinic.image.thumb.height || 0,
                        url: e.vet.clinic.image.thumb.url,
                    },
                    width: e.vet.clinic.image.width || 0,
                    height: e.vet.clinic.image.height || 0,
                    url: e.vet.clinic.image.url,
                },
                phone: e.vet.clinic.phone,
                showInSite: !!e.vet.clinic.showInSite,
                address: {
                    zipcode: e.vet.clinic.address.zipcode,
                    street: e.vet.clinic.address.street,
                    streetNumber: e.vet.clinic.address.streetNumber,
                    complementary: e.vet.clinic.address.complementary,
                    neighborhood: e.vet.clinic.address.neighborhood,
                    city: e.vet.clinic.address.city,
                    state: e.vet.clinic.address.state,
                },
                code: e.vet.clinic.code || 0,
                codeFormat: e.vet.clinic.codeFormat,
                services: e.vet.clinic.services === null || e.vet.clinic.services === undefined ? null : e.vet.clinic.services,
                createdAt: new Date(e.vet.clinic.createdAt + "Z"),
                adminUser: e.vet.clinic.adminUser === null || e.vet.clinic.adminUser === undefined ? null : {
                    name: e.vet.clinic.adminUser.name,
                    documentNumber: e.vet.clinic.adminUser.documentNumber,
                },
            },
        },
        date: new Date(e.date + "Z"),
        hour: e.hour,
        description: e.description,
        clinic: {
            id: e.clinic.id,
            image: {
                thumb: {
                    width: e.clinic.image.thumb.width || 0,
                    height: e.clinic.image.thumb.height || 0,
                    url: e.clinic.image.thumb.url,
                },
                width: e.clinic.image.width || 0,
                height: e.clinic.image.height || 0,
                url: e.clinic.image.url,
            },
            phone: e.clinic.phone,
            showInSite: !!e.clinic.showInSite,
            address: {
                zipcode: e.clinic.address.zipcode,
                street: e.clinic.address.street,
                streetNumber: e.clinic.address.streetNumber,
                complementary: e.clinic.address.complementary,
                neighborhood: e.clinic.address.neighborhood,
                city: e.clinic.address.city,
                state: e.clinic.address.state,
            },
            code: e.clinic.code || 0,
            codeFormat: e.clinic.codeFormat,
            services: e.clinic.services === null || e.clinic.services === undefined ? null : e.clinic.services,
            createdAt: new Date(e.clinic.createdAt + "Z"),
            adminUser: e.clinic.adminUser === null || e.clinic.adminUser === undefined ? null : {
                name: e.clinic.adminUser.name,
                documentNumber: e.clinic.adminUser.documentNumber,
            },
        },
        anamnese: {
            heartRate: e.anamnese.heartRate === null || e.anamnese.heartRate === undefined ? null : e.anamnese.heartRate,
            respiratoryFrequency: e.anamnese.respiratoryFrequency === null || e.anamnese.respiratoryFrequency === undefined ? null : e.anamnese.respiratoryFrequency,
            tpc: e.anamnese.tpc === null || e.anamnese.tpc === undefined ? null : e.anamnese.tpc,
            mucous: e.anamnese.mucous === null || e.anamnese.mucous === undefined ? null : e.anamnese.mucous,
            pulse: e.anamnese.pulse === null || e.anamnese.pulse === undefined ? null : e.anamnese.pulse,
            rectalTemperature: e.anamnese.rectalTemperature === null || e.anamnese.rectalTemperature === undefined ? null : e.anamnese.rectalTemperature,
            cardiopulmonaryAuscultation: e.anamnese.cardiopulmonaryAuscultation === null || e.anamnese.cardiopulmonaryAuscultation === undefined ? null : e.anamnese.cardiopulmonaryAuscultation,
            abdominalPalpation: e.anamnese.abdominalPalpation === null || e.anamnese.abdominalPalpation === undefined ? null : e.anamnese.abdominalPalpation,
            bodyScore: e.anamnese.bodyScore === null || e.anamnese.bodyScore === undefined ? null : e.anamnese.bodyScore,
            otherSystems: e.anamnese.otherSystems === null || e.anamnese.otherSystems === undefined ? null : e.anamnese.otherSystems,
            complementaryExams: e.anamnese.complementaryExams === null || e.anamnese.complementaryExams === undefined ? null : e.anamnese.complementaryExams,
            suspicions: e.anamnese.suspicions === null || e.anamnese.suspicions === undefined ? null : e.anamnese.suspicions,
            diagnosis: e.anamnese.diagnosis === null || e.anamnese.diagnosis === undefined ? null : e.anamnese.diagnosis,
            treatment: e.anamnese.treatment === null || e.anamnese.treatment === undefined ? null : e.anamnese.treatment,
            description: e.anamnese.description === null || e.anamnese.description === undefined ? null : e.anamnese.description,
        },
        fluidtherapys: e.fluidtherapys.map((e: any) => ({
            solution: e.solution === null || e.solution === undefined ? null : e.solution,
            fluidtherapyRate: e.fluidtherapyRate === null || e.fluidtherapyRate === undefined ? null : e.fluidtherapyRate,
            velocity: e.velocity === null || e.velocity === undefined ? null : e.velocity,
        })),
        finishedAt: e.finishedAt === null || e.finishedAt === undefined ? null : new Date(e.finishedAt + "Z"),
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getAllConsultationByPetForAdminClinic(pageOffset: number, petId: string, progress?: (progress: number) => void): Promise<ConsultationForClinic[]> {
    const args = {
        pageOffset: pageOffset || 0,
        petId: petId,
    };
    const ret = await makeRequest({name: "getAllConsultationByPetForAdminClinic", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        user: {
            id: e.user.id,
            email: e.user.email,
            name: e.user.name,
            password: e.user.password,
            documentNumber: e.user.documentNumber === null || e.user.documentNumber === undefined ? null : e.user.documentNumber,
            phone: e.user.phone,
            image: e.user.image === null || e.user.image === undefined ? null : {
                thumb: {
                    width: e.user.image.thumb.width || 0,
                    height: e.user.image.thumb.height || 0,
                    url: e.user.image.thumb.url,
                },
                width: e.user.image.width || 0,
                height: e.user.image.height || 0,
                url: e.user.image.url,
            },
            petParents: e.user.petParents,
        },
        pet: {
            id: e.pet.id,
            name: e.pet.name,
            image: {
                thumb: {
                    width: e.pet.image.thumb.width || 0,
                    height: e.pet.image.thumb.height || 0,
                    url: e.pet.image.thumb.url,
                },
                width: e.pet.image.width || 0,
                height: e.pet.image.height || 0,
                url: e.pet.image.url,
            },
            age: e.pet.age,
            species: e.pet.species,
            animalSex: e.pet.animalSex,
            isCastrated: !!e.pet.isCastrated,
            code: e.pet.code || 0,
            breed: e.pet.breed,
            birthdate: new Date(e.pet.birthdate + "Z"),
            codeFormat: e.pet.codeFormat,
        },
        vet: {
            id: e.vet.id,
            name: e.vet.name,
            image: e.vet.image === null || e.vet.image === undefined ? null : {
                thumb: {
                    width: e.vet.image.thumb.width || 0,
                    height: e.vet.image.thumb.height || 0,
                    url: e.vet.image.thumb.url,
                },
                width: e.vet.image.width || 0,
                height: e.vet.image.height || 0,
                url: e.vet.image.url,
            },
            description: e.vet.description === null || e.vet.description === undefined ? null : e.vet.description,
            documentNumber: e.vet.documentNumber === null || e.vet.documentNumber === undefined ? null : e.vet.documentNumber,
            showInSite: !!e.vet.showInSite,
            clinic: e.vet.clinic === null || e.vet.clinic === undefined ? null : {
                id: e.vet.clinic.id,
                image: {
                    thumb: {
                        width: e.vet.clinic.image.thumb.width || 0,
                        height: e.vet.clinic.image.thumb.height || 0,
                        url: e.vet.clinic.image.thumb.url,
                    },
                    width: e.vet.clinic.image.width || 0,
                    height: e.vet.clinic.image.height || 0,
                    url: e.vet.clinic.image.url,
                },
                phone: e.vet.clinic.phone,
                showInSite: !!e.vet.clinic.showInSite,
                address: {
                    zipcode: e.vet.clinic.address.zipcode,
                    street: e.vet.clinic.address.street,
                    streetNumber: e.vet.clinic.address.streetNumber,
                    complementary: e.vet.clinic.address.complementary,
                    neighborhood: e.vet.clinic.address.neighborhood,
                    city: e.vet.clinic.address.city,
                    state: e.vet.clinic.address.state,
                },
                code: e.vet.clinic.code || 0,
                codeFormat: e.vet.clinic.codeFormat,
                services: e.vet.clinic.services === null || e.vet.clinic.services === undefined ? null : e.vet.clinic.services,
                createdAt: new Date(e.vet.clinic.createdAt + "Z"),
                adminUser: e.vet.clinic.adminUser === null || e.vet.clinic.adminUser === undefined ? null : {
                    name: e.vet.clinic.adminUser.name,
                    documentNumber: e.vet.clinic.adminUser.documentNumber,
                },
            },
        },
        date: new Date(e.date + "Z"),
        hour: e.hour,
        description: e.description,
        clinic: {
            id: e.clinic.id,
            image: {
                thumb: {
                    width: e.clinic.image.thumb.width || 0,
                    height: e.clinic.image.thumb.height || 0,
                    url: e.clinic.image.thumb.url,
                },
                width: e.clinic.image.width || 0,
                height: e.clinic.image.height || 0,
                url: e.clinic.image.url,
            },
            phone: e.clinic.phone,
            showInSite: !!e.clinic.showInSite,
            address: {
                zipcode: e.clinic.address.zipcode,
                street: e.clinic.address.street,
                streetNumber: e.clinic.address.streetNumber,
                complementary: e.clinic.address.complementary,
                neighborhood: e.clinic.address.neighborhood,
                city: e.clinic.address.city,
                state: e.clinic.address.state,
            },
            code: e.clinic.code || 0,
            codeFormat: e.clinic.codeFormat,
            services: e.clinic.services === null || e.clinic.services === undefined ? null : e.clinic.services,
            createdAt: new Date(e.clinic.createdAt + "Z"),
            adminUser: e.clinic.adminUser === null || e.clinic.adminUser === undefined ? null : {
                name: e.clinic.adminUser.name,
                documentNumber: e.clinic.adminUser.documentNumber,
            },
        },
        anamnese: {
            heartRate: e.anamnese.heartRate === null || e.anamnese.heartRate === undefined ? null : e.anamnese.heartRate,
            respiratoryFrequency: e.anamnese.respiratoryFrequency === null || e.anamnese.respiratoryFrequency === undefined ? null : e.anamnese.respiratoryFrequency,
            tpc: e.anamnese.tpc === null || e.anamnese.tpc === undefined ? null : e.anamnese.tpc,
            mucous: e.anamnese.mucous === null || e.anamnese.mucous === undefined ? null : e.anamnese.mucous,
            pulse: e.anamnese.pulse === null || e.anamnese.pulse === undefined ? null : e.anamnese.pulse,
            rectalTemperature: e.anamnese.rectalTemperature === null || e.anamnese.rectalTemperature === undefined ? null : e.anamnese.rectalTemperature,
            cardiopulmonaryAuscultation: e.anamnese.cardiopulmonaryAuscultation === null || e.anamnese.cardiopulmonaryAuscultation === undefined ? null : e.anamnese.cardiopulmonaryAuscultation,
            abdominalPalpation: e.anamnese.abdominalPalpation === null || e.anamnese.abdominalPalpation === undefined ? null : e.anamnese.abdominalPalpation,
            bodyScore: e.anamnese.bodyScore === null || e.anamnese.bodyScore === undefined ? null : e.anamnese.bodyScore,
            otherSystems: e.anamnese.otherSystems === null || e.anamnese.otherSystems === undefined ? null : e.anamnese.otherSystems,
            complementaryExams: e.anamnese.complementaryExams === null || e.anamnese.complementaryExams === undefined ? null : e.anamnese.complementaryExams,
            suspicions: e.anamnese.suspicions === null || e.anamnese.suspicions === undefined ? null : e.anamnese.suspicions,
            diagnosis: e.anamnese.diagnosis === null || e.anamnese.diagnosis === undefined ? null : e.anamnese.diagnosis,
            treatment: e.anamnese.treatment === null || e.anamnese.treatment === undefined ? null : e.anamnese.treatment,
            description: e.anamnese.description === null || e.anamnese.description === undefined ? null : e.anamnese.description,
        },
        fluidtherapys: e.fluidtherapys.map((e: any) => ({
            solution: e.solution === null || e.solution === undefined ? null : e.solution,
            fluidtherapyRate: e.fluidtherapyRate === null || e.fluidtherapyRate === undefined ? null : e.fluidtherapyRate,
            velocity: e.velocity === null || e.velocity === undefined ? null : e.velocity,
        })),
        finishedAt: e.finishedAt === null || e.finishedAt === undefined ? null : new Date(e.finishedAt + "Z"),
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getAllConsultationForAdminClinic(pageOffset: number, filterByPeriod: FilterByPeriod | null, progress?: (progress: number) => void): Promise<ConsultationForClinic[]> {
    const args = {
        pageOffset: pageOffset || 0,
        filterByPeriod: filterByPeriod === null || filterByPeriod === undefined ? null : {
            startDate: filterByPeriod.startDate === null || filterByPeriod.startDate === undefined ? null : (typeof filterByPeriod.startDate === "string" && (filterByPeriod.startDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterByPeriod.startDate as any).replace("Z", "") : filterByPeriod.startDate.toISOString().replace("Z", "")),
            endDate: filterByPeriod.endDate === null || filterByPeriod.endDate === undefined ? null : (typeof filterByPeriod.endDate === "string" && (filterByPeriod.endDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterByPeriod.endDate as any).replace("Z", "") : filterByPeriod.endDate.toISOString().replace("Z", "")),
        },
    };
    const ret = await makeRequest({name: "getAllConsultationForAdminClinic", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        user: {
            id: e.user.id,
            email: e.user.email,
            name: e.user.name,
            password: e.user.password,
            documentNumber: e.user.documentNumber === null || e.user.documentNumber === undefined ? null : e.user.documentNumber,
            phone: e.user.phone,
            image: e.user.image === null || e.user.image === undefined ? null : {
                thumb: {
                    width: e.user.image.thumb.width || 0,
                    height: e.user.image.thumb.height || 0,
                    url: e.user.image.thumb.url,
                },
                width: e.user.image.width || 0,
                height: e.user.image.height || 0,
                url: e.user.image.url,
            },
            petParents: e.user.petParents,
        },
        pet: {
            id: e.pet.id,
            name: e.pet.name,
            image: {
                thumb: {
                    width: e.pet.image.thumb.width || 0,
                    height: e.pet.image.thumb.height || 0,
                    url: e.pet.image.thumb.url,
                },
                width: e.pet.image.width || 0,
                height: e.pet.image.height || 0,
                url: e.pet.image.url,
            },
            age: e.pet.age,
            species: e.pet.species,
            animalSex: e.pet.animalSex,
            isCastrated: !!e.pet.isCastrated,
            code: e.pet.code || 0,
            breed: e.pet.breed,
            birthdate: new Date(e.pet.birthdate + "Z"),
            codeFormat: e.pet.codeFormat,
        },
        vet: {
            id: e.vet.id,
            name: e.vet.name,
            image: e.vet.image === null || e.vet.image === undefined ? null : {
                thumb: {
                    width: e.vet.image.thumb.width || 0,
                    height: e.vet.image.thumb.height || 0,
                    url: e.vet.image.thumb.url,
                },
                width: e.vet.image.width || 0,
                height: e.vet.image.height || 0,
                url: e.vet.image.url,
            },
            description: e.vet.description === null || e.vet.description === undefined ? null : e.vet.description,
            documentNumber: e.vet.documentNumber === null || e.vet.documentNumber === undefined ? null : e.vet.documentNumber,
            showInSite: !!e.vet.showInSite,
            clinic: e.vet.clinic === null || e.vet.clinic === undefined ? null : {
                id: e.vet.clinic.id,
                image: {
                    thumb: {
                        width: e.vet.clinic.image.thumb.width || 0,
                        height: e.vet.clinic.image.thumb.height || 0,
                        url: e.vet.clinic.image.thumb.url,
                    },
                    width: e.vet.clinic.image.width || 0,
                    height: e.vet.clinic.image.height || 0,
                    url: e.vet.clinic.image.url,
                },
                phone: e.vet.clinic.phone,
                showInSite: !!e.vet.clinic.showInSite,
                address: {
                    zipcode: e.vet.clinic.address.zipcode,
                    street: e.vet.clinic.address.street,
                    streetNumber: e.vet.clinic.address.streetNumber,
                    complementary: e.vet.clinic.address.complementary,
                    neighborhood: e.vet.clinic.address.neighborhood,
                    city: e.vet.clinic.address.city,
                    state: e.vet.clinic.address.state,
                },
                code: e.vet.clinic.code || 0,
                codeFormat: e.vet.clinic.codeFormat,
                services: e.vet.clinic.services === null || e.vet.clinic.services === undefined ? null : e.vet.clinic.services,
                createdAt: new Date(e.vet.clinic.createdAt + "Z"),
                adminUser: e.vet.clinic.adminUser === null || e.vet.clinic.adminUser === undefined ? null : {
                    name: e.vet.clinic.adminUser.name,
                    documentNumber: e.vet.clinic.adminUser.documentNumber,
                },
            },
        },
        date: new Date(e.date + "Z"),
        hour: e.hour,
        description: e.description,
        clinic: {
            id: e.clinic.id,
            image: {
                thumb: {
                    width: e.clinic.image.thumb.width || 0,
                    height: e.clinic.image.thumb.height || 0,
                    url: e.clinic.image.thumb.url,
                },
                width: e.clinic.image.width || 0,
                height: e.clinic.image.height || 0,
                url: e.clinic.image.url,
            },
            phone: e.clinic.phone,
            showInSite: !!e.clinic.showInSite,
            address: {
                zipcode: e.clinic.address.zipcode,
                street: e.clinic.address.street,
                streetNumber: e.clinic.address.streetNumber,
                complementary: e.clinic.address.complementary,
                neighborhood: e.clinic.address.neighborhood,
                city: e.clinic.address.city,
                state: e.clinic.address.state,
            },
            code: e.clinic.code || 0,
            codeFormat: e.clinic.codeFormat,
            services: e.clinic.services === null || e.clinic.services === undefined ? null : e.clinic.services,
            createdAt: new Date(e.clinic.createdAt + "Z"),
            adminUser: e.clinic.adminUser === null || e.clinic.adminUser === undefined ? null : {
                name: e.clinic.adminUser.name,
                documentNumber: e.clinic.adminUser.documentNumber,
            },
        },
        anamnese: {
            heartRate: e.anamnese.heartRate === null || e.anamnese.heartRate === undefined ? null : e.anamnese.heartRate,
            respiratoryFrequency: e.anamnese.respiratoryFrequency === null || e.anamnese.respiratoryFrequency === undefined ? null : e.anamnese.respiratoryFrequency,
            tpc: e.anamnese.tpc === null || e.anamnese.tpc === undefined ? null : e.anamnese.tpc,
            mucous: e.anamnese.mucous === null || e.anamnese.mucous === undefined ? null : e.anamnese.mucous,
            pulse: e.anamnese.pulse === null || e.anamnese.pulse === undefined ? null : e.anamnese.pulse,
            rectalTemperature: e.anamnese.rectalTemperature === null || e.anamnese.rectalTemperature === undefined ? null : e.anamnese.rectalTemperature,
            cardiopulmonaryAuscultation: e.anamnese.cardiopulmonaryAuscultation === null || e.anamnese.cardiopulmonaryAuscultation === undefined ? null : e.anamnese.cardiopulmonaryAuscultation,
            abdominalPalpation: e.anamnese.abdominalPalpation === null || e.anamnese.abdominalPalpation === undefined ? null : e.anamnese.abdominalPalpation,
            bodyScore: e.anamnese.bodyScore === null || e.anamnese.bodyScore === undefined ? null : e.anamnese.bodyScore,
            otherSystems: e.anamnese.otherSystems === null || e.anamnese.otherSystems === undefined ? null : e.anamnese.otherSystems,
            complementaryExams: e.anamnese.complementaryExams === null || e.anamnese.complementaryExams === undefined ? null : e.anamnese.complementaryExams,
            suspicions: e.anamnese.suspicions === null || e.anamnese.suspicions === undefined ? null : e.anamnese.suspicions,
            diagnosis: e.anamnese.diagnosis === null || e.anamnese.diagnosis === undefined ? null : e.anamnese.diagnosis,
            treatment: e.anamnese.treatment === null || e.anamnese.treatment === undefined ? null : e.anamnese.treatment,
            description: e.anamnese.description === null || e.anamnese.description === undefined ? null : e.anamnese.description,
        },
        fluidtherapys: e.fluidtherapys.map((e: any) => ({
            solution: e.solution === null || e.solution === undefined ? null : e.solution,
            fluidtherapyRate: e.fluidtherapyRate === null || e.fluidtherapyRate === undefined ? null : e.fluidtherapyRate,
            velocity: e.velocity === null || e.velocity === undefined ? null : e.velocity,
        })),
        finishedAt: e.finishedAt === null || e.finishedAt === undefined ? null : new Date(e.finishedAt + "Z"),
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getConsultationByIdForAdminClinic(id: string, progress?: (progress: number) => void): Promise<ConsultationForClinic> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getConsultationByIdForAdminClinic", args, progress});
    return {
        id: ret.id,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            password: ret.user.password,
            documentNumber: ret.user.documentNumber === null || ret.user.documentNumber === undefined ? null : ret.user.documentNumber,
            phone: ret.user.phone,
            image: ret.user.image === null || ret.user.image === undefined ? null : {
                thumb: {
                    width: ret.user.image.thumb.width || 0,
                    height: ret.user.image.thumb.height || 0,
                    url: ret.user.image.thumb.url,
                },
                width: ret.user.image.width || 0,
                height: ret.user.image.height || 0,
                url: ret.user.image.url,
            },
            petParents: ret.user.petParents,
        },
        pet: {
            id: ret.pet.id,
            name: ret.pet.name,
            image: {
                thumb: {
                    width: ret.pet.image.thumb.width || 0,
                    height: ret.pet.image.thumb.height || 0,
                    url: ret.pet.image.thumb.url,
                },
                width: ret.pet.image.width || 0,
                height: ret.pet.image.height || 0,
                url: ret.pet.image.url,
            },
            age: ret.pet.age,
            species: ret.pet.species,
            animalSex: ret.pet.animalSex,
            isCastrated: !!ret.pet.isCastrated,
            code: ret.pet.code || 0,
            breed: ret.pet.breed,
            birthdate: new Date(ret.pet.birthdate + "Z"),
            codeFormat: ret.pet.codeFormat,
        },
        vet: {
            id: ret.vet.id,
            name: ret.vet.name,
            image: ret.vet.image === null || ret.vet.image === undefined ? null : {
                thumb: {
                    width: ret.vet.image.thumb.width || 0,
                    height: ret.vet.image.thumb.height || 0,
                    url: ret.vet.image.thumb.url,
                },
                width: ret.vet.image.width || 0,
                height: ret.vet.image.height || 0,
                url: ret.vet.image.url,
            },
            description: ret.vet.description === null || ret.vet.description === undefined ? null : ret.vet.description,
            documentNumber: ret.vet.documentNumber === null || ret.vet.documentNumber === undefined ? null : ret.vet.documentNumber,
            showInSite: !!ret.vet.showInSite,
            clinic: ret.vet.clinic === null || ret.vet.clinic === undefined ? null : {
                id: ret.vet.clinic.id,
                image: {
                    thumb: {
                        width: ret.vet.clinic.image.thumb.width || 0,
                        height: ret.vet.clinic.image.thumb.height || 0,
                        url: ret.vet.clinic.image.thumb.url,
                    },
                    width: ret.vet.clinic.image.width || 0,
                    height: ret.vet.clinic.image.height || 0,
                    url: ret.vet.clinic.image.url,
                },
                phone: ret.vet.clinic.phone,
                showInSite: !!ret.vet.clinic.showInSite,
                address: {
                    zipcode: ret.vet.clinic.address.zipcode,
                    street: ret.vet.clinic.address.street,
                    streetNumber: ret.vet.clinic.address.streetNumber,
                    complementary: ret.vet.clinic.address.complementary,
                    neighborhood: ret.vet.clinic.address.neighborhood,
                    city: ret.vet.clinic.address.city,
                    state: ret.vet.clinic.address.state,
                },
                code: ret.vet.clinic.code || 0,
                codeFormat: ret.vet.clinic.codeFormat,
                services: ret.vet.clinic.services === null || ret.vet.clinic.services === undefined ? null : ret.vet.clinic.services,
                createdAt: new Date(ret.vet.clinic.createdAt + "Z"),
                adminUser: ret.vet.clinic.adminUser === null || ret.vet.clinic.adminUser === undefined ? null : {
                    name: ret.vet.clinic.adminUser.name,
                    documentNumber: ret.vet.clinic.adminUser.documentNumber,
                },
            },
        },
        date: new Date(ret.date + "Z"),
        hour: ret.hour,
        description: ret.description,
        clinic: {
            id: ret.clinic.id,
            image: {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            phone: ret.clinic.phone,
            showInSite: !!ret.clinic.showInSite,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
            },
            code: ret.clinic.code || 0,
            codeFormat: ret.clinic.codeFormat,
            services: ret.clinic.services === null || ret.clinic.services === undefined ? null : ret.clinic.services,
            createdAt: new Date(ret.clinic.createdAt + "Z"),
            adminUser: ret.clinic.adminUser === null || ret.clinic.adminUser === undefined ? null : {
                name: ret.clinic.adminUser.name,
                documentNumber: ret.clinic.adminUser.documentNumber,
            },
        },
        anamnese: {
            heartRate: ret.anamnese.heartRate === null || ret.anamnese.heartRate === undefined ? null : ret.anamnese.heartRate,
            respiratoryFrequency: ret.anamnese.respiratoryFrequency === null || ret.anamnese.respiratoryFrequency === undefined ? null : ret.anamnese.respiratoryFrequency,
            tpc: ret.anamnese.tpc === null || ret.anamnese.tpc === undefined ? null : ret.anamnese.tpc,
            mucous: ret.anamnese.mucous === null || ret.anamnese.mucous === undefined ? null : ret.anamnese.mucous,
            pulse: ret.anamnese.pulse === null || ret.anamnese.pulse === undefined ? null : ret.anamnese.pulse,
            rectalTemperature: ret.anamnese.rectalTemperature === null || ret.anamnese.rectalTemperature === undefined ? null : ret.anamnese.rectalTemperature,
            cardiopulmonaryAuscultation: ret.anamnese.cardiopulmonaryAuscultation === null || ret.anamnese.cardiopulmonaryAuscultation === undefined ? null : ret.anamnese.cardiopulmonaryAuscultation,
            abdominalPalpation: ret.anamnese.abdominalPalpation === null || ret.anamnese.abdominalPalpation === undefined ? null : ret.anamnese.abdominalPalpation,
            bodyScore: ret.anamnese.bodyScore === null || ret.anamnese.bodyScore === undefined ? null : ret.anamnese.bodyScore,
            otherSystems: ret.anamnese.otherSystems === null || ret.anamnese.otherSystems === undefined ? null : ret.anamnese.otherSystems,
            complementaryExams: ret.anamnese.complementaryExams === null || ret.anamnese.complementaryExams === undefined ? null : ret.anamnese.complementaryExams,
            suspicions: ret.anamnese.suspicions === null || ret.anamnese.suspicions === undefined ? null : ret.anamnese.suspicions,
            diagnosis: ret.anamnese.diagnosis === null || ret.anamnese.diagnosis === undefined ? null : ret.anamnese.diagnosis,
            treatment: ret.anamnese.treatment === null || ret.anamnese.treatment === undefined ? null : ret.anamnese.treatment,
            description: ret.anamnese.description === null || ret.anamnese.description === undefined ? null : ret.anamnese.description,
        },
        fluidtherapys: ret.fluidtherapys.map((e: any) => ({
            solution: e.solution === null || e.solution === undefined ? null : e.solution,
            fluidtherapyRate: e.fluidtherapyRate === null || e.fluidtherapyRate === undefined ? null : e.fluidtherapyRate,
            velocity: e.velocity === null || e.velocity === undefined ? null : e.velocity,
        })),
        finishedAt: ret.finishedAt === null || ret.finishedAt === undefined ? null : new Date(ret.finishedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function deleteConsultationById(id: string, progress?: (progress: number) => void): Promise<Consultation> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteConsultationById", args, progress});
    return {
        id: ret.id,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            password: ret.user.password,
            documentNumber: ret.user.documentNumber === null || ret.user.documentNumber === undefined ? null : ret.user.documentNumber,
            phone: ret.user.phone,
            image: ret.user.image === null || ret.user.image === undefined ? null : {
                thumb: {
                    width: ret.user.image.thumb.width || 0,
                    height: ret.user.image.thumb.height || 0,
                    url: ret.user.image.thumb.url,
                },
                width: ret.user.image.width || 0,
                height: ret.user.image.height || 0,
                url: ret.user.image.url,
            },
            petParents: ret.user.petParents,
        },
        pet: {
            id: ret.pet.id,
            name: ret.pet.name,
            image: {
                thumb: {
                    width: ret.pet.image.thumb.width || 0,
                    height: ret.pet.image.thumb.height || 0,
                    url: ret.pet.image.thumb.url,
                },
                width: ret.pet.image.width || 0,
                height: ret.pet.image.height || 0,
                url: ret.pet.image.url,
            },
            age: ret.pet.age,
            species: ret.pet.species,
            animalSex: ret.pet.animalSex,
            isCastrated: !!ret.pet.isCastrated,
            code: ret.pet.code || 0,
            breed: ret.pet.breed,
            birthdate: new Date(ret.pet.birthdate + "Z"),
            codeFormat: ret.pet.codeFormat,
        },
        vet: {
            id: ret.vet.id,
            name: ret.vet.name,
            image: ret.vet.image === null || ret.vet.image === undefined ? null : {
                thumb: {
                    width: ret.vet.image.thumb.width || 0,
                    height: ret.vet.image.thumb.height || 0,
                    url: ret.vet.image.thumb.url,
                },
                width: ret.vet.image.width || 0,
                height: ret.vet.image.height || 0,
                url: ret.vet.image.url,
            },
            description: ret.vet.description === null || ret.vet.description === undefined ? null : ret.vet.description,
            documentNumber: ret.vet.documentNumber === null || ret.vet.documentNumber === undefined ? null : ret.vet.documentNumber,
            showInSite: !!ret.vet.showInSite,
            clinic: ret.vet.clinic === null || ret.vet.clinic === undefined ? null : {
                id: ret.vet.clinic.id,
                image: {
                    thumb: {
                        width: ret.vet.clinic.image.thumb.width || 0,
                        height: ret.vet.clinic.image.thumb.height || 0,
                        url: ret.vet.clinic.image.thumb.url,
                    },
                    width: ret.vet.clinic.image.width || 0,
                    height: ret.vet.clinic.image.height || 0,
                    url: ret.vet.clinic.image.url,
                },
                phone: ret.vet.clinic.phone,
                showInSite: !!ret.vet.clinic.showInSite,
                address: {
                    zipcode: ret.vet.clinic.address.zipcode,
                    street: ret.vet.clinic.address.street,
                    streetNumber: ret.vet.clinic.address.streetNumber,
                    complementary: ret.vet.clinic.address.complementary,
                    neighborhood: ret.vet.clinic.address.neighborhood,
                    city: ret.vet.clinic.address.city,
                    state: ret.vet.clinic.address.state,
                },
                code: ret.vet.clinic.code || 0,
                codeFormat: ret.vet.clinic.codeFormat,
                services: ret.vet.clinic.services === null || ret.vet.clinic.services === undefined ? null : ret.vet.clinic.services,
                createdAt: new Date(ret.vet.clinic.createdAt + "Z"),
                adminUser: ret.vet.clinic.adminUser === null || ret.vet.clinic.adminUser === undefined ? null : {
                    name: ret.vet.clinic.adminUser.name,
                    documentNumber: ret.vet.clinic.adminUser.documentNumber,
                },
            },
        },
        date: new Date(ret.date + "Z"),
        hour: ret.hour,
        description: ret.description,
        clinic: {
            id: ret.clinic.id,
            image: {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            phone: ret.clinic.phone,
            showInSite: !!ret.clinic.showInSite,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
            },
            code: ret.clinic.code || 0,
            codeFormat: ret.clinic.codeFormat,
            services: ret.clinic.services === null || ret.clinic.services === undefined ? null : ret.clinic.services,
            createdAt: new Date(ret.clinic.createdAt + "Z"),
            adminUser: ret.clinic.adminUser === null || ret.clinic.adminUser === undefined ? null : {
                name: ret.clinic.adminUser.name,
                documentNumber: ret.clinic.adminUser.documentNumber,
            },
        },
        anamnese: {
            heartRate: ret.anamnese.heartRate === null || ret.anamnese.heartRate === undefined ? null : ret.anamnese.heartRate,
            respiratoryFrequency: ret.anamnese.respiratoryFrequency === null || ret.anamnese.respiratoryFrequency === undefined ? null : ret.anamnese.respiratoryFrequency,
            tpc: ret.anamnese.tpc === null || ret.anamnese.tpc === undefined ? null : ret.anamnese.tpc,
            mucous: ret.anamnese.mucous === null || ret.anamnese.mucous === undefined ? null : ret.anamnese.mucous,
            pulse: ret.anamnese.pulse === null || ret.anamnese.pulse === undefined ? null : ret.anamnese.pulse,
            rectalTemperature: ret.anamnese.rectalTemperature === null || ret.anamnese.rectalTemperature === undefined ? null : ret.anamnese.rectalTemperature,
            cardiopulmonaryAuscultation: ret.anamnese.cardiopulmonaryAuscultation === null || ret.anamnese.cardiopulmonaryAuscultation === undefined ? null : ret.anamnese.cardiopulmonaryAuscultation,
            abdominalPalpation: ret.anamnese.abdominalPalpation === null || ret.anamnese.abdominalPalpation === undefined ? null : ret.anamnese.abdominalPalpation,
            bodyScore: ret.anamnese.bodyScore === null || ret.anamnese.bodyScore === undefined ? null : ret.anamnese.bodyScore,
            otherSystems: ret.anamnese.otherSystems === null || ret.anamnese.otherSystems === undefined ? null : ret.anamnese.otherSystems,
            complementaryExams: ret.anamnese.complementaryExams === null || ret.anamnese.complementaryExams === undefined ? null : ret.anamnese.complementaryExams,
            suspicions: ret.anamnese.suspicions === null || ret.anamnese.suspicions === undefined ? null : ret.anamnese.suspicions,
            diagnosis: ret.anamnese.diagnosis === null || ret.anamnese.diagnosis === undefined ? null : ret.anamnese.diagnosis,
            treatment: ret.anamnese.treatment === null || ret.anamnese.treatment === undefined ? null : ret.anamnese.treatment,
            description: ret.anamnese.description === null || ret.anamnese.description === undefined ? null : ret.anamnese.description,
        },
        fluidtherapys: ret.fluidtherapys.map((e: any) => ({
            solution: e.solution === null || e.solution === undefined ? null : e.solution,
            fluidtherapyRate: e.fluidtherapyRate === null || e.fluidtherapyRate === undefined ? null : e.fluidtherapyRate,
            velocity: e.velocity === null || e.velocity === undefined ? null : e.velocity,
        })),
        finishedAt: ret.finishedAt === null || ret.finishedAt === undefined ? null : new Date(ret.finishedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function deleteConsultationByIdForAdminClinic(id: string, progress?: (progress: number) => void): Promise<ConsultationForClinic> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteConsultationByIdForAdminClinic", args, progress});
    return {
        id: ret.id,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            password: ret.user.password,
            documentNumber: ret.user.documentNumber === null || ret.user.documentNumber === undefined ? null : ret.user.documentNumber,
            phone: ret.user.phone,
            image: ret.user.image === null || ret.user.image === undefined ? null : {
                thumb: {
                    width: ret.user.image.thumb.width || 0,
                    height: ret.user.image.thumb.height || 0,
                    url: ret.user.image.thumb.url,
                },
                width: ret.user.image.width || 0,
                height: ret.user.image.height || 0,
                url: ret.user.image.url,
            },
            petParents: ret.user.petParents,
        },
        pet: {
            id: ret.pet.id,
            name: ret.pet.name,
            image: {
                thumb: {
                    width: ret.pet.image.thumb.width || 0,
                    height: ret.pet.image.thumb.height || 0,
                    url: ret.pet.image.thumb.url,
                },
                width: ret.pet.image.width || 0,
                height: ret.pet.image.height || 0,
                url: ret.pet.image.url,
            },
            age: ret.pet.age,
            species: ret.pet.species,
            animalSex: ret.pet.animalSex,
            isCastrated: !!ret.pet.isCastrated,
            code: ret.pet.code || 0,
            breed: ret.pet.breed,
            birthdate: new Date(ret.pet.birthdate + "Z"),
            codeFormat: ret.pet.codeFormat,
        },
        vet: {
            id: ret.vet.id,
            name: ret.vet.name,
            image: ret.vet.image === null || ret.vet.image === undefined ? null : {
                thumb: {
                    width: ret.vet.image.thumb.width || 0,
                    height: ret.vet.image.thumb.height || 0,
                    url: ret.vet.image.thumb.url,
                },
                width: ret.vet.image.width || 0,
                height: ret.vet.image.height || 0,
                url: ret.vet.image.url,
            },
            description: ret.vet.description === null || ret.vet.description === undefined ? null : ret.vet.description,
            documentNumber: ret.vet.documentNumber === null || ret.vet.documentNumber === undefined ? null : ret.vet.documentNumber,
            showInSite: !!ret.vet.showInSite,
            clinic: ret.vet.clinic === null || ret.vet.clinic === undefined ? null : {
                id: ret.vet.clinic.id,
                image: {
                    thumb: {
                        width: ret.vet.clinic.image.thumb.width || 0,
                        height: ret.vet.clinic.image.thumb.height || 0,
                        url: ret.vet.clinic.image.thumb.url,
                    },
                    width: ret.vet.clinic.image.width || 0,
                    height: ret.vet.clinic.image.height || 0,
                    url: ret.vet.clinic.image.url,
                },
                phone: ret.vet.clinic.phone,
                showInSite: !!ret.vet.clinic.showInSite,
                address: {
                    zipcode: ret.vet.clinic.address.zipcode,
                    street: ret.vet.clinic.address.street,
                    streetNumber: ret.vet.clinic.address.streetNumber,
                    complementary: ret.vet.clinic.address.complementary,
                    neighborhood: ret.vet.clinic.address.neighborhood,
                    city: ret.vet.clinic.address.city,
                    state: ret.vet.clinic.address.state,
                },
                code: ret.vet.clinic.code || 0,
                codeFormat: ret.vet.clinic.codeFormat,
                services: ret.vet.clinic.services === null || ret.vet.clinic.services === undefined ? null : ret.vet.clinic.services,
                createdAt: new Date(ret.vet.clinic.createdAt + "Z"),
                adminUser: ret.vet.clinic.adminUser === null || ret.vet.clinic.adminUser === undefined ? null : {
                    name: ret.vet.clinic.adminUser.name,
                    documentNumber: ret.vet.clinic.adminUser.documentNumber,
                },
            },
        },
        date: new Date(ret.date + "Z"),
        hour: ret.hour,
        description: ret.description,
        clinic: {
            id: ret.clinic.id,
            image: {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            phone: ret.clinic.phone,
            showInSite: !!ret.clinic.showInSite,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
            },
            code: ret.clinic.code || 0,
            codeFormat: ret.clinic.codeFormat,
            services: ret.clinic.services === null || ret.clinic.services === undefined ? null : ret.clinic.services,
            createdAt: new Date(ret.clinic.createdAt + "Z"),
            adminUser: ret.clinic.adminUser === null || ret.clinic.adminUser === undefined ? null : {
                name: ret.clinic.adminUser.name,
                documentNumber: ret.clinic.adminUser.documentNumber,
            },
        },
        anamnese: {
            heartRate: ret.anamnese.heartRate === null || ret.anamnese.heartRate === undefined ? null : ret.anamnese.heartRate,
            respiratoryFrequency: ret.anamnese.respiratoryFrequency === null || ret.anamnese.respiratoryFrequency === undefined ? null : ret.anamnese.respiratoryFrequency,
            tpc: ret.anamnese.tpc === null || ret.anamnese.tpc === undefined ? null : ret.anamnese.tpc,
            mucous: ret.anamnese.mucous === null || ret.anamnese.mucous === undefined ? null : ret.anamnese.mucous,
            pulse: ret.anamnese.pulse === null || ret.anamnese.pulse === undefined ? null : ret.anamnese.pulse,
            rectalTemperature: ret.anamnese.rectalTemperature === null || ret.anamnese.rectalTemperature === undefined ? null : ret.anamnese.rectalTemperature,
            cardiopulmonaryAuscultation: ret.anamnese.cardiopulmonaryAuscultation === null || ret.anamnese.cardiopulmonaryAuscultation === undefined ? null : ret.anamnese.cardiopulmonaryAuscultation,
            abdominalPalpation: ret.anamnese.abdominalPalpation === null || ret.anamnese.abdominalPalpation === undefined ? null : ret.anamnese.abdominalPalpation,
            bodyScore: ret.anamnese.bodyScore === null || ret.anamnese.bodyScore === undefined ? null : ret.anamnese.bodyScore,
            otherSystems: ret.anamnese.otherSystems === null || ret.anamnese.otherSystems === undefined ? null : ret.anamnese.otherSystems,
            complementaryExams: ret.anamnese.complementaryExams === null || ret.anamnese.complementaryExams === undefined ? null : ret.anamnese.complementaryExams,
            suspicions: ret.anamnese.suspicions === null || ret.anamnese.suspicions === undefined ? null : ret.anamnese.suspicions,
            diagnosis: ret.anamnese.diagnosis === null || ret.anamnese.diagnosis === undefined ? null : ret.anamnese.diagnosis,
            treatment: ret.anamnese.treatment === null || ret.anamnese.treatment === undefined ? null : ret.anamnese.treatment,
            description: ret.anamnese.description === null || ret.anamnese.description === undefined ? null : ret.anamnese.description,
        },
        fluidtherapys: ret.fluidtherapys.map((e: any) => ({
            solution: e.solution === null || e.solution === undefined ? null : e.solution,
            fluidtherapyRate: e.fluidtherapyRate === null || e.fluidtherapyRate === undefined ? null : e.fluidtherapyRate,
            velocity: e.velocity === null || e.velocity === undefined ? null : e.velocity,
        })),
        finishedAt: ret.finishedAt === null || ret.finishedAt === undefined ? null : new Date(ret.finishedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function createConsultationForAdminClinic(newConsultation: NewConsultation, progress?: (progress: number) => void): Promise<ConsultationForClinic> {
    const args = {
        newConsultation: {
            vetId: newConsultation.vetId,
            petId: newConsultation.petId,
            date: (typeof newConsultation.date === "string" && (newConsultation.date as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newConsultation.date as any).replace("Z", "") : newConsultation.date.toISOString().replace("Z", "")),
            hour: (typeof newConsultation.hour === "string" && (newConsultation.hour as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newConsultation.hour as any).replace("Z", "") : newConsultation.hour.toISOString().replace("Z", "")),
            description: newConsultation.description,
            anamnese: {
                heartRate: newConsultation.anamnese.heartRate === null || newConsultation.anamnese.heartRate === undefined ? null : newConsultation.anamnese.heartRate,
                respiratoryFrequency: newConsultation.anamnese.respiratoryFrequency === null || newConsultation.anamnese.respiratoryFrequency === undefined ? null : newConsultation.anamnese.respiratoryFrequency,
                tpc: newConsultation.anamnese.tpc === null || newConsultation.anamnese.tpc === undefined ? null : newConsultation.anamnese.tpc,
                mucous: newConsultation.anamnese.mucous === null || newConsultation.anamnese.mucous === undefined ? null : newConsultation.anamnese.mucous,
                pulse: newConsultation.anamnese.pulse === null || newConsultation.anamnese.pulse === undefined ? null : newConsultation.anamnese.pulse,
                rectalTemperature: newConsultation.anamnese.rectalTemperature === null || newConsultation.anamnese.rectalTemperature === undefined ? null : newConsultation.anamnese.rectalTemperature,
                cardiopulmonaryAuscultation: newConsultation.anamnese.cardiopulmonaryAuscultation === null || newConsultation.anamnese.cardiopulmonaryAuscultation === undefined ? null : newConsultation.anamnese.cardiopulmonaryAuscultation,
                abdominalPalpation: newConsultation.anamnese.abdominalPalpation === null || newConsultation.anamnese.abdominalPalpation === undefined ? null : newConsultation.anamnese.abdominalPalpation,
                bodyScore: newConsultation.anamnese.bodyScore === null || newConsultation.anamnese.bodyScore === undefined ? null : newConsultation.anamnese.bodyScore,
                otherSystems: newConsultation.anamnese.otherSystems === null || newConsultation.anamnese.otherSystems === undefined ? null : newConsultation.anamnese.otherSystems,
                complementaryExams: newConsultation.anamnese.complementaryExams === null || newConsultation.anamnese.complementaryExams === undefined ? null : newConsultation.anamnese.complementaryExams,
                suspicions: newConsultation.anamnese.suspicions === null || newConsultation.anamnese.suspicions === undefined ? null : newConsultation.anamnese.suspicions,
                diagnosis: newConsultation.anamnese.diagnosis === null || newConsultation.anamnese.diagnosis === undefined ? null : newConsultation.anamnese.diagnosis,
                treatment: newConsultation.anamnese.treatment === null || newConsultation.anamnese.treatment === undefined ? null : newConsultation.anamnese.treatment,
                description: newConsultation.anamnese.description === null || newConsultation.anamnese.description === undefined ? null : newConsultation.anamnese.description,
            },
            fluidtherapys: newConsultation.fluidtherapys.map(e => ({
                solution: e.solution === null || e.solution === undefined ? null : e.solution,
                fluidtherapyRate: e.fluidtherapyRate === null || e.fluidtherapyRate === undefined ? null : e.fluidtherapyRate,
                velocity: e.velocity === null || e.velocity === undefined ? null : e.velocity,
            })),
        },
    };
    const ret = await makeRequest({name: "createConsultationForAdminClinic", args, progress});
    return {
        id: ret.id,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            password: ret.user.password,
            documentNumber: ret.user.documentNumber === null || ret.user.documentNumber === undefined ? null : ret.user.documentNumber,
            phone: ret.user.phone,
            image: ret.user.image === null || ret.user.image === undefined ? null : {
                thumb: {
                    width: ret.user.image.thumb.width || 0,
                    height: ret.user.image.thumb.height || 0,
                    url: ret.user.image.thumb.url,
                },
                width: ret.user.image.width || 0,
                height: ret.user.image.height || 0,
                url: ret.user.image.url,
            },
            petParents: ret.user.petParents,
        },
        pet: {
            id: ret.pet.id,
            name: ret.pet.name,
            image: {
                thumb: {
                    width: ret.pet.image.thumb.width || 0,
                    height: ret.pet.image.thumb.height || 0,
                    url: ret.pet.image.thumb.url,
                },
                width: ret.pet.image.width || 0,
                height: ret.pet.image.height || 0,
                url: ret.pet.image.url,
            },
            age: ret.pet.age,
            species: ret.pet.species,
            animalSex: ret.pet.animalSex,
            isCastrated: !!ret.pet.isCastrated,
            code: ret.pet.code || 0,
            breed: ret.pet.breed,
            birthdate: new Date(ret.pet.birthdate + "Z"),
            codeFormat: ret.pet.codeFormat,
        },
        vet: {
            id: ret.vet.id,
            name: ret.vet.name,
            image: ret.vet.image === null || ret.vet.image === undefined ? null : {
                thumb: {
                    width: ret.vet.image.thumb.width || 0,
                    height: ret.vet.image.thumb.height || 0,
                    url: ret.vet.image.thumb.url,
                },
                width: ret.vet.image.width || 0,
                height: ret.vet.image.height || 0,
                url: ret.vet.image.url,
            },
            description: ret.vet.description === null || ret.vet.description === undefined ? null : ret.vet.description,
            documentNumber: ret.vet.documentNumber === null || ret.vet.documentNumber === undefined ? null : ret.vet.documentNumber,
            showInSite: !!ret.vet.showInSite,
            clinic: ret.vet.clinic === null || ret.vet.clinic === undefined ? null : {
                id: ret.vet.clinic.id,
                image: {
                    thumb: {
                        width: ret.vet.clinic.image.thumb.width || 0,
                        height: ret.vet.clinic.image.thumb.height || 0,
                        url: ret.vet.clinic.image.thumb.url,
                    },
                    width: ret.vet.clinic.image.width || 0,
                    height: ret.vet.clinic.image.height || 0,
                    url: ret.vet.clinic.image.url,
                },
                phone: ret.vet.clinic.phone,
                showInSite: !!ret.vet.clinic.showInSite,
                address: {
                    zipcode: ret.vet.clinic.address.zipcode,
                    street: ret.vet.clinic.address.street,
                    streetNumber: ret.vet.clinic.address.streetNumber,
                    complementary: ret.vet.clinic.address.complementary,
                    neighborhood: ret.vet.clinic.address.neighborhood,
                    city: ret.vet.clinic.address.city,
                    state: ret.vet.clinic.address.state,
                },
                code: ret.vet.clinic.code || 0,
                codeFormat: ret.vet.clinic.codeFormat,
                services: ret.vet.clinic.services === null || ret.vet.clinic.services === undefined ? null : ret.vet.clinic.services,
                createdAt: new Date(ret.vet.clinic.createdAt + "Z"),
                adminUser: ret.vet.clinic.adminUser === null || ret.vet.clinic.adminUser === undefined ? null : {
                    name: ret.vet.clinic.adminUser.name,
                    documentNumber: ret.vet.clinic.adminUser.documentNumber,
                },
            },
        },
        date: new Date(ret.date + "Z"),
        hour: ret.hour,
        description: ret.description,
        clinic: {
            id: ret.clinic.id,
            image: {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            phone: ret.clinic.phone,
            showInSite: !!ret.clinic.showInSite,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
            },
            code: ret.clinic.code || 0,
            codeFormat: ret.clinic.codeFormat,
            services: ret.clinic.services === null || ret.clinic.services === undefined ? null : ret.clinic.services,
            createdAt: new Date(ret.clinic.createdAt + "Z"),
            adminUser: ret.clinic.adminUser === null || ret.clinic.adminUser === undefined ? null : {
                name: ret.clinic.adminUser.name,
                documentNumber: ret.clinic.adminUser.documentNumber,
            },
        },
        anamnese: {
            heartRate: ret.anamnese.heartRate === null || ret.anamnese.heartRate === undefined ? null : ret.anamnese.heartRate,
            respiratoryFrequency: ret.anamnese.respiratoryFrequency === null || ret.anamnese.respiratoryFrequency === undefined ? null : ret.anamnese.respiratoryFrequency,
            tpc: ret.anamnese.tpc === null || ret.anamnese.tpc === undefined ? null : ret.anamnese.tpc,
            mucous: ret.anamnese.mucous === null || ret.anamnese.mucous === undefined ? null : ret.anamnese.mucous,
            pulse: ret.anamnese.pulse === null || ret.anamnese.pulse === undefined ? null : ret.anamnese.pulse,
            rectalTemperature: ret.anamnese.rectalTemperature === null || ret.anamnese.rectalTemperature === undefined ? null : ret.anamnese.rectalTemperature,
            cardiopulmonaryAuscultation: ret.anamnese.cardiopulmonaryAuscultation === null || ret.anamnese.cardiopulmonaryAuscultation === undefined ? null : ret.anamnese.cardiopulmonaryAuscultation,
            abdominalPalpation: ret.anamnese.abdominalPalpation === null || ret.anamnese.abdominalPalpation === undefined ? null : ret.anamnese.abdominalPalpation,
            bodyScore: ret.anamnese.bodyScore === null || ret.anamnese.bodyScore === undefined ? null : ret.anamnese.bodyScore,
            otherSystems: ret.anamnese.otherSystems === null || ret.anamnese.otherSystems === undefined ? null : ret.anamnese.otherSystems,
            complementaryExams: ret.anamnese.complementaryExams === null || ret.anamnese.complementaryExams === undefined ? null : ret.anamnese.complementaryExams,
            suspicions: ret.anamnese.suspicions === null || ret.anamnese.suspicions === undefined ? null : ret.anamnese.suspicions,
            diagnosis: ret.anamnese.diagnosis === null || ret.anamnese.diagnosis === undefined ? null : ret.anamnese.diagnosis,
            treatment: ret.anamnese.treatment === null || ret.anamnese.treatment === undefined ? null : ret.anamnese.treatment,
            description: ret.anamnese.description === null || ret.anamnese.description === undefined ? null : ret.anamnese.description,
        },
        fluidtherapys: ret.fluidtherapys.map((e: any) => ({
            solution: e.solution === null || e.solution === undefined ? null : e.solution,
            fluidtherapyRate: e.fluidtherapyRate === null || e.fluidtherapyRate === undefined ? null : e.fluidtherapyRate,
            velocity: e.velocity === null || e.velocity === undefined ? null : e.velocity,
        })),
        finishedAt: ret.finishedAt === null || ret.finishedAt === undefined ? null : new Date(ret.finishedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function editConsultationForAdminClinic(id: string, editConsultation: EditConsultation, progress?: (progress: number) => void): Promise<ConsultationForClinic> {
    const args = {
        id: id,
        editConsultation: {
            date: (typeof editConsultation.date === "string" && (editConsultation.date as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editConsultation.date as any).replace("Z", "") : editConsultation.date.toISOString().replace("Z", "")),
            hour: (typeof editConsultation.hour === "string" && (editConsultation.hour as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editConsultation.hour as any).replace("Z", "") : editConsultation.hour.toISOString().replace("Z", "")),
            description: editConsultation.description,
            anamnese: {
                heartRate: editConsultation.anamnese.heartRate === null || editConsultation.anamnese.heartRate === undefined ? null : editConsultation.anamnese.heartRate,
                respiratoryFrequency: editConsultation.anamnese.respiratoryFrequency === null || editConsultation.anamnese.respiratoryFrequency === undefined ? null : editConsultation.anamnese.respiratoryFrequency,
                tpc: editConsultation.anamnese.tpc === null || editConsultation.anamnese.tpc === undefined ? null : editConsultation.anamnese.tpc,
                mucous: editConsultation.anamnese.mucous === null || editConsultation.anamnese.mucous === undefined ? null : editConsultation.anamnese.mucous,
                pulse: editConsultation.anamnese.pulse === null || editConsultation.anamnese.pulse === undefined ? null : editConsultation.anamnese.pulse,
                rectalTemperature: editConsultation.anamnese.rectalTemperature === null || editConsultation.anamnese.rectalTemperature === undefined ? null : editConsultation.anamnese.rectalTemperature,
                cardiopulmonaryAuscultation: editConsultation.anamnese.cardiopulmonaryAuscultation === null || editConsultation.anamnese.cardiopulmonaryAuscultation === undefined ? null : editConsultation.anamnese.cardiopulmonaryAuscultation,
                abdominalPalpation: editConsultation.anamnese.abdominalPalpation === null || editConsultation.anamnese.abdominalPalpation === undefined ? null : editConsultation.anamnese.abdominalPalpation,
                bodyScore: editConsultation.anamnese.bodyScore === null || editConsultation.anamnese.bodyScore === undefined ? null : editConsultation.anamnese.bodyScore,
                otherSystems: editConsultation.anamnese.otherSystems === null || editConsultation.anamnese.otherSystems === undefined ? null : editConsultation.anamnese.otherSystems,
                complementaryExams: editConsultation.anamnese.complementaryExams === null || editConsultation.anamnese.complementaryExams === undefined ? null : editConsultation.anamnese.complementaryExams,
                suspicions: editConsultation.anamnese.suspicions === null || editConsultation.anamnese.suspicions === undefined ? null : editConsultation.anamnese.suspicions,
                diagnosis: editConsultation.anamnese.diagnosis === null || editConsultation.anamnese.diagnosis === undefined ? null : editConsultation.anamnese.diagnosis,
                treatment: editConsultation.anamnese.treatment === null || editConsultation.anamnese.treatment === undefined ? null : editConsultation.anamnese.treatment,
                description: editConsultation.anamnese.description === null || editConsultation.anamnese.description === undefined ? null : editConsultation.anamnese.description,
            },
            fluidtherapys: editConsultation.fluidtherapys.map(e => ({
                solution: e.solution === null || e.solution === undefined ? null : e.solution,
                fluidtherapyRate: e.fluidtherapyRate === null || e.fluidtherapyRate === undefined ? null : e.fluidtherapyRate,
                velocity: e.velocity === null || e.velocity === undefined ? null : e.velocity,
            })),
        },
    };
    const ret = await makeRequest({name: "editConsultationForAdminClinic", args, progress});
    return {
        id: ret.id,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            password: ret.user.password,
            documentNumber: ret.user.documentNumber === null || ret.user.documentNumber === undefined ? null : ret.user.documentNumber,
            phone: ret.user.phone,
            image: ret.user.image === null || ret.user.image === undefined ? null : {
                thumb: {
                    width: ret.user.image.thumb.width || 0,
                    height: ret.user.image.thumb.height || 0,
                    url: ret.user.image.thumb.url,
                },
                width: ret.user.image.width || 0,
                height: ret.user.image.height || 0,
                url: ret.user.image.url,
            },
            petParents: ret.user.petParents,
        },
        pet: {
            id: ret.pet.id,
            name: ret.pet.name,
            image: {
                thumb: {
                    width: ret.pet.image.thumb.width || 0,
                    height: ret.pet.image.thumb.height || 0,
                    url: ret.pet.image.thumb.url,
                },
                width: ret.pet.image.width || 0,
                height: ret.pet.image.height || 0,
                url: ret.pet.image.url,
            },
            age: ret.pet.age,
            species: ret.pet.species,
            animalSex: ret.pet.animalSex,
            isCastrated: !!ret.pet.isCastrated,
            code: ret.pet.code || 0,
            breed: ret.pet.breed,
            birthdate: new Date(ret.pet.birthdate + "Z"),
            codeFormat: ret.pet.codeFormat,
        },
        vet: {
            id: ret.vet.id,
            name: ret.vet.name,
            image: ret.vet.image === null || ret.vet.image === undefined ? null : {
                thumb: {
                    width: ret.vet.image.thumb.width || 0,
                    height: ret.vet.image.thumb.height || 0,
                    url: ret.vet.image.thumb.url,
                },
                width: ret.vet.image.width || 0,
                height: ret.vet.image.height || 0,
                url: ret.vet.image.url,
            },
            description: ret.vet.description === null || ret.vet.description === undefined ? null : ret.vet.description,
            documentNumber: ret.vet.documentNumber === null || ret.vet.documentNumber === undefined ? null : ret.vet.documentNumber,
            showInSite: !!ret.vet.showInSite,
            clinic: ret.vet.clinic === null || ret.vet.clinic === undefined ? null : {
                id: ret.vet.clinic.id,
                image: {
                    thumb: {
                        width: ret.vet.clinic.image.thumb.width || 0,
                        height: ret.vet.clinic.image.thumb.height || 0,
                        url: ret.vet.clinic.image.thumb.url,
                    },
                    width: ret.vet.clinic.image.width || 0,
                    height: ret.vet.clinic.image.height || 0,
                    url: ret.vet.clinic.image.url,
                },
                phone: ret.vet.clinic.phone,
                showInSite: !!ret.vet.clinic.showInSite,
                address: {
                    zipcode: ret.vet.clinic.address.zipcode,
                    street: ret.vet.clinic.address.street,
                    streetNumber: ret.vet.clinic.address.streetNumber,
                    complementary: ret.vet.clinic.address.complementary,
                    neighborhood: ret.vet.clinic.address.neighborhood,
                    city: ret.vet.clinic.address.city,
                    state: ret.vet.clinic.address.state,
                },
                code: ret.vet.clinic.code || 0,
                codeFormat: ret.vet.clinic.codeFormat,
                services: ret.vet.clinic.services === null || ret.vet.clinic.services === undefined ? null : ret.vet.clinic.services,
                createdAt: new Date(ret.vet.clinic.createdAt + "Z"),
                adminUser: ret.vet.clinic.adminUser === null || ret.vet.clinic.adminUser === undefined ? null : {
                    name: ret.vet.clinic.adminUser.name,
                    documentNumber: ret.vet.clinic.adminUser.documentNumber,
                },
            },
        },
        date: new Date(ret.date + "Z"),
        hour: ret.hour,
        description: ret.description,
        clinic: {
            id: ret.clinic.id,
            image: {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            phone: ret.clinic.phone,
            showInSite: !!ret.clinic.showInSite,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
            },
            code: ret.clinic.code || 0,
            codeFormat: ret.clinic.codeFormat,
            services: ret.clinic.services === null || ret.clinic.services === undefined ? null : ret.clinic.services,
            createdAt: new Date(ret.clinic.createdAt + "Z"),
            adminUser: ret.clinic.adminUser === null || ret.clinic.adminUser === undefined ? null : {
                name: ret.clinic.adminUser.name,
                documentNumber: ret.clinic.adminUser.documentNumber,
            },
        },
        anamnese: {
            heartRate: ret.anamnese.heartRate === null || ret.anamnese.heartRate === undefined ? null : ret.anamnese.heartRate,
            respiratoryFrequency: ret.anamnese.respiratoryFrequency === null || ret.anamnese.respiratoryFrequency === undefined ? null : ret.anamnese.respiratoryFrequency,
            tpc: ret.anamnese.tpc === null || ret.anamnese.tpc === undefined ? null : ret.anamnese.tpc,
            mucous: ret.anamnese.mucous === null || ret.anamnese.mucous === undefined ? null : ret.anamnese.mucous,
            pulse: ret.anamnese.pulse === null || ret.anamnese.pulse === undefined ? null : ret.anamnese.pulse,
            rectalTemperature: ret.anamnese.rectalTemperature === null || ret.anamnese.rectalTemperature === undefined ? null : ret.anamnese.rectalTemperature,
            cardiopulmonaryAuscultation: ret.anamnese.cardiopulmonaryAuscultation === null || ret.anamnese.cardiopulmonaryAuscultation === undefined ? null : ret.anamnese.cardiopulmonaryAuscultation,
            abdominalPalpation: ret.anamnese.abdominalPalpation === null || ret.anamnese.abdominalPalpation === undefined ? null : ret.anamnese.abdominalPalpation,
            bodyScore: ret.anamnese.bodyScore === null || ret.anamnese.bodyScore === undefined ? null : ret.anamnese.bodyScore,
            otherSystems: ret.anamnese.otherSystems === null || ret.anamnese.otherSystems === undefined ? null : ret.anamnese.otherSystems,
            complementaryExams: ret.anamnese.complementaryExams === null || ret.anamnese.complementaryExams === undefined ? null : ret.anamnese.complementaryExams,
            suspicions: ret.anamnese.suspicions === null || ret.anamnese.suspicions === undefined ? null : ret.anamnese.suspicions,
            diagnosis: ret.anamnese.diagnosis === null || ret.anamnese.diagnosis === undefined ? null : ret.anamnese.diagnosis,
            treatment: ret.anamnese.treatment === null || ret.anamnese.treatment === undefined ? null : ret.anamnese.treatment,
            description: ret.anamnese.description === null || ret.anamnese.description === undefined ? null : ret.anamnese.description,
        },
        fluidtherapys: ret.fluidtherapys.map((e: any) => ({
            solution: e.solution === null || e.solution === undefined ? null : e.solution,
            fluidtherapyRate: e.fluidtherapyRate === null || e.fluidtherapyRate === undefined ? null : e.fluidtherapyRate,
            velocity: e.velocity === null || e.velocity === undefined ? null : e.velocity,
        })),
        finishedAt: ret.finishedAt === null || ret.finishedAt === undefined ? null : new Date(ret.finishedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function finishConsultation(id: string, progress?: (progress: number) => void): Promise<ConsultationForClinic> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "finishConsultation", args, progress});
    return {
        id: ret.id,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            password: ret.user.password,
            documentNumber: ret.user.documentNumber === null || ret.user.documentNumber === undefined ? null : ret.user.documentNumber,
            phone: ret.user.phone,
            image: ret.user.image === null || ret.user.image === undefined ? null : {
                thumb: {
                    width: ret.user.image.thumb.width || 0,
                    height: ret.user.image.thumb.height || 0,
                    url: ret.user.image.thumb.url,
                },
                width: ret.user.image.width || 0,
                height: ret.user.image.height || 0,
                url: ret.user.image.url,
            },
            petParents: ret.user.petParents,
        },
        pet: {
            id: ret.pet.id,
            name: ret.pet.name,
            image: {
                thumb: {
                    width: ret.pet.image.thumb.width || 0,
                    height: ret.pet.image.thumb.height || 0,
                    url: ret.pet.image.thumb.url,
                },
                width: ret.pet.image.width || 0,
                height: ret.pet.image.height || 0,
                url: ret.pet.image.url,
            },
            age: ret.pet.age,
            species: ret.pet.species,
            animalSex: ret.pet.animalSex,
            isCastrated: !!ret.pet.isCastrated,
            code: ret.pet.code || 0,
            breed: ret.pet.breed,
            birthdate: new Date(ret.pet.birthdate + "Z"),
            codeFormat: ret.pet.codeFormat,
        },
        vet: {
            id: ret.vet.id,
            name: ret.vet.name,
            image: ret.vet.image === null || ret.vet.image === undefined ? null : {
                thumb: {
                    width: ret.vet.image.thumb.width || 0,
                    height: ret.vet.image.thumb.height || 0,
                    url: ret.vet.image.thumb.url,
                },
                width: ret.vet.image.width || 0,
                height: ret.vet.image.height || 0,
                url: ret.vet.image.url,
            },
            description: ret.vet.description === null || ret.vet.description === undefined ? null : ret.vet.description,
            documentNumber: ret.vet.documentNumber === null || ret.vet.documentNumber === undefined ? null : ret.vet.documentNumber,
            showInSite: !!ret.vet.showInSite,
            clinic: ret.vet.clinic === null || ret.vet.clinic === undefined ? null : {
                id: ret.vet.clinic.id,
                image: {
                    thumb: {
                        width: ret.vet.clinic.image.thumb.width || 0,
                        height: ret.vet.clinic.image.thumb.height || 0,
                        url: ret.vet.clinic.image.thumb.url,
                    },
                    width: ret.vet.clinic.image.width || 0,
                    height: ret.vet.clinic.image.height || 0,
                    url: ret.vet.clinic.image.url,
                },
                phone: ret.vet.clinic.phone,
                showInSite: !!ret.vet.clinic.showInSite,
                address: {
                    zipcode: ret.vet.clinic.address.zipcode,
                    street: ret.vet.clinic.address.street,
                    streetNumber: ret.vet.clinic.address.streetNumber,
                    complementary: ret.vet.clinic.address.complementary,
                    neighborhood: ret.vet.clinic.address.neighborhood,
                    city: ret.vet.clinic.address.city,
                    state: ret.vet.clinic.address.state,
                },
                code: ret.vet.clinic.code || 0,
                codeFormat: ret.vet.clinic.codeFormat,
                services: ret.vet.clinic.services === null || ret.vet.clinic.services === undefined ? null : ret.vet.clinic.services,
                createdAt: new Date(ret.vet.clinic.createdAt + "Z"),
                adminUser: ret.vet.clinic.adminUser === null || ret.vet.clinic.adminUser === undefined ? null : {
                    name: ret.vet.clinic.adminUser.name,
                    documentNumber: ret.vet.clinic.adminUser.documentNumber,
                },
            },
        },
        date: new Date(ret.date + "Z"),
        hour: ret.hour,
        description: ret.description,
        clinic: {
            id: ret.clinic.id,
            image: {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            phone: ret.clinic.phone,
            showInSite: !!ret.clinic.showInSite,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
            },
            code: ret.clinic.code || 0,
            codeFormat: ret.clinic.codeFormat,
            services: ret.clinic.services === null || ret.clinic.services === undefined ? null : ret.clinic.services,
            createdAt: new Date(ret.clinic.createdAt + "Z"),
            adminUser: ret.clinic.adminUser === null || ret.clinic.adminUser === undefined ? null : {
                name: ret.clinic.adminUser.name,
                documentNumber: ret.clinic.adminUser.documentNumber,
            },
        },
        anamnese: {
            heartRate: ret.anamnese.heartRate === null || ret.anamnese.heartRate === undefined ? null : ret.anamnese.heartRate,
            respiratoryFrequency: ret.anamnese.respiratoryFrequency === null || ret.anamnese.respiratoryFrequency === undefined ? null : ret.anamnese.respiratoryFrequency,
            tpc: ret.anamnese.tpc === null || ret.anamnese.tpc === undefined ? null : ret.anamnese.tpc,
            mucous: ret.anamnese.mucous === null || ret.anamnese.mucous === undefined ? null : ret.anamnese.mucous,
            pulse: ret.anamnese.pulse === null || ret.anamnese.pulse === undefined ? null : ret.anamnese.pulse,
            rectalTemperature: ret.anamnese.rectalTemperature === null || ret.anamnese.rectalTemperature === undefined ? null : ret.anamnese.rectalTemperature,
            cardiopulmonaryAuscultation: ret.anamnese.cardiopulmonaryAuscultation === null || ret.anamnese.cardiopulmonaryAuscultation === undefined ? null : ret.anamnese.cardiopulmonaryAuscultation,
            abdominalPalpation: ret.anamnese.abdominalPalpation === null || ret.anamnese.abdominalPalpation === undefined ? null : ret.anamnese.abdominalPalpation,
            bodyScore: ret.anamnese.bodyScore === null || ret.anamnese.bodyScore === undefined ? null : ret.anamnese.bodyScore,
            otherSystems: ret.anamnese.otherSystems === null || ret.anamnese.otherSystems === undefined ? null : ret.anamnese.otherSystems,
            complementaryExams: ret.anamnese.complementaryExams === null || ret.anamnese.complementaryExams === undefined ? null : ret.anamnese.complementaryExams,
            suspicions: ret.anamnese.suspicions === null || ret.anamnese.suspicions === undefined ? null : ret.anamnese.suspicions,
            diagnosis: ret.anamnese.diagnosis === null || ret.anamnese.diagnosis === undefined ? null : ret.anamnese.diagnosis,
            treatment: ret.anamnese.treatment === null || ret.anamnese.treatment === undefined ? null : ret.anamnese.treatment,
            description: ret.anamnese.description === null || ret.anamnese.description === undefined ? null : ret.anamnese.description,
        },
        fluidtherapys: ret.fluidtherapys.map((e: any) => ({
            solution: e.solution === null || e.solution === undefined ? null : e.solution,
            fluidtherapyRate: e.fluidtherapyRate === null || e.fluidtherapyRate === undefined ? null : e.fluidtherapyRate,
            velocity: e.velocity === null || e.velocity === undefined ? null : e.velocity,
        })),
        finishedAt: ret.finishedAt === null || ret.finishedAt === undefined ? null : new Date(ret.finishedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function getAllExams(pageOffset: number, progress?: (progress: number) => void): Promise<Exam[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllExams", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        code: e.code === null || e.code === undefined ? null : e.code || 0,
        type: e.type,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        pdf: e.pdf === null || e.pdf === undefined ? null : e.pdf,
        createdAt: e.createdAt === null || e.createdAt === undefined ? null : new Date(e.createdAt + "Z"),
    }));
}

export async function getExamById(id: string, progress?: (progress: number) => void): Promise<Exam> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getExamById", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        code: ret.code === null || ret.code === undefined ? null : ret.code || 0,
        type: ret.type,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        pdf: ret.pdf === null || ret.pdf === undefined ? null : ret.pdf,
        createdAt: ret.createdAt === null || ret.createdAt === undefined ? null : new Date(ret.createdAt + "Z"),
    };
}

export async function getAllExamByPet(pageOffSet: number, petId: string, progress?: (progress: number) => void): Promise<Exam[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
        petId: petId,
    };
    const ret = await makeRequest({name: "getAllExamByPet", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        code: e.code === null || e.code === undefined ? null : e.code || 0,
        type: e.type,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        pdf: e.pdf === null || e.pdf === undefined ? null : e.pdf,
        createdAt: e.createdAt === null || e.createdAt === undefined ? null : new Date(e.createdAt + "Z"),
    }));
}

export async function getAllExamByConsultation(pageOffSet: number, consultationId: string, progress?: (progress: number) => void): Promise<Exam[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
        consultationId: consultationId,
    };
    const ret = await makeRequest({name: "getAllExamByConsultation", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        code: e.code === null || e.code === undefined ? null : e.code || 0,
        type: e.type,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        pdf: e.pdf === null || e.pdf === undefined ? null : e.pdf,
        createdAt: e.createdAt === null || e.createdAt === undefined ? null : new Date(e.createdAt + "Z"),
    }));
}

export async function getAllExamByHospitalization(pageOffSet: number, hospitalizationId: string, progress?: (progress: number) => void): Promise<Exam[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
        hospitalizationId: hospitalizationId,
    };
    const ret = await makeRequest({name: "getAllExamByHospitalization", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        code: e.code === null || e.code === undefined ? null : e.code || 0,
        type: e.type,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        pdf: e.pdf === null || e.pdf === undefined ? null : e.pdf,
        createdAt: e.createdAt === null || e.createdAt === undefined ? null : new Date(e.createdAt + "Z"),
    }));
}

export async function createExamConsultation(newExam: EditExam, consultationId: string, progress?: (progress: number) => void): Promise<Exam> {
    const args = {
        newExam: {
            name: newExam.name,
            code: newExam.code === null || newExam.code === undefined ? null : newExam.code || 0,
            type: newExam.type,
            image: newExam.image === null || newExam.image === undefined ? null : {
                thumb: {
                    width: newExam.image.thumb.width || 0,
                    height: newExam.image.thumb.height || 0,
                    url: newExam.image.thumb.url,
                },
                width: newExam.image.width || 0,
                height: newExam.image.height || 0,
                url: newExam.image.url,
            },
            pdf: newExam.pdf === null || newExam.pdf === undefined ? null : {
                bytes: newExam.pdf.bytes === null || newExam.pdf.bytes === undefined ? null : newExam.pdf.bytes.toString("base64"),
                url: newExam.pdf.url === null || newExam.pdf.url === undefined ? null : newExam.pdf.url,
            },
        },
        consultationId: consultationId,
    };
    const ret = await makeRequest({name: "createExamConsultation", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        code: ret.code === null || ret.code === undefined ? null : ret.code || 0,
        type: ret.type,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        pdf: ret.pdf === null || ret.pdf === undefined ? null : ret.pdf,
        createdAt: ret.createdAt === null || ret.createdAt === undefined ? null : new Date(ret.createdAt + "Z"),
    };
}

export async function createExamHospitalization(newExam: EditExam, hospitalizationId: string, progress?: (progress: number) => void): Promise<Exam> {
    const args = {
        newExam: {
            name: newExam.name,
            code: newExam.code === null || newExam.code === undefined ? null : newExam.code || 0,
            type: newExam.type,
            image: newExam.image === null || newExam.image === undefined ? null : {
                thumb: {
                    width: newExam.image.thumb.width || 0,
                    height: newExam.image.thumb.height || 0,
                    url: newExam.image.thumb.url,
                },
                width: newExam.image.width || 0,
                height: newExam.image.height || 0,
                url: newExam.image.url,
            },
            pdf: newExam.pdf === null || newExam.pdf === undefined ? null : {
                bytes: newExam.pdf.bytes === null || newExam.pdf.bytes === undefined ? null : newExam.pdf.bytes.toString("base64"),
                url: newExam.pdf.url === null || newExam.pdf.url === undefined ? null : newExam.pdf.url,
            },
        },
        hospitalizationId: hospitalizationId,
    };
    const ret = await makeRequest({name: "createExamHospitalization", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        code: ret.code === null || ret.code === undefined ? null : ret.code || 0,
        type: ret.type,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        pdf: ret.pdf === null || ret.pdf === undefined ? null : ret.pdf,
        createdAt: ret.createdAt === null || ret.createdAt === undefined ? null : new Date(ret.createdAt + "Z"),
    };
}

export async function editExam(id: string, editExam: EditExam, progress?: (progress: number) => void): Promise<Exam> {
    const args = {
        id: id,
        editExam: {
            name: editExam.name,
            code: editExam.code === null || editExam.code === undefined ? null : editExam.code || 0,
            type: editExam.type,
            image: editExam.image === null || editExam.image === undefined ? null : {
                thumb: {
                    width: editExam.image.thumb.width || 0,
                    height: editExam.image.thumb.height || 0,
                    url: editExam.image.thumb.url,
                },
                width: editExam.image.width || 0,
                height: editExam.image.height || 0,
                url: editExam.image.url,
            },
            pdf: editExam.pdf === null || editExam.pdf === undefined ? null : {
                bytes: editExam.pdf.bytes === null || editExam.pdf.bytes === undefined ? null : editExam.pdf.bytes.toString("base64"),
                url: editExam.pdf.url === null || editExam.pdf.url === undefined ? null : editExam.pdf.url,
            },
        },
    };
    const ret = await makeRequest({name: "editExam", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        code: ret.code === null || ret.code === undefined ? null : ret.code || 0,
        type: ret.type,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        pdf: ret.pdf === null || ret.pdf === undefined ? null : ret.pdf,
        createdAt: ret.createdAt === null || ret.createdAt === undefined ? null : new Date(ret.createdAt + "Z"),
    };
}

export async function deleteExameById(id: string, progress?: (progress: number) => void): Promise<Exam> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteExameById", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        code: ret.code === null || ret.code === undefined ? null : ret.code || 0,
        type: ret.type,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        pdf: ret.pdf === null || ret.pdf === undefined ? null : ret.pdf,
        createdAt: ret.createdAt === null || ret.createdAt === undefined ? null : new Date(ret.createdAt + "Z"),
    };
}

export async function getAllHospitalizations(pageOffset: number, filterByPeriod: FilterByPeriod | null, progress?: (progress: number) => void): Promise<Hospitalization[]> {
    const args = {
        pageOffset: pageOffset || 0,
        filterByPeriod: filterByPeriod === null || filterByPeriod === undefined ? null : {
            startDate: filterByPeriod.startDate === null || filterByPeriod.startDate === undefined ? null : (typeof filterByPeriod.startDate === "string" && (filterByPeriod.startDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterByPeriod.startDate as any).replace("Z", "") : filterByPeriod.startDate.toISOString().replace("Z", "")),
            endDate: filterByPeriod.endDate === null || filterByPeriod.endDate === undefined ? null : (typeof filterByPeriod.endDate === "string" && (filterByPeriod.endDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterByPeriod.endDate as any).replace("Z", "") : filterByPeriod.endDate.toISOString().replace("Z", "")),
        },
    };
    const ret = await makeRequest({name: "getAllHospitalizations", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        startDate: new Date(e.startDate + "Z"),
        endDate: e.endDate === null || e.endDate === undefined ? null : new Date(e.endDate + "Z"),
        finishedAt: e.finishedAt === null || e.finishedAt === undefined ? null : new Date(e.finishedAt + "Z"),
        entryTime: e.entryTime,
        exitTime: e.exitTime === null || e.exitTime === undefined ? null : e.exitTime,
        images: e.images === null || e.images === undefined ? null : e.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        pet: {
            id: e.pet.id,
            name: e.pet.name,
            image: {
                thumb: {
                    width: e.pet.image.thumb.width || 0,
                    height: e.pet.image.thumb.height || 0,
                    url: e.pet.image.thumb.url,
                },
                width: e.pet.image.width || 0,
                height: e.pet.image.height || 0,
                url: e.pet.image.url,
            },
            age: e.pet.age,
            species: e.pet.species,
            animalSex: e.pet.animalSex,
            isCastrated: !!e.pet.isCastrated,
            code: e.pet.code || 0,
            breed: e.pet.breed,
            birthdate: new Date(e.pet.birthdate + "Z"),
            codeFormat: e.pet.codeFormat,
        },
        clinic: e.clinic === null || e.clinic === undefined ? null : {
            id: e.clinic.id,
            image: {
                thumb: {
                    width: e.clinic.image.thumb.width || 0,
                    height: e.clinic.image.thumb.height || 0,
                    url: e.clinic.image.thumb.url,
                },
                width: e.clinic.image.width || 0,
                height: e.clinic.image.height || 0,
                url: e.clinic.image.url,
            },
            phone: e.clinic.phone,
            showInSite: !!e.clinic.showInSite,
            address: {
                zipcode: e.clinic.address.zipcode,
                street: e.clinic.address.street,
                streetNumber: e.clinic.address.streetNumber,
                complementary: e.clinic.address.complementary,
                neighborhood: e.clinic.address.neighborhood,
                city: e.clinic.address.city,
                state: e.clinic.address.state,
            },
            code: e.clinic.code || 0,
            codeFormat: e.clinic.codeFormat,
            services: e.clinic.services === null || e.clinic.services === undefined ? null : e.clinic.services,
            createdAt: new Date(e.clinic.createdAt + "Z"),
            adminUser: e.clinic.adminUser === null || e.clinic.adminUser === undefined ? null : {
                name: e.clinic.adminUser.name,
                documentNumber: e.clinic.adminUser.documentNumber,
            },
        },
    }));
}

export async function getHospitalizationById(id: string, progress?: (progress: number) => void): Promise<Hospitalization> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getHospitalizationById", args, progress});
    return {
        id: ret.id,
        startDate: new Date(ret.startDate + "Z"),
        endDate: ret.endDate === null || ret.endDate === undefined ? null : new Date(ret.endDate + "Z"),
        finishedAt: ret.finishedAt === null || ret.finishedAt === undefined ? null : new Date(ret.finishedAt + "Z"),
        entryTime: ret.entryTime,
        exitTime: ret.exitTime === null || ret.exitTime === undefined ? null : ret.exitTime,
        images: ret.images === null || ret.images === undefined ? null : ret.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        pet: {
            id: ret.pet.id,
            name: ret.pet.name,
            image: {
                thumb: {
                    width: ret.pet.image.thumb.width || 0,
                    height: ret.pet.image.thumb.height || 0,
                    url: ret.pet.image.thumb.url,
                },
                width: ret.pet.image.width || 0,
                height: ret.pet.image.height || 0,
                url: ret.pet.image.url,
            },
            age: ret.pet.age,
            species: ret.pet.species,
            animalSex: ret.pet.animalSex,
            isCastrated: !!ret.pet.isCastrated,
            code: ret.pet.code || 0,
            breed: ret.pet.breed,
            birthdate: new Date(ret.pet.birthdate + "Z"),
            codeFormat: ret.pet.codeFormat,
        },
        clinic: ret.clinic === null || ret.clinic === undefined ? null : {
            id: ret.clinic.id,
            image: {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            phone: ret.clinic.phone,
            showInSite: !!ret.clinic.showInSite,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
            },
            code: ret.clinic.code || 0,
            codeFormat: ret.clinic.codeFormat,
            services: ret.clinic.services === null || ret.clinic.services === undefined ? null : ret.clinic.services,
            createdAt: new Date(ret.clinic.createdAt + "Z"),
            adminUser: ret.clinic.adminUser === null || ret.clinic.adminUser === undefined ? null : {
                name: ret.clinic.adminUser.name,
                documentNumber: ret.clinic.adminUser.documentNumber,
            },
        },
    };
}

export async function getAllHospitalizationsForAdminClinic(pageOffset: number, filterByPeriod: FilterByPeriod | null, progress?: (progress: number) => void): Promise<HospitalizationForClinic[]> {
    const args = {
        pageOffset: pageOffset || 0,
        filterByPeriod: filterByPeriod === null || filterByPeriod === undefined ? null : {
            startDate: filterByPeriod.startDate === null || filterByPeriod.startDate === undefined ? null : (typeof filterByPeriod.startDate === "string" && (filterByPeriod.startDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterByPeriod.startDate as any).replace("Z", "") : filterByPeriod.startDate.toISOString().replace("Z", "")),
            endDate: filterByPeriod.endDate === null || filterByPeriod.endDate === undefined ? null : (typeof filterByPeriod.endDate === "string" && (filterByPeriod.endDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (filterByPeriod.endDate as any).replace("Z", "") : filterByPeriod.endDate.toISOString().replace("Z", "")),
        },
    };
    const ret = await makeRequest({name: "getAllHospitalizationsForAdminClinic", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        startDate: new Date(e.startDate + "Z"),
        endDate: e.endDate === null || e.endDate === undefined ? null : new Date(e.endDate + "Z"),
        finishedAt: e.finishedAt === null || e.finishedAt === undefined ? null : new Date(e.finishedAt + "Z"),
        entryTime: e.entryTime,
        exitTime: e.exitTime === null || e.exitTime === undefined ? null : e.exitTime,
        images: e.images === null || e.images === undefined ? null : e.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        pet: {
            id: e.pet.id,
            name: e.pet.name,
            image: {
                thumb: {
                    width: e.pet.image.thumb.width || 0,
                    height: e.pet.image.thumb.height || 0,
                    url: e.pet.image.thumb.url,
                },
                width: e.pet.image.width || 0,
                height: e.pet.image.height || 0,
                url: e.pet.image.url,
            },
            age: e.pet.age,
            species: e.pet.species,
            animalSex: e.pet.animalSex,
            isCastrated: !!e.pet.isCastrated,
            code: e.pet.code || 0,
            breed: e.pet.breed,
            birthdate: new Date(e.pet.birthdate + "Z"),
            codeFormat: e.pet.codeFormat,
        },
        clinic: e.clinic === null || e.clinic === undefined ? null : {
            id: e.clinic.id,
            image: {
                thumb: {
                    width: e.clinic.image.thumb.width || 0,
                    height: e.clinic.image.thumb.height || 0,
                    url: e.clinic.image.thumb.url,
                },
                width: e.clinic.image.width || 0,
                height: e.clinic.image.height || 0,
                url: e.clinic.image.url,
            },
            phone: e.clinic.phone,
            showInSite: !!e.clinic.showInSite,
            address: {
                zipcode: e.clinic.address.zipcode,
                street: e.clinic.address.street,
                streetNumber: e.clinic.address.streetNumber,
                complementary: e.clinic.address.complementary,
                neighborhood: e.clinic.address.neighborhood,
                city: e.clinic.address.city,
                state: e.clinic.address.state,
            },
            code: e.clinic.code || 0,
            codeFormat: e.clinic.codeFormat,
            services: e.clinic.services === null || e.clinic.services === undefined ? null : e.clinic.services,
            createdAt: new Date(e.clinic.createdAt + "Z"),
            adminUser: e.clinic.adminUser === null || e.clinic.adminUser === undefined ? null : {
                name: e.clinic.adminUser.name,
                documentNumber: e.clinic.adminUser.documentNumber,
            },
        },
    }));
}

export async function getAllHospitalizationsForAdminClinicByPet(pageOffset: number, petId: string, progress?: (progress: number) => void): Promise<HospitalizationForClinic[]> {
    const args = {
        pageOffset: pageOffset || 0,
        petId: petId,
    };
    const ret = await makeRequest({name: "getAllHospitalizationsForAdminClinicByPet", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        startDate: new Date(e.startDate + "Z"),
        endDate: e.endDate === null || e.endDate === undefined ? null : new Date(e.endDate + "Z"),
        finishedAt: e.finishedAt === null || e.finishedAt === undefined ? null : new Date(e.finishedAt + "Z"),
        entryTime: e.entryTime,
        exitTime: e.exitTime === null || e.exitTime === undefined ? null : e.exitTime,
        images: e.images === null || e.images === undefined ? null : e.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        pet: {
            id: e.pet.id,
            name: e.pet.name,
            image: {
                thumb: {
                    width: e.pet.image.thumb.width || 0,
                    height: e.pet.image.thumb.height || 0,
                    url: e.pet.image.thumb.url,
                },
                width: e.pet.image.width || 0,
                height: e.pet.image.height || 0,
                url: e.pet.image.url,
            },
            age: e.pet.age,
            species: e.pet.species,
            animalSex: e.pet.animalSex,
            isCastrated: !!e.pet.isCastrated,
            code: e.pet.code || 0,
            breed: e.pet.breed,
            birthdate: new Date(e.pet.birthdate + "Z"),
            codeFormat: e.pet.codeFormat,
        },
        clinic: e.clinic === null || e.clinic === undefined ? null : {
            id: e.clinic.id,
            image: {
                thumb: {
                    width: e.clinic.image.thumb.width || 0,
                    height: e.clinic.image.thumb.height || 0,
                    url: e.clinic.image.thumb.url,
                },
                width: e.clinic.image.width || 0,
                height: e.clinic.image.height || 0,
                url: e.clinic.image.url,
            },
            phone: e.clinic.phone,
            showInSite: !!e.clinic.showInSite,
            address: {
                zipcode: e.clinic.address.zipcode,
                street: e.clinic.address.street,
                streetNumber: e.clinic.address.streetNumber,
                complementary: e.clinic.address.complementary,
                neighborhood: e.clinic.address.neighborhood,
                city: e.clinic.address.city,
                state: e.clinic.address.state,
            },
            code: e.clinic.code || 0,
            codeFormat: e.clinic.codeFormat,
            services: e.clinic.services === null || e.clinic.services === undefined ? null : e.clinic.services,
            createdAt: new Date(e.clinic.createdAt + "Z"),
            adminUser: e.clinic.adminUser === null || e.clinic.adminUser === undefined ? null : {
                name: e.clinic.adminUser.name,
                documentNumber: e.clinic.adminUser.documentNumber,
            },
        },
    }));
}

export async function getAllHospitalizationsByPet(pageOffset: number, petId: string, progress?: (progress: number) => void): Promise<Hospitalization[]> {
    const args = {
        pageOffset: pageOffset || 0,
        petId: petId,
    };
    const ret = await makeRequest({name: "getAllHospitalizationsByPet", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        startDate: new Date(e.startDate + "Z"),
        endDate: e.endDate === null || e.endDate === undefined ? null : new Date(e.endDate + "Z"),
        finishedAt: e.finishedAt === null || e.finishedAt === undefined ? null : new Date(e.finishedAt + "Z"),
        entryTime: e.entryTime,
        exitTime: e.exitTime === null || e.exitTime === undefined ? null : e.exitTime,
        images: e.images === null || e.images === undefined ? null : e.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        pet: {
            id: e.pet.id,
            name: e.pet.name,
            image: {
                thumb: {
                    width: e.pet.image.thumb.width || 0,
                    height: e.pet.image.thumb.height || 0,
                    url: e.pet.image.thumb.url,
                },
                width: e.pet.image.width || 0,
                height: e.pet.image.height || 0,
                url: e.pet.image.url,
            },
            age: e.pet.age,
            species: e.pet.species,
            animalSex: e.pet.animalSex,
            isCastrated: !!e.pet.isCastrated,
            code: e.pet.code || 0,
            breed: e.pet.breed,
            birthdate: new Date(e.pet.birthdate + "Z"),
            codeFormat: e.pet.codeFormat,
        },
        clinic: e.clinic === null || e.clinic === undefined ? null : {
            id: e.clinic.id,
            image: {
                thumb: {
                    width: e.clinic.image.thumb.width || 0,
                    height: e.clinic.image.thumb.height || 0,
                    url: e.clinic.image.thumb.url,
                },
                width: e.clinic.image.width || 0,
                height: e.clinic.image.height || 0,
                url: e.clinic.image.url,
            },
            phone: e.clinic.phone,
            showInSite: !!e.clinic.showInSite,
            address: {
                zipcode: e.clinic.address.zipcode,
                street: e.clinic.address.street,
                streetNumber: e.clinic.address.streetNumber,
                complementary: e.clinic.address.complementary,
                neighborhood: e.clinic.address.neighborhood,
                city: e.clinic.address.city,
                state: e.clinic.address.state,
            },
            code: e.clinic.code || 0,
            codeFormat: e.clinic.codeFormat,
            services: e.clinic.services === null || e.clinic.services === undefined ? null : e.clinic.services,
            createdAt: new Date(e.clinic.createdAt + "Z"),
            adminUser: e.clinic.adminUser === null || e.clinic.adminUser === undefined ? null : {
                name: e.clinic.adminUser.name,
                documentNumber: e.clinic.adminUser.documentNumber,
            },
        },
    }));
}

export async function getHospitalizationByIdForAdminClinic(id: string, progress?: (progress: number) => void): Promise<HospitalizationForClinic> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getHospitalizationByIdForAdminClinic", args, progress});
    return {
        id: ret.id,
        startDate: new Date(ret.startDate + "Z"),
        endDate: ret.endDate === null || ret.endDate === undefined ? null : new Date(ret.endDate + "Z"),
        finishedAt: ret.finishedAt === null || ret.finishedAt === undefined ? null : new Date(ret.finishedAt + "Z"),
        entryTime: ret.entryTime,
        exitTime: ret.exitTime === null || ret.exitTime === undefined ? null : ret.exitTime,
        images: ret.images === null || ret.images === undefined ? null : ret.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        pet: {
            id: ret.pet.id,
            name: ret.pet.name,
            image: {
                thumb: {
                    width: ret.pet.image.thumb.width || 0,
                    height: ret.pet.image.thumb.height || 0,
                    url: ret.pet.image.thumb.url,
                },
                width: ret.pet.image.width || 0,
                height: ret.pet.image.height || 0,
                url: ret.pet.image.url,
            },
            age: ret.pet.age,
            species: ret.pet.species,
            animalSex: ret.pet.animalSex,
            isCastrated: !!ret.pet.isCastrated,
            code: ret.pet.code || 0,
            breed: ret.pet.breed,
            birthdate: new Date(ret.pet.birthdate + "Z"),
            codeFormat: ret.pet.codeFormat,
        },
        clinic: ret.clinic === null || ret.clinic === undefined ? null : {
            id: ret.clinic.id,
            image: {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            phone: ret.clinic.phone,
            showInSite: !!ret.clinic.showInSite,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
            },
            code: ret.clinic.code || 0,
            codeFormat: ret.clinic.codeFormat,
            services: ret.clinic.services === null || ret.clinic.services === undefined ? null : ret.clinic.services,
            createdAt: new Date(ret.clinic.createdAt + "Z"),
            adminUser: ret.clinic.adminUser === null || ret.clinic.adminUser === undefined ? null : {
                name: ret.clinic.adminUser.name,
                documentNumber: ret.clinic.adminUser.documentNumber,
            },
        },
    };
}

export async function deleteHospitalizationById(id: string, progress?: (progress: number) => void): Promise<Hospitalization> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteHospitalizationById", args, progress});
    return {
        id: ret.id,
        startDate: new Date(ret.startDate + "Z"),
        endDate: ret.endDate === null || ret.endDate === undefined ? null : new Date(ret.endDate + "Z"),
        finishedAt: ret.finishedAt === null || ret.finishedAt === undefined ? null : new Date(ret.finishedAt + "Z"),
        entryTime: ret.entryTime,
        exitTime: ret.exitTime === null || ret.exitTime === undefined ? null : ret.exitTime,
        images: ret.images === null || ret.images === undefined ? null : ret.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        pet: {
            id: ret.pet.id,
            name: ret.pet.name,
            image: {
                thumb: {
                    width: ret.pet.image.thumb.width || 0,
                    height: ret.pet.image.thumb.height || 0,
                    url: ret.pet.image.thumb.url,
                },
                width: ret.pet.image.width || 0,
                height: ret.pet.image.height || 0,
                url: ret.pet.image.url,
            },
            age: ret.pet.age,
            species: ret.pet.species,
            animalSex: ret.pet.animalSex,
            isCastrated: !!ret.pet.isCastrated,
            code: ret.pet.code || 0,
            breed: ret.pet.breed,
            birthdate: new Date(ret.pet.birthdate + "Z"),
            codeFormat: ret.pet.codeFormat,
        },
        clinic: ret.clinic === null || ret.clinic === undefined ? null : {
            id: ret.clinic.id,
            image: {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            phone: ret.clinic.phone,
            showInSite: !!ret.clinic.showInSite,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
            },
            code: ret.clinic.code || 0,
            codeFormat: ret.clinic.codeFormat,
            services: ret.clinic.services === null || ret.clinic.services === undefined ? null : ret.clinic.services,
            createdAt: new Date(ret.clinic.createdAt + "Z"),
            adminUser: ret.clinic.adminUser === null || ret.clinic.adminUser === undefined ? null : {
                name: ret.clinic.adminUser.name,
                documentNumber: ret.clinic.adminUser.documentNumber,
            },
        },
    };
}

export async function deleteHospitalizationByIdForAdminClinic(id: string, progress?: (progress: number) => void): Promise<HospitalizationForClinic> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteHospitalizationByIdForAdminClinic", args, progress});
    return {
        id: ret.id,
        startDate: new Date(ret.startDate + "Z"),
        endDate: ret.endDate === null || ret.endDate === undefined ? null : new Date(ret.endDate + "Z"),
        finishedAt: ret.finishedAt === null || ret.finishedAt === undefined ? null : new Date(ret.finishedAt + "Z"),
        entryTime: ret.entryTime,
        exitTime: ret.exitTime === null || ret.exitTime === undefined ? null : ret.exitTime,
        images: ret.images === null || ret.images === undefined ? null : ret.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        pet: {
            id: ret.pet.id,
            name: ret.pet.name,
            image: {
                thumb: {
                    width: ret.pet.image.thumb.width || 0,
                    height: ret.pet.image.thumb.height || 0,
                    url: ret.pet.image.thumb.url,
                },
                width: ret.pet.image.width || 0,
                height: ret.pet.image.height || 0,
                url: ret.pet.image.url,
            },
            age: ret.pet.age,
            species: ret.pet.species,
            animalSex: ret.pet.animalSex,
            isCastrated: !!ret.pet.isCastrated,
            code: ret.pet.code || 0,
            breed: ret.pet.breed,
            birthdate: new Date(ret.pet.birthdate + "Z"),
            codeFormat: ret.pet.codeFormat,
        },
        clinic: ret.clinic === null || ret.clinic === undefined ? null : {
            id: ret.clinic.id,
            image: {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            phone: ret.clinic.phone,
            showInSite: !!ret.clinic.showInSite,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
            },
            code: ret.clinic.code || 0,
            codeFormat: ret.clinic.codeFormat,
            services: ret.clinic.services === null || ret.clinic.services === undefined ? null : ret.clinic.services,
            createdAt: new Date(ret.clinic.createdAt + "Z"),
            adminUser: ret.clinic.adminUser === null || ret.clinic.adminUser === undefined ? null : {
                name: ret.clinic.adminUser.name,
                documentNumber: ret.clinic.adminUser.documentNumber,
            },
        },
    };
}

export async function createHospitalizationForAdminClinic(newHospitalization: NewHospitalization, progress?: (progress: number) => void): Promise<HospitalizationForClinic> {
    const args = {
        newHospitalization: {
            petId: newHospitalization.petId,
            startDate: (typeof newHospitalization.startDate === "string" && (newHospitalization.startDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newHospitalization.startDate as any).replace("Z", "") : newHospitalization.startDate.toISOString().replace("Z", "")),
            endDate: newHospitalization.endDate === null || newHospitalization.endDate === undefined ? null : (typeof newHospitalization.endDate === "string" && (newHospitalization.endDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newHospitalization.endDate as any).replace("Z", "") : newHospitalization.endDate.toISOString().replace("Z", "")),
            entryTime: (typeof newHospitalization.entryTime === "string" && (newHospitalization.entryTime as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newHospitalization.entryTime as any).replace("Z", "") : newHospitalization.entryTime.toISOString().replace("Z", "")),
            exitTime: newHospitalization.exitTime === null || newHospitalization.exitTime === undefined ? null : (typeof newHospitalization.exitTime === "string" && (newHospitalization.exitTime as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newHospitalization.exitTime as any).replace("Z", "") : newHospitalization.exitTime.toISOString().replace("Z", "")),
            images: newHospitalization.images === null || newHospitalization.images === undefined ? null : newHospitalization.images.map(e => ({
                thumb: {
                    width: e.thumb.width || 0,
                    height: e.thumb.height || 0,
                    url: e.thumb.url,
                },
                width: e.width || 0,
                height: e.height || 0,
                url: e.url,
            })),
        },
    };
    const ret = await makeRequest({name: "createHospitalizationForAdminClinic", args, progress});
    return {
        id: ret.id,
        startDate: new Date(ret.startDate + "Z"),
        endDate: ret.endDate === null || ret.endDate === undefined ? null : new Date(ret.endDate + "Z"),
        finishedAt: ret.finishedAt === null || ret.finishedAt === undefined ? null : new Date(ret.finishedAt + "Z"),
        entryTime: ret.entryTime,
        exitTime: ret.exitTime === null || ret.exitTime === undefined ? null : ret.exitTime,
        images: ret.images === null || ret.images === undefined ? null : ret.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        pet: {
            id: ret.pet.id,
            name: ret.pet.name,
            image: {
                thumb: {
                    width: ret.pet.image.thumb.width || 0,
                    height: ret.pet.image.thumb.height || 0,
                    url: ret.pet.image.thumb.url,
                },
                width: ret.pet.image.width || 0,
                height: ret.pet.image.height || 0,
                url: ret.pet.image.url,
            },
            age: ret.pet.age,
            species: ret.pet.species,
            animalSex: ret.pet.animalSex,
            isCastrated: !!ret.pet.isCastrated,
            code: ret.pet.code || 0,
            breed: ret.pet.breed,
            birthdate: new Date(ret.pet.birthdate + "Z"),
            codeFormat: ret.pet.codeFormat,
        },
        clinic: ret.clinic === null || ret.clinic === undefined ? null : {
            id: ret.clinic.id,
            image: {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            phone: ret.clinic.phone,
            showInSite: !!ret.clinic.showInSite,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
            },
            code: ret.clinic.code || 0,
            codeFormat: ret.clinic.codeFormat,
            services: ret.clinic.services === null || ret.clinic.services === undefined ? null : ret.clinic.services,
            createdAt: new Date(ret.clinic.createdAt + "Z"),
            adminUser: ret.clinic.adminUser === null || ret.clinic.adminUser === undefined ? null : {
                name: ret.clinic.adminUser.name,
                documentNumber: ret.clinic.adminUser.documentNumber,
            },
        },
    };
}

export async function editHospitalizationForAdminClinic(id: string, editHospitalization: EditHospitalization, progress?: (progress: number) => void): Promise<HospitalizationForClinic> {
    const args = {
        id: id,
        editHospitalization: {
            startDate: (typeof editHospitalization.startDate === "string" && (editHospitalization.startDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editHospitalization.startDate as any).replace("Z", "") : editHospitalization.startDate.toISOString().replace("Z", "")),
            endDate: editHospitalization.endDate === null || editHospitalization.endDate === undefined ? null : (typeof editHospitalization.endDate === "string" && (editHospitalization.endDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editHospitalization.endDate as any).replace("Z", "") : editHospitalization.endDate.toISOString().replace("Z", "")),
            entryTime: (typeof editHospitalization.entryTime === "string" && (editHospitalization.entryTime as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editHospitalization.entryTime as any).replace("Z", "") : editHospitalization.entryTime.toISOString().replace("Z", "")),
            exitTime: editHospitalization.exitTime === null || editHospitalization.exitTime === undefined ? null : (typeof editHospitalization.exitTime === "string" && (editHospitalization.exitTime as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editHospitalization.exitTime as any).replace("Z", "") : editHospitalization.exitTime.toISOString().replace("Z", "")),
            images: editHospitalization.images === null || editHospitalization.images === undefined ? null : editHospitalization.images.map(e => ({
                thumb: {
                    width: e.thumb.width || 0,
                    height: e.thumb.height || 0,
                    url: e.thumb.url,
                },
                width: e.width || 0,
                height: e.height || 0,
                url: e.url,
            })),
        },
    };
    const ret = await makeRequest({name: "editHospitalizationForAdminClinic", args, progress});
    return {
        id: ret.id,
        startDate: new Date(ret.startDate + "Z"),
        endDate: ret.endDate === null || ret.endDate === undefined ? null : new Date(ret.endDate + "Z"),
        finishedAt: ret.finishedAt === null || ret.finishedAt === undefined ? null : new Date(ret.finishedAt + "Z"),
        entryTime: ret.entryTime,
        exitTime: ret.exitTime === null || ret.exitTime === undefined ? null : ret.exitTime,
        images: ret.images === null || ret.images === undefined ? null : ret.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        pet: {
            id: ret.pet.id,
            name: ret.pet.name,
            image: {
                thumb: {
                    width: ret.pet.image.thumb.width || 0,
                    height: ret.pet.image.thumb.height || 0,
                    url: ret.pet.image.thumb.url,
                },
                width: ret.pet.image.width || 0,
                height: ret.pet.image.height || 0,
                url: ret.pet.image.url,
            },
            age: ret.pet.age,
            species: ret.pet.species,
            animalSex: ret.pet.animalSex,
            isCastrated: !!ret.pet.isCastrated,
            code: ret.pet.code || 0,
            breed: ret.pet.breed,
            birthdate: new Date(ret.pet.birthdate + "Z"),
            codeFormat: ret.pet.codeFormat,
        },
        clinic: ret.clinic === null || ret.clinic === undefined ? null : {
            id: ret.clinic.id,
            image: {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            phone: ret.clinic.phone,
            showInSite: !!ret.clinic.showInSite,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
            },
            code: ret.clinic.code || 0,
            codeFormat: ret.clinic.codeFormat,
            services: ret.clinic.services === null || ret.clinic.services === undefined ? null : ret.clinic.services,
            createdAt: new Date(ret.clinic.createdAt + "Z"),
            adminUser: ret.clinic.adminUser === null || ret.clinic.adminUser === undefined ? null : {
                name: ret.clinic.adminUser.name,
                documentNumber: ret.clinic.adminUser.documentNumber,
            },
        },
    };
}

export async function finishHospitalization(id: string, progress?: (progress: number) => void): Promise<HospitalizationForClinic> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "finishHospitalization", args, progress});
    return {
        id: ret.id,
        startDate: new Date(ret.startDate + "Z"),
        endDate: ret.endDate === null || ret.endDate === undefined ? null : new Date(ret.endDate + "Z"),
        finishedAt: ret.finishedAt === null || ret.finishedAt === undefined ? null : new Date(ret.finishedAt + "Z"),
        entryTime: ret.entryTime,
        exitTime: ret.exitTime === null || ret.exitTime === undefined ? null : ret.exitTime,
        images: ret.images === null || ret.images === undefined ? null : ret.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        pet: {
            id: ret.pet.id,
            name: ret.pet.name,
            image: {
                thumb: {
                    width: ret.pet.image.thumb.width || 0,
                    height: ret.pet.image.thumb.height || 0,
                    url: ret.pet.image.thumb.url,
                },
                width: ret.pet.image.width || 0,
                height: ret.pet.image.height || 0,
                url: ret.pet.image.url,
            },
            age: ret.pet.age,
            species: ret.pet.species,
            animalSex: ret.pet.animalSex,
            isCastrated: !!ret.pet.isCastrated,
            code: ret.pet.code || 0,
            breed: ret.pet.breed,
            birthdate: new Date(ret.pet.birthdate + "Z"),
            codeFormat: ret.pet.codeFormat,
        },
        clinic: ret.clinic === null || ret.clinic === undefined ? null : {
            id: ret.clinic.id,
            image: {
                thumb: {
                    width: ret.clinic.image.thumb.width || 0,
                    height: ret.clinic.image.thumb.height || 0,
                    url: ret.clinic.image.thumb.url,
                },
                width: ret.clinic.image.width || 0,
                height: ret.clinic.image.height || 0,
                url: ret.clinic.image.url,
            },
            phone: ret.clinic.phone,
            showInSite: !!ret.clinic.showInSite,
            address: {
                zipcode: ret.clinic.address.zipcode,
                street: ret.clinic.address.street,
                streetNumber: ret.clinic.address.streetNumber,
                complementary: ret.clinic.address.complementary,
                neighborhood: ret.clinic.address.neighborhood,
                city: ret.clinic.address.city,
                state: ret.clinic.address.state,
            },
            code: ret.clinic.code || 0,
            codeFormat: ret.clinic.codeFormat,
            services: ret.clinic.services === null || ret.clinic.services === undefined ? null : ret.clinic.services,
            createdAt: new Date(ret.clinic.createdAt + "Z"),
            adminUser: ret.clinic.adminUser === null || ret.clinic.adminUser === undefined ? null : {
                name: ret.clinic.adminUser.name,
                documentNumber: ret.clinic.adminUser.documentNumber,
            },
        },
    };
}

export async function getAllPets(pageOffset: number, petName: string, progress?: (progress: number) => void): Promise<Pet[]> {
    const args = {
        pageOffset: pageOffset || 0,
        petName: petName,
    };
    const ret = await makeRequest({name: "getAllPets", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        image: {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        age: e.age,
        species: e.species,
        animalSex: e.animalSex,
        isCastrated: !!e.isCastrated,
        code: e.code || 0,
        breed: e.breed,
        birthdate: new Date(e.birthdate + "Z"),
        codeFormat: e.codeFormat,
    }));
}

export async function getPetById(id: string, progress?: (progress: number) => void): Promise<Pet> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getPetById", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        age: ret.age,
        species: ret.species,
        animalSex: ret.animalSex,
        isCastrated: !!ret.isCastrated,
        code: ret.code || 0,
        breed: ret.breed,
        birthdate: new Date(ret.birthdate + "Z"),
        codeFormat: ret.codeFormat,
    };
}

export async function getAllPetsForClinic(clinicId: string, progress?: (progress: number) => void): Promise<Pet[]> {
    const args = {
        clinicId: clinicId,
    };
    const ret = await makeRequest({name: "getAllPetsForClinic", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        image: {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        age: e.age,
        species: e.species,
        animalSex: e.animalSex,
        isCastrated: !!e.isCastrated,
        code: e.code || 0,
        breed: e.breed,
        birthdate: new Date(e.birthdate + "Z"),
        codeFormat: e.codeFormat,
    }));
}

export async function getAllPetsForAdminClinicAndAutocomplete(clinicId: string, name: string, progress?: (progress: number) => void): Promise<Pet[]> {
    const args = {
        clinicId: clinicId,
        name: name,
    };
    const ret = await makeRequest({name: "getAllPetsForAdminClinicAndAutocomplete", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        image: {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        age: e.age,
        species: e.species,
        animalSex: e.animalSex,
        isCastrated: !!e.isCastrated,
        code: e.code || 0,
        breed: e.breed,
        birthdate: new Date(e.birthdate + "Z"),
        codeFormat: e.codeFormat,
    }));
}

export async function deletePetById(id: string, progress?: (progress: number) => void): Promise<Pet> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deletePetById", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        age: ret.age,
        species: ret.species,
        animalSex: ret.animalSex,
        isCastrated: !!ret.isCastrated,
        code: ret.code || 0,
        breed: ret.breed,
        birthdate: new Date(ret.birthdate + "Z"),
        codeFormat: ret.codeFormat,
    };
}

export async function createPet(newPet: NewPet, progress?: (progress: number) => void): Promise<Pet> {
    const args = {
        newPet: {
            name: newPet.name,
            image: {
                bytes: newPet.image.bytes === null || newPet.image.bytes === undefined ? null : newPet.image.bytes.toString("base64"),
                image: newPet.image.image === null || newPet.image.image === undefined ? null : {
                    thumb: {
                        width: newPet.image.image.thumb.width || 0,
                        height: newPet.image.image.thumb.height || 0,
                        url: newPet.image.image.thumb.url,
                    },
                    width: newPet.image.image.width || 0,
                    height: newPet.image.image.height || 0,
                    url: newPet.image.image.url,
                },
            },
            age: newPet.age,
            species: newPet.species,
            animalSex: newPet.animalSex,
            isCastrated: !!newPet.isCastrated,
            breed: newPet.breed,
            birthdate: (typeof newPet.birthdate === "string" && (newPet.birthdate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newPet.birthdate as any).replace("Z", "") : newPet.birthdate.toISOString().replace("Z", "")),
            userId: newPet.userId,
        },
    };
    const ret = await makeRequest({name: "createPet", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        age: ret.age,
        species: ret.species,
        animalSex: ret.animalSex,
        isCastrated: !!ret.isCastrated,
        code: ret.code || 0,
        breed: ret.breed,
        birthdate: new Date(ret.birthdate + "Z"),
        codeFormat: ret.codeFormat,
    };
}

export async function editPet(id: string, editPet: EditPet, progress?: (progress: number) => void): Promise<Pet> {
    const args = {
        id: id,
        editPet: {
            name: editPet.name,
            image: {
                bytes: editPet.image.bytes === null || editPet.image.bytes === undefined ? null : editPet.image.bytes.toString("base64"),
                image: editPet.image.image === null || editPet.image.image === undefined ? null : {
                    thumb: {
                        width: editPet.image.image.thumb.width || 0,
                        height: editPet.image.image.thumb.height || 0,
                        url: editPet.image.image.thumb.url,
                    },
                    width: editPet.image.image.width || 0,
                    height: editPet.image.image.height || 0,
                    url: editPet.image.image.url,
                },
            },
            age: editPet.age,
            species: editPet.species,
            animalSex: editPet.animalSex,
            isCastrated: !!editPet.isCastrated,
            breed: editPet.breed,
            birthdate: (typeof editPet.birthdate === "string" && (editPet.birthdate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editPet.birthdate as any).replace("Z", "") : editPet.birthdate.toISOString().replace("Z", "")),
        },
    };
    const ret = await makeRequest({name: "editPet", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        age: ret.age,
        species: ret.species,
        animalSex: ret.animalSex,
        isCastrated: !!ret.isCastrated,
        code: ret.code || 0,
        breed: ret.breed,
        birthdate: new Date(ret.birthdate + "Z"),
        codeFormat: ret.codeFormat,
    };
}

export async function getAllPosts(pageOffset: number, progress?: (progress: number) => void): Promise<PostWithoutDescription[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllPosts", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        image: {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        title: e.title,
        slug: e.slug === null || e.slug === undefined ? null : e.slug,
        adminUser: {
            id: e.adminUser.id,
            clinic: e.adminUser.clinic === null || e.adminUser.clinic === undefined ? null : {
                id: e.adminUser.clinic.id,
                image: {
                    thumb: {
                        width: e.adminUser.clinic.image.thumb.width || 0,
                        height: e.adminUser.clinic.image.thumb.height || 0,
                        url: e.adminUser.clinic.image.thumb.url,
                    },
                    width: e.adminUser.clinic.image.width || 0,
                    height: e.adminUser.clinic.image.height || 0,
                    url: e.adminUser.clinic.image.url,
                },
                phone: e.adminUser.clinic.phone,
                showInSite: !!e.adminUser.clinic.showInSite,
                address: {
                    zipcode: e.adminUser.clinic.address.zipcode,
                    street: e.adminUser.clinic.address.street,
                    streetNumber: e.adminUser.clinic.address.streetNumber,
                    complementary: e.adminUser.clinic.address.complementary,
                    neighborhood: e.adminUser.clinic.address.neighborhood,
                    city: e.adminUser.clinic.address.city,
                    state: e.adminUser.clinic.address.state,
                },
                code: e.adminUser.clinic.code || 0,
                codeFormat: e.adminUser.clinic.codeFormat,
                services: e.adminUser.clinic.services === null || e.adminUser.clinic.services === undefined ? null : e.adminUser.clinic.services,
                createdAt: new Date(e.adminUser.clinic.createdAt + "Z"),
                adminUser: e.adminUser.clinic.adminUser === null || e.adminUser.clinic.adminUser === undefined ? null : {
                    name: e.adminUser.clinic.adminUser.name,
                    documentNumber: e.adminUser.clinic.adminUser.documentNumber,
                },
            },
            type: e.adminUser.type,
            createdAt: new Date(e.adminUser.createdAt + "Z"),
            name: e.adminUser.name,
            email: e.adminUser.email,
            documentNumber: e.adminUser.documentNumber,
        },
        review: e.review === null || e.review === undefined ? null : e.review,
        approvedAt: e.approvedAt === null || e.approvedAt === undefined ? null : new Date(e.approvedAt + "Z"),
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getPostById(id: string, progress?: (progress: number) => void): Promise<Post> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getPostById", args, progress});
    return {
        id: ret.id,
        image: {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        title: ret.title,
        slug: ret.slug === null || ret.slug === undefined ? null : ret.slug,
        description: ret.description,
        adminUser: {
            id: ret.adminUser.id,
            clinic: ret.adminUser.clinic === null || ret.adminUser.clinic === undefined ? null : {
                id: ret.adminUser.clinic.id,
                image: {
                    thumb: {
                        width: ret.adminUser.clinic.image.thumb.width || 0,
                        height: ret.adminUser.clinic.image.thumb.height || 0,
                        url: ret.adminUser.clinic.image.thumb.url,
                    },
                    width: ret.adminUser.clinic.image.width || 0,
                    height: ret.adminUser.clinic.image.height || 0,
                    url: ret.adminUser.clinic.image.url,
                },
                phone: ret.adminUser.clinic.phone,
                showInSite: !!ret.adminUser.clinic.showInSite,
                address: {
                    zipcode: ret.adminUser.clinic.address.zipcode,
                    street: ret.adminUser.clinic.address.street,
                    streetNumber: ret.adminUser.clinic.address.streetNumber,
                    complementary: ret.adminUser.clinic.address.complementary,
                    neighborhood: ret.adminUser.clinic.address.neighborhood,
                    city: ret.adminUser.clinic.address.city,
                    state: ret.adminUser.clinic.address.state,
                },
                code: ret.adminUser.clinic.code || 0,
                codeFormat: ret.adminUser.clinic.codeFormat,
                services: ret.adminUser.clinic.services === null || ret.adminUser.clinic.services === undefined ? null : ret.adminUser.clinic.services,
                createdAt: new Date(ret.adminUser.clinic.createdAt + "Z"),
                adminUser: ret.adminUser.clinic.adminUser === null || ret.adminUser.clinic.adminUser === undefined ? null : {
                    name: ret.adminUser.clinic.adminUser.name,
                    documentNumber: ret.adminUser.clinic.adminUser.documentNumber,
                },
            },
            type: ret.adminUser.type,
            createdAt: new Date(ret.adminUser.createdAt + "Z"),
            name: ret.adminUser.name,
            email: ret.adminUser.email,
            documentNumber: ret.adminUser.documentNumber,
        },
        review: ret.review === null || ret.review === undefined ? null : ret.review,
        createdAt: new Date(ret.createdAt + "Z"),
        approvedAt: ret.approvedAt === null || ret.approvedAt === undefined ? null : new Date(ret.approvedAt + "Z"),
    };
}

export async function getAllPendingPosts(pageOffset: number, progress?: (progress: number) => void): Promise<PostWithoutDescription[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllPendingPosts", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        image: {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        title: e.title,
        slug: e.slug === null || e.slug === undefined ? null : e.slug,
        adminUser: {
            id: e.adminUser.id,
            clinic: e.adminUser.clinic === null || e.adminUser.clinic === undefined ? null : {
                id: e.adminUser.clinic.id,
                image: {
                    thumb: {
                        width: e.adminUser.clinic.image.thumb.width || 0,
                        height: e.adminUser.clinic.image.thumb.height || 0,
                        url: e.adminUser.clinic.image.thumb.url,
                    },
                    width: e.adminUser.clinic.image.width || 0,
                    height: e.adminUser.clinic.image.height || 0,
                    url: e.adminUser.clinic.image.url,
                },
                phone: e.adminUser.clinic.phone,
                showInSite: !!e.adminUser.clinic.showInSite,
                address: {
                    zipcode: e.adminUser.clinic.address.zipcode,
                    street: e.adminUser.clinic.address.street,
                    streetNumber: e.adminUser.clinic.address.streetNumber,
                    complementary: e.adminUser.clinic.address.complementary,
                    neighborhood: e.adminUser.clinic.address.neighborhood,
                    city: e.adminUser.clinic.address.city,
                    state: e.adminUser.clinic.address.state,
                },
                code: e.adminUser.clinic.code || 0,
                codeFormat: e.adminUser.clinic.codeFormat,
                services: e.adminUser.clinic.services === null || e.adminUser.clinic.services === undefined ? null : e.adminUser.clinic.services,
                createdAt: new Date(e.adminUser.clinic.createdAt + "Z"),
                adminUser: e.adminUser.clinic.adminUser === null || e.adminUser.clinic.adminUser === undefined ? null : {
                    name: e.adminUser.clinic.adminUser.name,
                    documentNumber: e.adminUser.clinic.adminUser.documentNumber,
                },
            },
            type: e.adminUser.type,
            createdAt: new Date(e.adminUser.createdAt + "Z"),
            name: e.adminUser.name,
            email: e.adminUser.email,
            documentNumber: e.adminUser.documentNumber,
        },
        review: e.review === null || e.review === undefined ? null : e.review,
        approvedAt: e.approvedAt === null || e.approvedAt === undefined ? null : new Date(e.approvedAt + "Z"),
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getAllPendingPostsForClinicAdminUser(pageOffset: number, progress?: (progress: number) => void): Promise<PostWithoutDescription[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllPendingPostsForClinicAdminUser", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        image: {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        title: e.title,
        slug: e.slug === null || e.slug === undefined ? null : e.slug,
        adminUser: {
            id: e.adminUser.id,
            clinic: e.adminUser.clinic === null || e.adminUser.clinic === undefined ? null : {
                id: e.adminUser.clinic.id,
                image: {
                    thumb: {
                        width: e.adminUser.clinic.image.thumb.width || 0,
                        height: e.adminUser.clinic.image.thumb.height || 0,
                        url: e.adminUser.clinic.image.thumb.url,
                    },
                    width: e.adminUser.clinic.image.width || 0,
                    height: e.adminUser.clinic.image.height || 0,
                    url: e.adminUser.clinic.image.url,
                },
                phone: e.adminUser.clinic.phone,
                showInSite: !!e.adminUser.clinic.showInSite,
                address: {
                    zipcode: e.adminUser.clinic.address.zipcode,
                    street: e.adminUser.clinic.address.street,
                    streetNumber: e.adminUser.clinic.address.streetNumber,
                    complementary: e.adminUser.clinic.address.complementary,
                    neighborhood: e.adminUser.clinic.address.neighborhood,
                    city: e.adminUser.clinic.address.city,
                    state: e.adminUser.clinic.address.state,
                },
                code: e.adminUser.clinic.code || 0,
                codeFormat: e.adminUser.clinic.codeFormat,
                services: e.adminUser.clinic.services === null || e.adminUser.clinic.services === undefined ? null : e.adminUser.clinic.services,
                createdAt: new Date(e.adminUser.clinic.createdAt + "Z"),
                adminUser: e.adminUser.clinic.adminUser === null || e.adminUser.clinic.adminUser === undefined ? null : {
                    name: e.adminUser.clinic.adminUser.name,
                    documentNumber: e.adminUser.clinic.adminUser.documentNumber,
                },
            },
            type: e.adminUser.type,
            createdAt: new Date(e.adminUser.createdAt + "Z"),
            name: e.adminUser.name,
            email: e.adminUser.email,
            documentNumber: e.adminUser.documentNumber,
        },
        review: e.review === null || e.review === undefined ? null : e.review,
        approvedAt: e.approvedAt === null || e.approvedAt === undefined ? null : new Date(e.approvedAt + "Z"),
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function createPostForAdminMaster(newPost: NewPost, progress?: (progress: number) => void): Promise<Post> {
    const args = {
        newPost: {
            image: {
                bytes: newPost.image.bytes === null || newPost.image.bytes === undefined ? null : newPost.image.bytes.toString("base64"),
                image: newPost.image.image === null || newPost.image.image === undefined ? null : {
                    thumb: {
                        width: newPost.image.image.thumb.width || 0,
                        height: newPost.image.image.thumb.height || 0,
                        url: newPost.image.image.thumb.url,
                    },
                    width: newPost.image.image.width || 0,
                    height: newPost.image.image.height || 0,
                    url: newPost.image.image.url,
                },
            },
            title: newPost.title,
            slug: newPost.slug === null || newPost.slug === undefined ? null : newPost.slug,
            description: newPost.description,
            review: newPost.review === null || newPost.review === undefined ? null : newPost.review,
        },
    };
    const ret = await makeRequest({name: "createPostForAdminMaster", args, progress});
    return {
        id: ret.id,
        image: {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        title: ret.title,
        slug: ret.slug === null || ret.slug === undefined ? null : ret.slug,
        description: ret.description,
        adminUser: {
            id: ret.adminUser.id,
            clinic: ret.adminUser.clinic === null || ret.adminUser.clinic === undefined ? null : {
                id: ret.adminUser.clinic.id,
                image: {
                    thumb: {
                        width: ret.adminUser.clinic.image.thumb.width || 0,
                        height: ret.adminUser.clinic.image.thumb.height || 0,
                        url: ret.adminUser.clinic.image.thumb.url,
                    },
                    width: ret.adminUser.clinic.image.width || 0,
                    height: ret.adminUser.clinic.image.height || 0,
                    url: ret.adminUser.clinic.image.url,
                },
                phone: ret.adminUser.clinic.phone,
                showInSite: !!ret.adminUser.clinic.showInSite,
                address: {
                    zipcode: ret.adminUser.clinic.address.zipcode,
                    street: ret.adminUser.clinic.address.street,
                    streetNumber: ret.adminUser.clinic.address.streetNumber,
                    complementary: ret.adminUser.clinic.address.complementary,
                    neighborhood: ret.adminUser.clinic.address.neighborhood,
                    city: ret.adminUser.clinic.address.city,
                    state: ret.adminUser.clinic.address.state,
                },
                code: ret.adminUser.clinic.code || 0,
                codeFormat: ret.adminUser.clinic.codeFormat,
                services: ret.adminUser.clinic.services === null || ret.adminUser.clinic.services === undefined ? null : ret.adminUser.clinic.services,
                createdAt: new Date(ret.adminUser.clinic.createdAt + "Z"),
                adminUser: ret.adminUser.clinic.adminUser === null || ret.adminUser.clinic.adminUser === undefined ? null : {
                    name: ret.adminUser.clinic.adminUser.name,
                    documentNumber: ret.adminUser.clinic.adminUser.documentNumber,
                },
            },
            type: ret.adminUser.type,
            createdAt: new Date(ret.adminUser.createdAt + "Z"),
            name: ret.adminUser.name,
            email: ret.adminUser.email,
            documentNumber: ret.adminUser.documentNumber,
        },
        review: ret.review === null || ret.review === undefined ? null : ret.review,
        createdAt: new Date(ret.createdAt + "Z"),
        approvedAt: ret.approvedAt === null || ret.approvedAt === undefined ? null : new Date(ret.approvedAt + "Z"),
    };
}

export async function createPostForAdminMarketing(newPost: NewPost, progress?: (progress: number) => void): Promise<Post> {
    const args = {
        newPost: {
            image: {
                bytes: newPost.image.bytes === null || newPost.image.bytes === undefined ? null : newPost.image.bytes.toString("base64"),
                image: newPost.image.image === null || newPost.image.image === undefined ? null : {
                    thumb: {
                        width: newPost.image.image.thumb.width || 0,
                        height: newPost.image.image.thumb.height || 0,
                        url: newPost.image.image.thumb.url,
                    },
                    width: newPost.image.image.width || 0,
                    height: newPost.image.image.height || 0,
                    url: newPost.image.image.url,
                },
            },
            title: newPost.title,
            slug: newPost.slug === null || newPost.slug === undefined ? null : newPost.slug,
            description: newPost.description,
            review: newPost.review === null || newPost.review === undefined ? null : newPost.review,
        },
    };
    const ret = await makeRequest({name: "createPostForAdminMarketing", args, progress});
    return {
        id: ret.id,
        image: {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        title: ret.title,
        slug: ret.slug === null || ret.slug === undefined ? null : ret.slug,
        description: ret.description,
        adminUser: {
            id: ret.adminUser.id,
            clinic: ret.adminUser.clinic === null || ret.adminUser.clinic === undefined ? null : {
                id: ret.adminUser.clinic.id,
                image: {
                    thumb: {
                        width: ret.adminUser.clinic.image.thumb.width || 0,
                        height: ret.adminUser.clinic.image.thumb.height || 0,
                        url: ret.adminUser.clinic.image.thumb.url,
                    },
                    width: ret.adminUser.clinic.image.width || 0,
                    height: ret.adminUser.clinic.image.height || 0,
                    url: ret.adminUser.clinic.image.url,
                },
                phone: ret.adminUser.clinic.phone,
                showInSite: !!ret.adminUser.clinic.showInSite,
                address: {
                    zipcode: ret.adminUser.clinic.address.zipcode,
                    street: ret.adminUser.clinic.address.street,
                    streetNumber: ret.adminUser.clinic.address.streetNumber,
                    complementary: ret.adminUser.clinic.address.complementary,
                    neighborhood: ret.adminUser.clinic.address.neighborhood,
                    city: ret.adminUser.clinic.address.city,
                    state: ret.adminUser.clinic.address.state,
                },
                code: ret.adminUser.clinic.code || 0,
                codeFormat: ret.adminUser.clinic.codeFormat,
                services: ret.adminUser.clinic.services === null || ret.adminUser.clinic.services === undefined ? null : ret.adminUser.clinic.services,
                createdAt: new Date(ret.adminUser.clinic.createdAt + "Z"),
                adminUser: ret.adminUser.clinic.adminUser === null || ret.adminUser.clinic.adminUser === undefined ? null : {
                    name: ret.adminUser.clinic.adminUser.name,
                    documentNumber: ret.adminUser.clinic.adminUser.documentNumber,
                },
            },
            type: ret.adminUser.type,
            createdAt: new Date(ret.adminUser.createdAt + "Z"),
            name: ret.adminUser.name,
            email: ret.adminUser.email,
            documentNumber: ret.adminUser.documentNumber,
        },
        review: ret.review === null || ret.review === undefined ? null : ret.review,
        createdAt: new Date(ret.createdAt + "Z"),
        approvedAt: ret.approvedAt === null || ret.approvedAt === undefined ? null : new Date(ret.approvedAt + "Z"),
    };
}

export async function editPost(id: string, editPost: NewPost, progress?: (progress: number) => void): Promise<Post> {
    const args = {
        id: id,
        editPost: {
            image: {
                bytes: editPost.image.bytes === null || editPost.image.bytes === undefined ? null : editPost.image.bytes.toString("base64"),
                image: editPost.image.image === null || editPost.image.image === undefined ? null : {
                    thumb: {
                        width: editPost.image.image.thumb.width || 0,
                        height: editPost.image.image.thumb.height || 0,
                        url: editPost.image.image.thumb.url,
                    },
                    width: editPost.image.image.width || 0,
                    height: editPost.image.image.height || 0,
                    url: editPost.image.image.url,
                },
            },
            title: editPost.title,
            slug: editPost.slug === null || editPost.slug === undefined ? null : editPost.slug,
            description: editPost.description,
            review: editPost.review === null || editPost.review === undefined ? null : editPost.review,
        },
    };
    const ret = await makeRequest({name: "editPost", args, progress});
    return {
        id: ret.id,
        image: {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        title: ret.title,
        slug: ret.slug === null || ret.slug === undefined ? null : ret.slug,
        description: ret.description,
        adminUser: {
            id: ret.adminUser.id,
            clinic: ret.adminUser.clinic === null || ret.adminUser.clinic === undefined ? null : {
                id: ret.adminUser.clinic.id,
                image: {
                    thumb: {
                        width: ret.adminUser.clinic.image.thumb.width || 0,
                        height: ret.adminUser.clinic.image.thumb.height || 0,
                        url: ret.adminUser.clinic.image.thumb.url,
                    },
                    width: ret.adminUser.clinic.image.width || 0,
                    height: ret.adminUser.clinic.image.height || 0,
                    url: ret.adminUser.clinic.image.url,
                },
                phone: ret.adminUser.clinic.phone,
                showInSite: !!ret.adminUser.clinic.showInSite,
                address: {
                    zipcode: ret.adminUser.clinic.address.zipcode,
                    street: ret.adminUser.clinic.address.street,
                    streetNumber: ret.adminUser.clinic.address.streetNumber,
                    complementary: ret.adminUser.clinic.address.complementary,
                    neighborhood: ret.adminUser.clinic.address.neighborhood,
                    city: ret.adminUser.clinic.address.city,
                    state: ret.adminUser.clinic.address.state,
                },
                code: ret.adminUser.clinic.code || 0,
                codeFormat: ret.adminUser.clinic.codeFormat,
                services: ret.adminUser.clinic.services === null || ret.adminUser.clinic.services === undefined ? null : ret.adminUser.clinic.services,
                createdAt: new Date(ret.adminUser.clinic.createdAt + "Z"),
                adminUser: ret.adminUser.clinic.adminUser === null || ret.adminUser.clinic.adminUser === undefined ? null : {
                    name: ret.adminUser.clinic.adminUser.name,
                    documentNumber: ret.adminUser.clinic.adminUser.documentNumber,
                },
            },
            type: ret.adminUser.type,
            createdAt: new Date(ret.adminUser.createdAt + "Z"),
            name: ret.adminUser.name,
            email: ret.adminUser.email,
            documentNumber: ret.adminUser.documentNumber,
        },
        review: ret.review === null || ret.review === undefined ? null : ret.review,
        createdAt: new Date(ret.createdAt + "Z"),
        approvedAt: ret.approvedAt === null || ret.approvedAt === undefined ? null : new Date(ret.approvedAt + "Z"),
    };
}

export async function deletePost(id: string, progress?: (progress: number) => void): Promise<Post> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deletePost", args, progress});
    return {
        id: ret.id,
        image: {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        title: ret.title,
        slug: ret.slug === null || ret.slug === undefined ? null : ret.slug,
        description: ret.description,
        adminUser: {
            id: ret.adminUser.id,
            clinic: ret.adminUser.clinic === null || ret.adminUser.clinic === undefined ? null : {
                id: ret.adminUser.clinic.id,
                image: {
                    thumb: {
                        width: ret.adminUser.clinic.image.thumb.width || 0,
                        height: ret.adminUser.clinic.image.thumb.height || 0,
                        url: ret.adminUser.clinic.image.thumb.url,
                    },
                    width: ret.adminUser.clinic.image.width || 0,
                    height: ret.adminUser.clinic.image.height || 0,
                    url: ret.adminUser.clinic.image.url,
                },
                phone: ret.adminUser.clinic.phone,
                showInSite: !!ret.adminUser.clinic.showInSite,
                address: {
                    zipcode: ret.adminUser.clinic.address.zipcode,
                    street: ret.adminUser.clinic.address.street,
                    streetNumber: ret.adminUser.clinic.address.streetNumber,
                    complementary: ret.adminUser.clinic.address.complementary,
                    neighborhood: ret.adminUser.clinic.address.neighborhood,
                    city: ret.adminUser.clinic.address.city,
                    state: ret.adminUser.clinic.address.state,
                },
                code: ret.adminUser.clinic.code || 0,
                codeFormat: ret.adminUser.clinic.codeFormat,
                services: ret.adminUser.clinic.services === null || ret.adminUser.clinic.services === undefined ? null : ret.adminUser.clinic.services,
                createdAt: new Date(ret.adminUser.clinic.createdAt + "Z"),
                adminUser: ret.adminUser.clinic.adminUser === null || ret.adminUser.clinic.adminUser === undefined ? null : {
                    name: ret.adminUser.clinic.adminUser.name,
                    documentNumber: ret.adminUser.clinic.adminUser.documentNumber,
                },
            },
            type: ret.adminUser.type,
            createdAt: new Date(ret.adminUser.createdAt + "Z"),
            name: ret.adminUser.name,
            email: ret.adminUser.email,
            documentNumber: ret.adminUser.documentNumber,
        },
        review: ret.review === null || ret.review === undefined ? null : ret.review,
        createdAt: new Date(ret.createdAt + "Z"),
        approvedAt: ret.approvedAt === null || ret.approvedAt === undefined ? null : new Date(ret.approvedAt + "Z"),
    };
}

export async function reprovePost(id: string, review: string, progress?: (progress: number) => void): Promise<Post> {
    const args = {
        id: id,
        review: review,
    };
    const ret = await makeRequest({name: "reprovePost", args, progress});
    return {
        id: ret.id,
        image: {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        title: ret.title,
        slug: ret.slug === null || ret.slug === undefined ? null : ret.slug,
        description: ret.description,
        adminUser: {
            id: ret.adminUser.id,
            clinic: ret.adminUser.clinic === null || ret.adminUser.clinic === undefined ? null : {
                id: ret.adminUser.clinic.id,
                image: {
                    thumb: {
                        width: ret.adminUser.clinic.image.thumb.width || 0,
                        height: ret.adminUser.clinic.image.thumb.height || 0,
                        url: ret.adminUser.clinic.image.thumb.url,
                    },
                    width: ret.adminUser.clinic.image.width || 0,
                    height: ret.adminUser.clinic.image.height || 0,
                    url: ret.adminUser.clinic.image.url,
                },
                phone: ret.adminUser.clinic.phone,
                showInSite: !!ret.adminUser.clinic.showInSite,
                address: {
                    zipcode: ret.adminUser.clinic.address.zipcode,
                    street: ret.adminUser.clinic.address.street,
                    streetNumber: ret.adminUser.clinic.address.streetNumber,
                    complementary: ret.adminUser.clinic.address.complementary,
                    neighborhood: ret.adminUser.clinic.address.neighborhood,
                    city: ret.adminUser.clinic.address.city,
                    state: ret.adminUser.clinic.address.state,
                },
                code: ret.adminUser.clinic.code || 0,
                codeFormat: ret.adminUser.clinic.codeFormat,
                services: ret.adminUser.clinic.services === null || ret.adminUser.clinic.services === undefined ? null : ret.adminUser.clinic.services,
                createdAt: new Date(ret.adminUser.clinic.createdAt + "Z"),
                adminUser: ret.adminUser.clinic.adminUser === null || ret.adminUser.clinic.adminUser === undefined ? null : {
                    name: ret.adminUser.clinic.adminUser.name,
                    documentNumber: ret.adminUser.clinic.adminUser.documentNumber,
                },
            },
            type: ret.adminUser.type,
            createdAt: new Date(ret.adminUser.createdAt + "Z"),
            name: ret.adminUser.name,
            email: ret.adminUser.email,
            documentNumber: ret.adminUser.documentNumber,
        },
        review: ret.review === null || ret.review === undefined ? null : ret.review,
        createdAt: new Date(ret.createdAt + "Z"),
        approvedAt: ret.approvedAt === null || ret.approvedAt === undefined ? null : new Date(ret.approvedAt + "Z"),
    };
}

export async function approvePost(id: string, progress?: (progress: number) => void): Promise<Post> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "approvePost", args, progress});
    return {
        id: ret.id,
        image: {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        title: ret.title,
        slug: ret.slug === null || ret.slug === undefined ? null : ret.slug,
        description: ret.description,
        adminUser: {
            id: ret.adminUser.id,
            clinic: ret.adminUser.clinic === null || ret.adminUser.clinic === undefined ? null : {
                id: ret.adminUser.clinic.id,
                image: {
                    thumb: {
                        width: ret.adminUser.clinic.image.thumb.width || 0,
                        height: ret.adminUser.clinic.image.thumb.height || 0,
                        url: ret.adminUser.clinic.image.thumb.url,
                    },
                    width: ret.adminUser.clinic.image.width || 0,
                    height: ret.adminUser.clinic.image.height || 0,
                    url: ret.adminUser.clinic.image.url,
                },
                phone: ret.adminUser.clinic.phone,
                showInSite: !!ret.adminUser.clinic.showInSite,
                address: {
                    zipcode: ret.adminUser.clinic.address.zipcode,
                    street: ret.adminUser.clinic.address.street,
                    streetNumber: ret.adminUser.clinic.address.streetNumber,
                    complementary: ret.adminUser.clinic.address.complementary,
                    neighborhood: ret.adminUser.clinic.address.neighborhood,
                    city: ret.adminUser.clinic.address.city,
                    state: ret.adminUser.clinic.address.state,
                },
                code: ret.adminUser.clinic.code || 0,
                codeFormat: ret.adminUser.clinic.codeFormat,
                services: ret.adminUser.clinic.services === null || ret.adminUser.clinic.services === undefined ? null : ret.adminUser.clinic.services,
                createdAt: new Date(ret.adminUser.clinic.createdAt + "Z"),
                adminUser: ret.adminUser.clinic.adminUser === null || ret.adminUser.clinic.adminUser === undefined ? null : {
                    name: ret.adminUser.clinic.adminUser.name,
                    documentNumber: ret.adminUser.clinic.adminUser.documentNumber,
                },
            },
            type: ret.adminUser.type,
            createdAt: new Date(ret.adminUser.createdAt + "Z"),
            name: ret.adminUser.name,
            email: ret.adminUser.email,
            documentNumber: ret.adminUser.documentNumber,
        },
        review: ret.review === null || ret.review === undefined ? null : ret.review,
        createdAt: new Date(ret.createdAt + "Z"),
        approvedAt: ret.approvedAt === null || ret.approvedAt === undefined ? null : new Date(ret.approvedAt + "Z"),
    };
}

export async function createPostForClinicAdmin(newPost: NewPost, progress?: (progress: number) => void): Promise<Post> {
    const args = {
        newPost: {
            image: {
                bytes: newPost.image.bytes === null || newPost.image.bytes === undefined ? null : newPost.image.bytes.toString("base64"),
                image: newPost.image.image === null || newPost.image.image === undefined ? null : {
                    thumb: {
                        width: newPost.image.image.thumb.width || 0,
                        height: newPost.image.image.thumb.height || 0,
                        url: newPost.image.image.thumb.url,
                    },
                    width: newPost.image.image.width || 0,
                    height: newPost.image.image.height || 0,
                    url: newPost.image.image.url,
                },
            },
            title: newPost.title,
            slug: newPost.slug === null || newPost.slug === undefined ? null : newPost.slug,
            description: newPost.description,
            review: newPost.review === null || newPost.review === undefined ? null : newPost.review,
        },
    };
    const ret = await makeRequest({name: "createPostForClinicAdmin", args, progress});
    return {
        id: ret.id,
        image: {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        title: ret.title,
        slug: ret.slug === null || ret.slug === undefined ? null : ret.slug,
        description: ret.description,
        adminUser: {
            id: ret.adminUser.id,
            clinic: ret.adminUser.clinic === null || ret.adminUser.clinic === undefined ? null : {
                id: ret.adminUser.clinic.id,
                image: {
                    thumb: {
                        width: ret.adminUser.clinic.image.thumb.width || 0,
                        height: ret.adminUser.clinic.image.thumb.height || 0,
                        url: ret.adminUser.clinic.image.thumb.url,
                    },
                    width: ret.adminUser.clinic.image.width || 0,
                    height: ret.adminUser.clinic.image.height || 0,
                    url: ret.adminUser.clinic.image.url,
                },
                phone: ret.adminUser.clinic.phone,
                showInSite: !!ret.adminUser.clinic.showInSite,
                address: {
                    zipcode: ret.adminUser.clinic.address.zipcode,
                    street: ret.adminUser.clinic.address.street,
                    streetNumber: ret.adminUser.clinic.address.streetNumber,
                    complementary: ret.adminUser.clinic.address.complementary,
                    neighborhood: ret.adminUser.clinic.address.neighborhood,
                    city: ret.adminUser.clinic.address.city,
                    state: ret.adminUser.clinic.address.state,
                },
                code: ret.adminUser.clinic.code || 0,
                codeFormat: ret.adminUser.clinic.codeFormat,
                services: ret.adminUser.clinic.services === null || ret.adminUser.clinic.services === undefined ? null : ret.adminUser.clinic.services,
                createdAt: new Date(ret.adminUser.clinic.createdAt + "Z"),
                adminUser: ret.adminUser.clinic.adminUser === null || ret.adminUser.clinic.adminUser === undefined ? null : {
                    name: ret.adminUser.clinic.adminUser.name,
                    documentNumber: ret.adminUser.clinic.adminUser.documentNumber,
                },
            },
            type: ret.adminUser.type,
            createdAt: new Date(ret.adminUser.createdAt + "Z"),
            name: ret.adminUser.name,
            email: ret.adminUser.email,
            documentNumber: ret.adminUser.documentNumber,
        },
        review: ret.review === null || ret.review === undefined ? null : ret.review,
        createdAt: new Date(ret.createdAt + "Z"),
        approvedAt: ret.approvedAt === null || ret.approvedAt === undefined ? null : new Date(ret.approvedAt + "Z"),
    };
}

export async function getAllPostsForClinicAdminUser(pageOffset: number, progress?: (progress: number) => void): Promise<PostWithoutDescription[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllPostsForClinicAdminUser", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        image: {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        title: e.title,
        slug: e.slug === null || e.slug === undefined ? null : e.slug,
        adminUser: {
            id: e.adminUser.id,
            clinic: e.adminUser.clinic === null || e.adminUser.clinic === undefined ? null : {
                id: e.adminUser.clinic.id,
                image: {
                    thumb: {
                        width: e.adminUser.clinic.image.thumb.width || 0,
                        height: e.adminUser.clinic.image.thumb.height || 0,
                        url: e.adminUser.clinic.image.thumb.url,
                    },
                    width: e.adminUser.clinic.image.width || 0,
                    height: e.adminUser.clinic.image.height || 0,
                    url: e.adminUser.clinic.image.url,
                },
                phone: e.adminUser.clinic.phone,
                showInSite: !!e.adminUser.clinic.showInSite,
                address: {
                    zipcode: e.adminUser.clinic.address.zipcode,
                    street: e.adminUser.clinic.address.street,
                    streetNumber: e.adminUser.clinic.address.streetNumber,
                    complementary: e.adminUser.clinic.address.complementary,
                    neighborhood: e.adminUser.clinic.address.neighborhood,
                    city: e.adminUser.clinic.address.city,
                    state: e.adminUser.clinic.address.state,
                },
                code: e.adminUser.clinic.code || 0,
                codeFormat: e.adminUser.clinic.codeFormat,
                services: e.adminUser.clinic.services === null || e.adminUser.clinic.services === undefined ? null : e.adminUser.clinic.services,
                createdAt: new Date(e.adminUser.clinic.createdAt + "Z"),
                adminUser: e.adminUser.clinic.adminUser === null || e.adminUser.clinic.adminUser === undefined ? null : {
                    name: e.adminUser.clinic.adminUser.name,
                    documentNumber: e.adminUser.clinic.adminUser.documentNumber,
                },
            },
            type: e.adminUser.type,
            createdAt: new Date(e.adminUser.createdAt + "Z"),
            name: e.adminUser.name,
            email: e.adminUser.email,
            documentNumber: e.adminUser.documentNumber,
        },
        review: e.review === null || e.review === undefined ? null : e.review,
        approvedAt: e.approvedAt === null || e.approvedAt === undefined ? null : new Date(e.approvedAt + "Z"),
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getPostByIdForClinicAdminUser(id: string, progress?: (progress: number) => void): Promise<Post> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getPostByIdForClinicAdminUser", args, progress});
    return {
        id: ret.id,
        image: {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        title: ret.title,
        slug: ret.slug === null || ret.slug === undefined ? null : ret.slug,
        description: ret.description,
        adminUser: {
            id: ret.adminUser.id,
            clinic: ret.adminUser.clinic === null || ret.adminUser.clinic === undefined ? null : {
                id: ret.adminUser.clinic.id,
                image: {
                    thumb: {
                        width: ret.adminUser.clinic.image.thumb.width || 0,
                        height: ret.adminUser.clinic.image.thumb.height || 0,
                        url: ret.adminUser.clinic.image.thumb.url,
                    },
                    width: ret.adminUser.clinic.image.width || 0,
                    height: ret.adminUser.clinic.image.height || 0,
                    url: ret.adminUser.clinic.image.url,
                },
                phone: ret.adminUser.clinic.phone,
                showInSite: !!ret.adminUser.clinic.showInSite,
                address: {
                    zipcode: ret.adminUser.clinic.address.zipcode,
                    street: ret.adminUser.clinic.address.street,
                    streetNumber: ret.adminUser.clinic.address.streetNumber,
                    complementary: ret.adminUser.clinic.address.complementary,
                    neighborhood: ret.adminUser.clinic.address.neighborhood,
                    city: ret.adminUser.clinic.address.city,
                    state: ret.adminUser.clinic.address.state,
                },
                code: ret.adminUser.clinic.code || 0,
                codeFormat: ret.adminUser.clinic.codeFormat,
                services: ret.adminUser.clinic.services === null || ret.adminUser.clinic.services === undefined ? null : ret.adminUser.clinic.services,
                createdAt: new Date(ret.adminUser.clinic.createdAt + "Z"),
                adminUser: ret.adminUser.clinic.adminUser === null || ret.adminUser.clinic.adminUser === undefined ? null : {
                    name: ret.adminUser.clinic.adminUser.name,
                    documentNumber: ret.adminUser.clinic.adminUser.documentNumber,
                },
            },
            type: ret.adminUser.type,
            createdAt: new Date(ret.adminUser.createdAt + "Z"),
            name: ret.adminUser.name,
            email: ret.adminUser.email,
            documentNumber: ret.adminUser.documentNumber,
        },
        review: ret.review === null || ret.review === undefined ? null : ret.review,
        createdAt: new Date(ret.createdAt + "Z"),
        approvedAt: ret.approvedAt === null || ret.approvedAt === undefined ? null : new Date(ret.approvedAt + "Z"),
    };
}

export async function getAllReprovedPostsForClinicAdminUser(pageOffset: number, progress?: (progress: number) => void): Promise<PostWithoutDescription[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllReprovedPostsForClinicAdminUser", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        image: {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        title: e.title,
        slug: e.slug === null || e.slug === undefined ? null : e.slug,
        adminUser: {
            id: e.adminUser.id,
            clinic: e.adminUser.clinic === null || e.adminUser.clinic === undefined ? null : {
                id: e.adminUser.clinic.id,
                image: {
                    thumb: {
                        width: e.adminUser.clinic.image.thumb.width || 0,
                        height: e.adminUser.clinic.image.thumb.height || 0,
                        url: e.adminUser.clinic.image.thumb.url,
                    },
                    width: e.adminUser.clinic.image.width || 0,
                    height: e.adminUser.clinic.image.height || 0,
                    url: e.adminUser.clinic.image.url,
                },
                phone: e.adminUser.clinic.phone,
                showInSite: !!e.adminUser.clinic.showInSite,
                address: {
                    zipcode: e.adminUser.clinic.address.zipcode,
                    street: e.adminUser.clinic.address.street,
                    streetNumber: e.adminUser.clinic.address.streetNumber,
                    complementary: e.adminUser.clinic.address.complementary,
                    neighborhood: e.adminUser.clinic.address.neighborhood,
                    city: e.adminUser.clinic.address.city,
                    state: e.adminUser.clinic.address.state,
                },
                code: e.adminUser.clinic.code || 0,
                codeFormat: e.adminUser.clinic.codeFormat,
                services: e.adminUser.clinic.services === null || e.adminUser.clinic.services === undefined ? null : e.adminUser.clinic.services,
                createdAt: new Date(e.adminUser.clinic.createdAt + "Z"),
                adminUser: e.adminUser.clinic.adminUser === null || e.adminUser.clinic.adminUser === undefined ? null : {
                    name: e.adminUser.clinic.adminUser.name,
                    documentNumber: e.adminUser.clinic.adminUser.documentNumber,
                },
            },
            type: e.adminUser.type,
            createdAt: new Date(e.adminUser.createdAt + "Z"),
            name: e.adminUser.name,
            email: e.adminUser.email,
            documentNumber: e.adminUser.documentNumber,
        },
        review: e.review === null || e.review === undefined ? null : e.review,
        approvedAt: e.approvedAt === null || e.approvedAt === undefined ? null : new Date(e.approvedAt + "Z"),
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getAllReprovedPostsForMarketingMaster(pageOffset: number, progress?: (progress: number) => void): Promise<PostWithoutDescription[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllReprovedPostsForMarketingMaster", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        image: {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        title: e.title,
        slug: e.slug === null || e.slug === undefined ? null : e.slug,
        adminUser: {
            id: e.adminUser.id,
            clinic: e.adminUser.clinic === null || e.adminUser.clinic === undefined ? null : {
                id: e.adminUser.clinic.id,
                image: {
                    thumb: {
                        width: e.adminUser.clinic.image.thumb.width || 0,
                        height: e.adminUser.clinic.image.thumb.height || 0,
                        url: e.adminUser.clinic.image.thumb.url,
                    },
                    width: e.adminUser.clinic.image.width || 0,
                    height: e.adminUser.clinic.image.height || 0,
                    url: e.adminUser.clinic.image.url,
                },
                phone: e.adminUser.clinic.phone,
                showInSite: !!e.adminUser.clinic.showInSite,
                address: {
                    zipcode: e.adminUser.clinic.address.zipcode,
                    street: e.adminUser.clinic.address.street,
                    streetNumber: e.adminUser.clinic.address.streetNumber,
                    complementary: e.adminUser.clinic.address.complementary,
                    neighborhood: e.adminUser.clinic.address.neighborhood,
                    city: e.adminUser.clinic.address.city,
                    state: e.adminUser.clinic.address.state,
                },
                code: e.adminUser.clinic.code || 0,
                codeFormat: e.adminUser.clinic.codeFormat,
                services: e.adminUser.clinic.services === null || e.adminUser.clinic.services === undefined ? null : e.adminUser.clinic.services,
                createdAt: new Date(e.adminUser.clinic.createdAt + "Z"),
                adminUser: e.adminUser.clinic.adminUser === null || e.adminUser.clinic.adminUser === undefined ? null : {
                    name: e.adminUser.clinic.adminUser.name,
                    documentNumber: e.adminUser.clinic.adminUser.documentNumber,
                },
            },
            type: e.adminUser.type,
            createdAt: new Date(e.adminUser.createdAt + "Z"),
            name: e.adminUser.name,
            email: e.adminUser.email,
            documentNumber: e.adminUser.documentNumber,
        },
        review: e.review === null || e.review === undefined ? null : e.review,
        approvedAt: e.approvedAt === null || e.approvedAt === undefined ? null : new Date(e.approvedAt + "Z"),
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getAllReprovedPostsForMarketing(pageOffset: number, progress?: (progress: number) => void): Promise<PostWithoutDescription[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllReprovedPostsForMarketing", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        image: {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        title: e.title,
        slug: e.slug === null || e.slug === undefined ? null : e.slug,
        adminUser: {
            id: e.adminUser.id,
            clinic: e.adminUser.clinic === null || e.adminUser.clinic === undefined ? null : {
                id: e.adminUser.clinic.id,
                image: {
                    thumb: {
                        width: e.adminUser.clinic.image.thumb.width || 0,
                        height: e.adminUser.clinic.image.thumb.height || 0,
                        url: e.adminUser.clinic.image.thumb.url,
                    },
                    width: e.adminUser.clinic.image.width || 0,
                    height: e.adminUser.clinic.image.height || 0,
                    url: e.adminUser.clinic.image.url,
                },
                phone: e.adminUser.clinic.phone,
                showInSite: !!e.adminUser.clinic.showInSite,
                address: {
                    zipcode: e.adminUser.clinic.address.zipcode,
                    street: e.adminUser.clinic.address.street,
                    streetNumber: e.adminUser.clinic.address.streetNumber,
                    complementary: e.adminUser.clinic.address.complementary,
                    neighborhood: e.adminUser.clinic.address.neighborhood,
                    city: e.adminUser.clinic.address.city,
                    state: e.adminUser.clinic.address.state,
                },
                code: e.adminUser.clinic.code || 0,
                codeFormat: e.adminUser.clinic.codeFormat,
                services: e.adminUser.clinic.services === null || e.adminUser.clinic.services === undefined ? null : e.adminUser.clinic.services,
                createdAt: new Date(e.adminUser.clinic.createdAt + "Z"),
                adminUser: e.adminUser.clinic.adminUser === null || e.adminUser.clinic.adminUser === undefined ? null : {
                    name: e.adminUser.clinic.adminUser.name,
                    documentNumber: e.adminUser.clinic.adminUser.documentNumber,
                },
            },
            type: e.adminUser.type,
            createdAt: new Date(e.adminUser.createdAt + "Z"),
            name: e.adminUser.name,
            email: e.adminUser.email,
            documentNumber: e.adminUser.documentNumber,
        },
        review: e.review === null || e.review === undefined ? null : e.review,
        approvedAt: e.approvedAt === null || e.approvedAt === undefined ? null : new Date(e.approvedAt + "Z"),
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getAllPostsForMarketingUser(pageOffset: number, progress?: (progress: number) => void): Promise<PostWithoutDescription[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllPostsForMarketingUser", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        image: {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        title: e.title,
        slug: e.slug === null || e.slug === undefined ? null : e.slug,
        adminUser: {
            id: e.adminUser.id,
            clinic: e.adminUser.clinic === null || e.adminUser.clinic === undefined ? null : {
                id: e.adminUser.clinic.id,
                image: {
                    thumb: {
                        width: e.adminUser.clinic.image.thumb.width || 0,
                        height: e.adminUser.clinic.image.thumb.height || 0,
                        url: e.adminUser.clinic.image.thumb.url,
                    },
                    width: e.adminUser.clinic.image.width || 0,
                    height: e.adminUser.clinic.image.height || 0,
                    url: e.adminUser.clinic.image.url,
                },
                phone: e.adminUser.clinic.phone,
                showInSite: !!e.adminUser.clinic.showInSite,
                address: {
                    zipcode: e.adminUser.clinic.address.zipcode,
                    street: e.adminUser.clinic.address.street,
                    streetNumber: e.adminUser.clinic.address.streetNumber,
                    complementary: e.adminUser.clinic.address.complementary,
                    neighborhood: e.adminUser.clinic.address.neighborhood,
                    city: e.adminUser.clinic.address.city,
                    state: e.adminUser.clinic.address.state,
                },
                code: e.adminUser.clinic.code || 0,
                codeFormat: e.adminUser.clinic.codeFormat,
                services: e.adminUser.clinic.services === null || e.adminUser.clinic.services === undefined ? null : e.adminUser.clinic.services,
                createdAt: new Date(e.adminUser.clinic.createdAt + "Z"),
                adminUser: e.adminUser.clinic.adminUser === null || e.adminUser.clinic.adminUser === undefined ? null : {
                    name: e.adminUser.clinic.adminUser.name,
                    documentNumber: e.adminUser.clinic.adminUser.documentNumber,
                },
            },
            type: e.adminUser.type,
            createdAt: new Date(e.adminUser.createdAt + "Z"),
            name: e.adminUser.name,
            email: e.adminUser.email,
            documentNumber: e.adminUser.documentNumber,
        },
        review: e.review === null || e.review === undefined ? null : e.review,
        approvedAt: e.approvedAt === null || e.approvedAt === undefined ? null : new Date(e.approvedAt + "Z"),
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getPostByIdForAdminMarketing(id: string, progress?: (progress: number) => void): Promise<Post> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getPostByIdForAdminMarketing", args, progress});
    return {
        id: ret.id,
        image: {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        title: ret.title,
        slug: ret.slug === null || ret.slug === undefined ? null : ret.slug,
        description: ret.description,
        adminUser: {
            id: ret.adminUser.id,
            clinic: ret.adminUser.clinic === null || ret.adminUser.clinic === undefined ? null : {
                id: ret.adminUser.clinic.id,
                image: {
                    thumb: {
                        width: ret.adminUser.clinic.image.thumb.width || 0,
                        height: ret.adminUser.clinic.image.thumb.height || 0,
                        url: ret.adminUser.clinic.image.thumb.url,
                    },
                    width: ret.adminUser.clinic.image.width || 0,
                    height: ret.adminUser.clinic.image.height || 0,
                    url: ret.adminUser.clinic.image.url,
                },
                phone: ret.adminUser.clinic.phone,
                showInSite: !!ret.adminUser.clinic.showInSite,
                address: {
                    zipcode: ret.adminUser.clinic.address.zipcode,
                    street: ret.adminUser.clinic.address.street,
                    streetNumber: ret.adminUser.clinic.address.streetNumber,
                    complementary: ret.adminUser.clinic.address.complementary,
                    neighborhood: ret.adminUser.clinic.address.neighborhood,
                    city: ret.adminUser.clinic.address.city,
                    state: ret.adminUser.clinic.address.state,
                },
                code: ret.adminUser.clinic.code || 0,
                codeFormat: ret.adminUser.clinic.codeFormat,
                services: ret.adminUser.clinic.services === null || ret.adminUser.clinic.services === undefined ? null : ret.adminUser.clinic.services,
                createdAt: new Date(ret.adminUser.clinic.createdAt + "Z"),
                adminUser: ret.adminUser.clinic.adminUser === null || ret.adminUser.clinic.adminUser === undefined ? null : {
                    name: ret.adminUser.clinic.adminUser.name,
                    documentNumber: ret.adminUser.clinic.adminUser.documentNumber,
                },
            },
            type: ret.adminUser.type,
            createdAt: new Date(ret.adminUser.createdAt + "Z"),
            name: ret.adminUser.name,
            email: ret.adminUser.email,
            documentNumber: ret.adminUser.documentNumber,
        },
        review: ret.review === null || ret.review === undefined ? null : ret.review,
        createdAt: new Date(ret.createdAt + "Z"),
        approvedAt: ret.approvedAt === null || ret.approvedAt === undefined ? null : new Date(ret.approvedAt + "Z"),
    };
}

export async function getClinicAverageRating(id: string, progress?: (progress: number) => void): Promise<number | null> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getClinicAverageRating", args, progress});
    return ret === null || ret === undefined ? null : ret;
}

export async function getVetAverageRating(vetId: string, progress?: (progress: number) => void): Promise<number | null> {
    const args = {
        vetId: vetId,
    };
    const ret = await makeRequest({name: "getVetAverageRating", args, progress});
    return ret === null || ret === undefined ? null : ret;
}

export async function sendRequestResetPassword(email: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        email: email,
    };
    await makeRequest({name: "sendRequestResetPassword", args, progress});
    return undefined;
}

export async function validateToken(token: string, progress?: (progress: number) => void): Promise<boolean> {
    const args = {
        token: token,
    };
    const ret = await makeRequest({name: "validateToken", args, progress});
    return !!ret;
}

export async function resetPassword(token: string, newPassword: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
        newPassword: newPassword,
    };
    await makeRequest({name: "resetPassword", args, progress});
    return undefined;
}

export async function deleteUser(id: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteUser", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        password: ret.password,
        documentNumber: ret.documentNumber === null || ret.documentNumber === undefined ? null : ret.documentNumber,
        phone: ret.phone,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        petParents: ret.petParents,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function getUser(id: string, progress?: (progress: number) => void): Promise<UserWithPets> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getUser", args, progress});
    return {
        pets: ret.pets.map((e: any) => ({
            id: e.id,
            name: e.name,
            image: {
                thumb: {
                    width: e.image.thumb.width || 0,
                    height: e.image.thumb.height || 0,
                    url: e.image.thumb.url,
                },
                width: e.image.width || 0,
                height: e.image.height || 0,
                url: e.image.url,
            },
            age: e.age,
            species: e.species,
            animalSex: e.animalSex,
            isCastrated: !!e.isCastrated,
            code: e.code || 0,
            breed: e.breed,
            birthdate: new Date(e.birthdate + "Z"),
            codeFormat: e.codeFormat,
        })),
        id: ret.id,
        email: ret.email,
        name: ret.name,
        password: ret.password,
        documentNumber: ret.documentNumber === null || ret.documentNumber === undefined ? null : ret.documentNumber,
        phone: ret.phone,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        petParents: ret.petParents,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function getAllUsers(pageOffSet: number, documentNumber: string | null, progress?: (progress: number) => void): Promise<User[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
        documentNumber: documentNumber === null || documentNumber === undefined ? null : documentNumber,
    };
    const ret = await makeRequest({name: "getAllUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        email: e.email,
        name: e.name,
        password: e.password,
        documentNumber: e.documentNumber === null || e.documentNumber === undefined ? null : e.documentNumber,
        phone: e.phone,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        petParents: e.petParents,
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getUserDeleteRequestById(id: string, progress?: (progress: number) => void): Promise<UserDeleteRequest> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getUserDeleteRequestById", args, progress});
    return {
        id: ret.id,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            password: ret.user.password,
            documentNumber: ret.user.documentNumber === null || ret.user.documentNumber === undefined ? null : ret.user.documentNumber,
            phone: ret.user.phone,
            image: ret.user.image === null || ret.user.image === undefined ? null : {
                thumb: {
                    width: ret.user.image.thumb.width || 0,
                    height: ret.user.image.thumb.height || 0,
                    url: ret.user.image.thumb.url,
                },
                width: ret.user.image.width || 0,
                height: ret.user.image.height || 0,
                url: ret.user.image.url,
            },
            petParents: ret.user.petParents,
            createdAt: new Date(ret.user.createdAt + "Z"),
        },
        acceptedAt: ret.acceptedAt === null || ret.acceptedAt === undefined ? null : new Date(ret.acceptedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
    };
}

export async function getAllUserDeleteRequests(pageOffSet: number, progress?: (progress: number) => void): Promise<UserDeleteRequest[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
    };
    const ret = await makeRequest({name: "getAllUserDeleteRequests", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        user: {
            id: e.user.id,
            email: e.user.email,
            name: e.user.name,
            password: e.user.password,
            documentNumber: e.user.documentNumber === null || e.user.documentNumber === undefined ? null : e.user.documentNumber,
            phone: e.user.phone,
            image: e.user.image === null || e.user.image === undefined ? null : {
                thumb: {
                    width: e.user.image.thumb.width || 0,
                    height: e.user.image.thumb.height || 0,
                    url: e.user.image.thumb.url,
                },
                width: e.user.image.width || 0,
                height: e.user.image.height || 0,
                url: e.user.image.url,
            },
            petParents: e.user.petParents,
            createdAt: new Date(e.user.createdAt + "Z"),
        },
        acceptedAt: e.acceptedAt === null || e.acceptedAt === undefined ? null : new Date(e.acceptedAt + "Z"),
        createdAt: new Date(e.createdAt + "Z"),
        deletedAt: e.deletedAt === null || e.deletedAt === undefined ? null : new Date(e.deletedAt + "Z"),
    }));
}

export async function getAllPendingUserDeleteRequests(pageOffSet: number, progress?: (progress: number) => void): Promise<UserDeleteRequest[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
    };
    const ret = await makeRequest({name: "getAllPendingUserDeleteRequests", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        user: {
            id: e.user.id,
            email: e.user.email,
            name: e.user.name,
            password: e.user.password,
            documentNumber: e.user.documentNumber === null || e.user.documentNumber === undefined ? null : e.user.documentNumber,
            phone: e.user.phone,
            image: e.user.image === null || e.user.image === undefined ? null : {
                thumb: {
                    width: e.user.image.thumb.width || 0,
                    height: e.user.image.thumb.height || 0,
                    url: e.user.image.thumb.url,
                },
                width: e.user.image.width || 0,
                height: e.user.image.height || 0,
                url: e.user.image.url,
            },
            petParents: e.user.petParents,
            createdAt: new Date(e.user.createdAt + "Z"),
        },
        acceptedAt: e.acceptedAt === null || e.acceptedAt === undefined ? null : new Date(e.acceptedAt + "Z"),
        createdAt: new Date(e.createdAt + "Z"),
        deletedAt: e.deletedAt === null || e.deletedAt === undefined ? null : new Date(e.deletedAt + "Z"),
    }));
}

export async function acceptUserDeleteRequest(id: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "acceptUserDeleteRequest", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        password: ret.password,
        documentNumber: ret.documentNumber === null || ret.documentNumber === undefined ? null : ret.documentNumber,
        phone: ret.phone,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        petParents: ret.petParents,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function refuseUserDeleteRequest(id: string, progress?: (progress: number) => void): Promise<UserDeleteRequest> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "refuseUserDeleteRequest", args, progress});
    return {
        id: ret.id,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            password: ret.user.password,
            documentNumber: ret.user.documentNumber === null || ret.user.documentNumber === undefined ? null : ret.user.documentNumber,
            phone: ret.user.phone,
            image: ret.user.image === null || ret.user.image === undefined ? null : {
                thumb: {
                    width: ret.user.image.thumb.width || 0,
                    height: ret.user.image.thumb.height || 0,
                    url: ret.user.image.thumb.url,
                },
                width: ret.user.image.width || 0,
                height: ret.user.image.height || 0,
                url: ret.user.image.url,
            },
            petParents: ret.user.petParents,
            createdAt: new Date(ret.user.createdAt + "Z"),
        },
        acceptedAt: ret.acceptedAt === null || ret.acceptedAt === undefined ? null : new Date(ret.acceptedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
    };
}

export async function getAllVaccines(pageOffset: number, progress?: (progress: number) => void): Promise<Vaccine[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllVaccines", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        code: e.code === null || e.code === undefined ? null : e.code || 0,
        nextVaccinationAt: e.nextVaccinationAt === null || e.nextVaccinationAt === undefined ? null : new Date(e.nextVaccinationAt + "Z"),
        expirationDateAt: new Date(e.expirationDateAt + "Z"),
        manufacturingDateAt: new Date(e.manufacturingDateAt + "Z"),
        batch: e.batch || 0,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        appliedAt: new Date(e.appliedAt + "Z"),
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getAllVaccinesByPet(pageOffset: number, petId: string, progress?: (progress: number) => void): Promise<Vaccine[]> {
    const args = {
        pageOffset: pageOffset || 0,
        petId: petId,
    };
    const ret = await makeRequest({name: "getAllVaccinesByPet", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        code: e.code === null || e.code === undefined ? null : e.code || 0,
        nextVaccinationAt: e.nextVaccinationAt === null || e.nextVaccinationAt === undefined ? null : new Date(e.nextVaccinationAt + "Z"),
        expirationDateAt: new Date(e.expirationDateAt + "Z"),
        manufacturingDateAt: new Date(e.manufacturingDateAt + "Z"),
        batch: e.batch || 0,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        appliedAt: new Date(e.appliedAt + "Z"),
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getAllVaccinesByConsult(pageOffset: number, consultId: string, progress?: (progress: number) => void): Promise<Vaccine[]> {
    const args = {
        pageOffset: pageOffset || 0,
        consultId: consultId,
    };
    const ret = await makeRequest({name: "getAllVaccinesByConsult", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        code: e.code === null || e.code === undefined ? null : e.code || 0,
        nextVaccinationAt: e.nextVaccinationAt === null || e.nextVaccinationAt === undefined ? null : new Date(e.nextVaccinationAt + "Z"),
        expirationDateAt: new Date(e.expirationDateAt + "Z"),
        manufacturingDateAt: new Date(e.manufacturingDateAt + "Z"),
        batch: e.batch || 0,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        appliedAt: new Date(e.appliedAt + "Z"),
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getVaccineById(id: string, progress?: (progress: number) => void): Promise<Vaccine> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getVaccineById", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        code: ret.code === null || ret.code === undefined ? null : ret.code || 0,
        nextVaccinationAt: ret.nextVaccinationAt === null || ret.nextVaccinationAt === undefined ? null : new Date(ret.nextVaccinationAt + "Z"),
        expirationDateAt: new Date(ret.expirationDateAt + "Z"),
        manufacturingDateAt: new Date(ret.manufacturingDateAt + "Z"),
        batch: ret.batch || 0,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        appliedAt: new Date(ret.appliedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function createVaccine(newVaccine: EditVaccine, consultationId: string, progress?: (progress: number) => void): Promise<Vaccine> {
    const args = {
        newVaccine: {
            name: newVaccine.name,
            code: newVaccine.code === null || newVaccine.code === undefined ? null : newVaccine.code || 0,
            nextVaccinationAt: newVaccine.nextVaccinationAt === null || newVaccine.nextVaccinationAt === undefined ? null : (typeof newVaccine.nextVaccinationAt === "string" && (newVaccine.nextVaccinationAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newVaccine.nextVaccinationAt as any).replace("Z", "") : newVaccine.nextVaccinationAt.toISOString().replace("Z", "")),
            appliedAt: (typeof newVaccine.appliedAt === "string" && (newVaccine.appliedAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newVaccine.appliedAt as any).replace("Z", "") : newVaccine.appliedAt.toISOString().replace("Z", "")),
            expirationDateAt: (typeof newVaccine.expirationDateAt === "string" && (newVaccine.expirationDateAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newVaccine.expirationDateAt as any).replace("Z", "") : newVaccine.expirationDateAt.toISOString().replace("Z", "")),
            manufacturingDateAt: (typeof newVaccine.manufacturingDateAt === "string" && (newVaccine.manufacturingDateAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newVaccine.manufacturingDateAt as any).replace("Z", "") : newVaccine.manufacturingDateAt.toISOString().replace("Z", "")),
            batch: newVaccine.batch || 0,
            image: newVaccine.image === null || newVaccine.image === undefined ? null : {
                thumb: {
                    width: newVaccine.image.thumb.width || 0,
                    height: newVaccine.image.thumb.height || 0,
                    url: newVaccine.image.thumb.url,
                },
                width: newVaccine.image.width || 0,
                height: newVaccine.image.height || 0,
                url: newVaccine.image.url,
            },
        },
        consultationId: consultationId,
    };
    const ret = await makeRequest({name: "createVaccine", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        code: ret.code === null || ret.code === undefined ? null : ret.code || 0,
        nextVaccinationAt: ret.nextVaccinationAt === null || ret.nextVaccinationAt === undefined ? null : new Date(ret.nextVaccinationAt + "Z"),
        expirationDateAt: new Date(ret.expirationDateAt + "Z"),
        manufacturingDateAt: new Date(ret.manufacturingDateAt + "Z"),
        batch: ret.batch || 0,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        appliedAt: new Date(ret.appliedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function editVaccine(id: string, editVaccine: EditVaccine, progress?: (progress: number) => void): Promise<Vaccine> {
    const args = {
        id: id,
        editVaccine: {
            name: editVaccine.name,
            code: editVaccine.code === null || editVaccine.code === undefined ? null : editVaccine.code || 0,
            nextVaccinationAt: editVaccine.nextVaccinationAt === null || editVaccine.nextVaccinationAt === undefined ? null : (typeof editVaccine.nextVaccinationAt === "string" && (editVaccine.nextVaccinationAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editVaccine.nextVaccinationAt as any).replace("Z", "") : editVaccine.nextVaccinationAt.toISOString().replace("Z", "")),
            appliedAt: (typeof editVaccine.appliedAt === "string" && (editVaccine.appliedAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editVaccine.appliedAt as any).replace("Z", "") : editVaccine.appliedAt.toISOString().replace("Z", "")),
            expirationDateAt: (typeof editVaccine.expirationDateAt === "string" && (editVaccine.expirationDateAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editVaccine.expirationDateAt as any).replace("Z", "") : editVaccine.expirationDateAt.toISOString().replace("Z", "")),
            manufacturingDateAt: (typeof editVaccine.manufacturingDateAt === "string" && (editVaccine.manufacturingDateAt as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (editVaccine.manufacturingDateAt as any).replace("Z", "") : editVaccine.manufacturingDateAt.toISOString().replace("Z", "")),
            batch: editVaccine.batch || 0,
            image: editVaccine.image === null || editVaccine.image === undefined ? null : {
                thumb: {
                    width: editVaccine.image.thumb.width || 0,
                    height: editVaccine.image.thumb.height || 0,
                    url: editVaccine.image.thumb.url,
                },
                width: editVaccine.image.width || 0,
                height: editVaccine.image.height || 0,
                url: editVaccine.image.url,
            },
        },
    };
    const ret = await makeRequest({name: "editVaccine", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        code: ret.code === null || ret.code === undefined ? null : ret.code || 0,
        nextVaccinationAt: ret.nextVaccinationAt === null || ret.nextVaccinationAt === undefined ? null : new Date(ret.nextVaccinationAt + "Z"),
        expirationDateAt: new Date(ret.expirationDateAt + "Z"),
        manufacturingDateAt: new Date(ret.manufacturingDateAt + "Z"),
        batch: ret.batch || 0,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        appliedAt: new Date(ret.appliedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function deleteVaccineById(id: string, progress?: (progress: number) => void): Promise<Vaccine> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteVaccineById", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        code: ret.code === null || ret.code === undefined ? null : ret.code || 0,
        nextVaccinationAt: ret.nextVaccinationAt === null || ret.nextVaccinationAt === undefined ? null : new Date(ret.nextVaccinationAt + "Z"),
        expirationDateAt: new Date(ret.expirationDateAt + "Z"),
        manufacturingDateAt: new Date(ret.manufacturingDateAt + "Z"),
        batch: ret.batch || 0,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        appliedAt: new Date(ret.appliedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function ping(progress?: (progress: number) => void): Promise<string> {
    const ret = await makeRequest({name: "ping", args: {}, progress});
    return ret;
}

export async function setPushToken(token: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
    };
    await makeRequest({name: "setPushToken", args, progress});
    return undefined;
}

//////////////////////////////////////////////////////

let fallbackDeviceId: string | null = null;

function setDeviceId(deviceId: string): void {
    fallbackDeviceId = deviceId;
    try {
        localStorage.setItem("deviceId", deviceId);
    } catch (e) {}
}

function getDeviceId(): string | null {
    try {
        return localStorage.getItem("deviceId") || fallbackDeviceId;
    } catch (e) {}

    return fallbackDeviceId;
}

async function device(): Promise<any> {
    const parser = new UAParser();
    parser.setUA(navigator.userAgent);
    const agent = parser.getResult();
    const me = document.currentScript as HTMLScriptElement;
    const device: any = {
            type: "web",
            platform: {
                browser: agent.browser.name,
                browserVersion: agent.browser.version,
                os: agent.os.name,
                osVersion: agent.os.version,
            },
            screen: {
                width: screen.width,
                height: screen.height,
            },
            version: me ? me.src : "",
            language: navigator.language,
    };

    const deviceId = getDeviceId();
    if (deviceId)
        device.id = deviceId;

    return device;
}

function randomBytesHex(len: number): string {
    let hex = "";
    for (let i = 0; i < 2 * len; ++i) {
        hex += "0123456789abcdef"[Math.floor(Math.random() * 16)];
    }

    return hex;
}

export interface ListenerTypes {
    fail: (e: Error, name: string, args: any) => void;
    fatal: (e: Error, name: string, args: any) => void;
    success: (res: string, name: string, args: any) => void;
}

// tslint:disable-next-line: ban-types
type HookArray = Function[];
export type Listenables = keyof ListenerTypes;
export type ListenersDict = { [k in Listenables]: Array<ListenerTypes[k]> };

const listenersDict: ListenersDict = {
    fail: [],
    fatal: [],
    success: [],
};

export function addEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listeners.push(hook);
}

export function removeEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listenersDict[listenable] = listeners.filter((h) => h !== hook) as any;
}

async function makeRequest({name, args, progress}: {name: string, args: any, progress?: (progress: number) => void}): Promise<any> {
    const deviceData = await device();
    return new Promise<any>((resolve, reject) => {
        const req = new XMLHttpRequest();
        req.open(
            "POST",
            `${baseUrl.startsWith("http") || baseUrl.startsWith("localhost") ?
                "" :
                "https://"
            }${baseUrl}/${name}`,
        );

        const body = {
            id: randomBytesHex(8),
            device: deviceData,
            name: name,
            args: args,
        };

        function roughSizeOfObject(object: any): number {
            const objectList: any = [];
            const stack: any = [ object ];
            let bytes = 0;

            while (stack.length) {
                const value = stack.pop();
                if (typeof value === "boolean") {
                    bytes += 4;
                } else if (typeof value === "string") {
                    bytes += value.length * 2;
                } else if (typeof value === "number") {
                    bytes += 8;
                } else if (
                    typeof value === "object"
                    && objectList.indexOf(value) === -1
                ) {
                    objectList.push(value);
                    for (const i in value) {
                        stack.push(value[i]);
                    }
                }
            }

            return bytes;
        }

        req.upload.onprogress = (event: ProgressEvent) => {
            if (event.lengthComputable && progress) {
                progress(Math.ceil(((event.loaded) / event.total) * 100));
            }
        };

        req.onreadystatechange = () => {
            if (req.readyState !== 4) return;
            try {
                const response = JSON.parse(req.responseText);

                try {
                    setDeviceId(response.deviceId);

                    if (response.ok) {
                        resolve(response.result);
                        listenersDict["success"].forEach((hook) => hook(response.result, name, args));
                    } else {
                        const error = typeof response.error === "object" ?
                            response.error :
                            { type: "Fatal", message: response.toString() };

                        reject(error);

                        listenersDict["fail"].forEach((hook) => hook(error, name, args));
                    }
                } catch (e) {
                    console.error(e);
                    reject({type: "Fatal", message: `[${name}] ${e.toString()}`});

                    listenersDict["fatal"].forEach((hook) => hook(e, name, args));
                }
            } catch (e) {
                console.error(e);
                reject({ type: "BadFormattedResponse", message: `Response couldn't be parsed as JSON (${req.responseText}):\n${e.toString()}` });
                listenersDict["fatal"].forEach((hook) => hook(e, name, args));
            }
        };

        req.send(JSON.stringify(body));
    });
}
