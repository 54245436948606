import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { CentralizedCard, TextInput } from "~/components";
import strings from "~/resources/strings";
import Store from "./store";
import { useHistory } from "~/hooks/useHistory";

const CreateOrEdit: React.FC = () => {
	const { id } = useParams<{id: string}>();
	const history = useHistory();
	const store = useLocalObservable(() => new Store(id));
	const pageStrings = strings.pages.adminMarketingMaster.createOrEdit;
	const commonStrings = strings.common;

	const onSucess = () => {
		history.goBack();
	};

	return (
		<CentralizedCard
			title={{ text: pageStrings.title(!!id) }}
			button={(
				<Button
					minW={{ base: "100%", md: 280 }}
					size="lg"
					mt={10}
					isLoading={store.loader.isLoading}
					onClick={() => store.createOrEditAdmin(onSucess)}
				>
					{commonStrings.buttons.saveOrEdit(!!id)}
				</Button>
			)}
		>
			<TextInput
				labelText={commonStrings.fields.name}
				labelProps={{ fontWeight: "bold" }}
				labelColor="primary"
				type="text"
				isDisabled={store.loader.isLoading}
				errorText={store.formShelf.field("name").error}
				{...store.formShelf.field("name")}
			/>
			<TextInput
				labelText={commonStrings.fields.email}
				labelProps={{ fontWeight: "bold" }}
				labelColor="primary"
				type="text"
				isDisabled={store.loader.isLoading}
				errorText={store.formShelf.field("email").error}
				{...store.formShelf.field("email")}
			/>
			<TextInput
				labelText={commonStrings.fields.cpf}
				labelProps={{ fontWeight: "bold" }}
				labelColor="primary"
				type="text"
				isDisabled={store.loader.isLoading}
				errorText={store.formShelf.field("documentNumber").error}
				{...store.formShelf.field("documentNumber")}
				mask="999.999.999-99"
			/>
			{!id && (
				<>
					<TextInput
						labelText={commonStrings.fields.password}
						labelProps={{ fontWeight: "bold" }}
						labelColor="primary"
						type="password"
						isDisabled={store.loader.isLoading}
						errorText={store.formShelf.field("password").error}
						{...store.formShelf.field("password")}
					/>
				</>
			)}
		</CentralizedCard>
	);
};

export default observer(CreateOrEdit);
