import * as api from "@startapp/connectvet-admin-api";

import { LocalizedStringsMethods } from "localized-strings";

const productionUrl = "https://api.vetepet24h.com.br/admin";
const stagingUrl = "https://api.vetepet24h.com.br/admin-staging";

const bootAPI = (strings: LocalizedStringsMethods) => {
	let apiURL: string;

	switch (process.env.NODE_ENV) {
		case "production": {
			apiURL = productionUrl;
			break;
		}

		case "staging":
		default: {
			apiURL = stagingUrl;
		}
	}

	api.setStrings(strings);
	api.setUrl(apiURL);
};

export default api;

export { bootAPI };
