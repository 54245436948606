import React from "react";
import { observer } from "mobx-react-lite";
import { Td } from "@chakra-ui/react";
import { TableModel } from "~/components";
import strings from "~/resources/strings";
import api from "~/resources/api";
import format from "~/resources/format";

interface IProps {
	isMaster?: boolean;
}

const TableView: React.FC<IProps> = (props) => {
	const pageStrings = strings.pages.reprovedPosts;
	const { isMaster } = props;

	return (
		<TableModel
			header={pageStrings.table.headers}
			tableTitle={pageStrings.table.title}
			initStoreSettings={{
				apiGetAllMethod: isMaster ? api.getAllReprovedPostsForMarketingMaster : api.getAllReprovedPostsForMarketing,
			}}
			renderRow={(item) => (
				<>
					<Td>{item.adminUser.name}</Td>
					<Td>{item.title}</Td>
					<Td>{item.slug}</Td>
					<Td>{format.date(item.createdAt)}</Td>
				</>
			)}
			hasDetails
		/>
	);
};

export default observer(TableView);
