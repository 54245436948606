import { makeAutoObservable } from "mobx";
import {
	AttributeShelf,
	LoaderShelf,
	PaginatedListShelf,
} from "@startapp/mobx-utils";
import { onFetchError } from "~/resources/fetchError";
import api from "~/resources/api";
import PendingPostShelf from "~/shelves/PendingPostShelf";

export class Store {
	public pendingPostShelf = new PendingPostShelf();
	public paginetedListShelf: PaginatedListShelf<api.PostWithoutDescription>;
	public loader = new LoaderShelf();
	public postId = new AttributeShelf<string>("");

	constructor() {
		makeAutoObservable(this);
		this.paginetedListShelf = new PaginatedListShelf(
			(page) => api.getAllPendingPosts(page),
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		) ;
	}
}
