import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { ArrowBackIcon } from "@chakra-ui/icons";
import {
	Button,
	Center,
	chakra,
	Stack,
	Text,
} from "@chakra-ui/react";
import { useHistory } from "~/hooks/useHistory";
import {
	CentralizedCard,
	DetailsRow,
	ImageWithModal,
	ReprovePostModal,
} from "~/components";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import { Store } from "./store";
import api from "~/resources/api";
import format from "~/resources/format";
import strings from "~/resources/strings";

const Details = () => {
	const commonStrings = strings.common;
	const { id } = useParams<"id">();
	const store = useLocalObservable(() => new Store(id || ""));
	const history = useHistory();
	const [isOpen, setIsOpen] = React.useState(false);
	const onGoToTheUsersList = () => history.goBack();
	const { dialog } = useGlobalStore();

	const onSuccessReprove = () => {
		setIsOpen(false);
		history.goBack();
	};

	const openDialog = (post: api.Post | null) => {
		const modal = strings.common.modal;
		dialog.showDialog({
			title: modal.button.approve,
			closeOnOverlayClick: true,
			description: modal.descriptionApprove,
			buttons: [
				{
					title: modal.button.approve,
					onPress: () => {
						store.pendingPostShelf.approvePost(post?.id || "", () => history.goBack());
						dialog.closeDialog();
					},
					buttonProps: { bg: "green.500" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	return (
		<CentralizedCard
			title={{
				text: commonStrings.detailsTitle,
			}}
			isLoading={store.fetchModelShelf.initialLoader.isLoading}
			button={
				<Button
					onClick={() => onGoToTheUsersList()}
					w={{
						base: "50%",
						md: "15%",
					}}
					mb={5}
					variant="outline"
					leftIcon={
						<ArrowBackIcon />
					}
				>
					{strings.actions.back}
				</Button>
			}
		>
			{
				store.fetchModelShelf.model.value &&
					<>
						<ImageWithModal src={store.fetchModelShelf.model.value.image?.url} />
						<DetailsRow
							label={commonStrings.fields.author}
							value={store.fetchModelShelf.model.value.adminUser.name|| strings.common.empty}
						/>
						<DetailsRow
							label={commonStrings.fields.title}
							value={store.fetchModelShelf.model.value.title}
						/>
						<DetailsRow
							label={commonStrings.fields.slug}
							value={store.fetchModelShelf.model.value.slug}
						/>
						<DetailsRow
							label={commonStrings.fields.createdAt}
							value={format.date(store.fetchModelShelf.model.value.createdAt)}
						/>
						<Stack spacing={1}>
							<Text
								fontSize="md"
								color="gray.900"
								fontWeight="bold"
							>
								{commonStrings.fields.description}
							</Text>
							<chakra.div
								p={2}
								borderRadius="lg"
								backgroundColor="teal.50"
								dangerouslySetInnerHTML={{ __html: store.fetchModelShelf.model.value.description }}
							/>
						</Stack>
						<Center gap={2}>
							<Button
								onClick={() => openDialog(store.fetchModelShelf.model.value)}
							>
								{strings.common.buttons.approve}
							</Button>
							<Button
								colorScheme="red"
								onClick={() => setIsOpen(true)}
							>
								{strings.common.buttons.refuse}
							</Button>
						</Center>
						<ReprovePostModal
							formValues={store.pendingPostShelf.formShelf}
							isOpen={isOpen}
							onClose={() => setIsOpen(false)}
							onReprove={() => store.pendingPostShelf.refusePost(
								()=> onSuccessReprove(),
								store.fetchModelShelf.model.value?.id || "",
							)}
						/>
					</>
			}
		</CentralizedCard>
	);
};

export default observer(Details);
