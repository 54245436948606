import React from "react";
import { Icon, Flex } from "@chakra-ui/react";
import { DrawerContainer } from "../../../components";
import { AiOutlineMenu } from "react-icons/ai";

export const NavbarContent: React.FC = () => {
	const [isOpen, setIsOpen] = React.useState<boolean>(false);

	return (
		<>
			<Flex
				width="100%"
				alignItems="center"
				justifyContent="space-between"
				maxW={{ base: "100%", md: "72%" }}
			>
				<Icon
					as={AiOutlineMenu}
					onClick={() => setIsOpen(true)}
					cursor="pointer"
					color="primary.500"
					w={35}
					h={35}
				/>
			</Flex>
			<DrawerContainer
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
			/>
		</>
	);
};
