import { makeAutoObservable } from "mobx";
import { FormShelf } from "@startapp/mobx-utils/src/web";
import { AttributeShelf, LoaderShelf } from "@startapp/mobx-utils";

import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";
import { showSuccessToast } from "~/resources/toast";
import { onFetchError } from "~/resources/fetchError";

const pageStrings = strings.pages.adminMarketing.createOrEdit;

export default class Store {

	public formShelf = new FormShelf({
		name: "",
		email: "",
		password: "",
		documentNumber: "",
	});

	public loader = new LoaderShelf();

	public id = new AttributeShelf("");

	constructor(id?: string){
		makeAutoObservable(this);

		if (id) {
			this.id.setValue(id);
			this.getAdminUser(id);
		}
	}

	public getAdminUser = async (id: string) => {
		this.loader.start();
		try {
			const admin = await api.getAdminUser(id);
			this.setInitValues(admin);
		} catch (e) {
			onFetchError(e);
		} finally {
			this.loader.end();
		}
	};

	public setInitValues = (admin: api.EditAdminUser) => {
		this.formShelf = new FormShelf({
			name: admin.name,
			email: admin.email,
			documentNumber: admin.documentNumber,
			password: "",
		});
	};

	public get dataAdmin(){
		const data = this.formShelf.getValues();
		return {
			name: data.name,
			email: data.email,
			documentNumber: data.documentNumber,
		};
	}

	public createOrEditAdmin = async (onSuccess: () => void) => {
		this.loader.start();
		try {

			const data = this.formShelf.getValues();
			if (this.id.value) {
				await api.editMarketingUser(this.id.value, {
					...this.dataAdmin,
				});
			} else {
				await api.createMarketingMasterAdmin({
					...this.dataAdmin,
					password: data.password,
				});
			}

			showSuccessToast(pageStrings.success(!!this.id.value));
			onSuccess();
		} catch (e) {

			const error = Errors.treatError(e);
			if (error.type === api.ErrorType.Validation) {
				this.formShelf.fieldError.cleanAndAddErrors(JSON.parse((e as Error).message));
			} else {
				onFetchError(e);
			}
		} finally {
			this.loader.end();
		}
	};
}
