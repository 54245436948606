import React from "react";
import ReactDOM from "react-dom/client";
import { Routes, BrowserRouter, Route } from "react-router-dom";
import { GlobalStoreProvider } from "~/contexts/useGlobalContext";
import { theme } from "./themes";
import { ChakraProvider } from "@chakra-ui/react";
import { bootAPI } from "~/resources/api";
import strings from "~/resources/strings";
import App from "./routes";

const root = ReactDOM.createRoot(
	document.getElementById("app")!,
);

bootAPI(strings);

root.render(
	<React.StrictMode>
		<ChakraProvider theme={theme}>
			<GlobalStoreProvider>
				<BrowserRouter>
					<Routes>
						<Route
							path="*"
							element={<App  />}
						/>
					</Routes>
				</BrowserRouter>
			</GlobalStoreProvider>
		</ChakraProvider>
	</React.StrictMode>,
);
