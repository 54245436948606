import React from "react";
import { observer } from "mobx-react-lite";
import { Routes, Route } from "react-router-dom";
import { MainLayout } from "~/layout";
import {
	ClinicsShowInSite,
	ReprovedPosts,
	VetsShowInSite,
	HomePage,
	Posts,
	AdminMarketingUsers,
	AdminMarketingMaster,
	PendingPosts,
} from "~/pages";
import { useAuthAdminMarketingMasterRoute } from "~/hooks/useAuthAdminMarketingRoute";

const AdminMarketingRoutes = observer(() => {
	useAuthAdminMarketingMasterRoute();

	return (
		<MainLayout>
			<Routes>
				<Route
					path="/"
					element={<HomePage />}
				/>
				<Route path="adminMarketingMaster">
					<Route
						index
						element={<AdminMarketingMaster.TableView />}
					/>
					<Route
						path="create"
						element={<AdminMarketingMaster.CreateOrEdit />}
					/>
					<Route
						path="edit/:id"
						element={<AdminMarketingMaster.CreateOrEdit />}
					/>
					<Route
						path="details/:id"
						element={<AdminMarketingMaster.Details />}
					/>
				</Route>
				<Route path="adminMarketing">
					<Route
						index
						element={<AdminMarketingUsers.TableView />}
					/>
					<Route
						path="create"
						element={<AdminMarketingUsers.CreateOrEdit />}
					/>
					<Route
						path="edit/:id"
						element={<AdminMarketingUsers.CreateOrEdit />}
					/>
					<Route
						path="details/:id"
						element={<AdminMarketingUsers.Details />}
					/>
				</Route>
				<Route path="clinics">
					<Route
						index
						element={<ClinicsShowInSite.TableView />}
					/>
					<Route
						path="create"
						element={<ClinicsShowInSite.CreateOrEdit />}
					/>
					<Route
						path="edit/:id"
						element={<ClinicsShowInSite.CreateOrEdit />}
					/>
					<Route
						path="details/:id"
						element={<ClinicsShowInSite.Details />}
					/>
				</Route>

				<Route path="pendingPosts">
					<Route
						index
						element={<PendingPosts.TableView />}
					/>
					<Route
						path="details/:id"
						element={<PendingPosts.Details />}
					/>
				</Route>
				<Route path="reprovedPosts">
					<Route
						index
						element={<ReprovedPosts.TableView isMaster />}
					/>
					<Route
						path="details/:id"
						element={<ReprovedPosts.Details isMaster />}
					/>
				</Route>
				<Route path="vetsshowinsite">
					<Route
						index
						element={<VetsShowInSite.TableView />}
					/>
					<Route
						path="create"
						element={<VetsShowInSite.CreateOrEdit />}
					/>
					<Route
						path="edit/:id"
						element={<VetsShowInSite.CreateOrEdit />}
					/>
				</Route>
				<Route path="posts">
					<Route
						index
						element={<Posts.TableView />}
					/>
					<Route
						path="create"
						element={<Posts.CreateOrEdit />}
					/>
					<Route
						path="edit/:id"
						element={<Posts.CreateOrEdit />}
					/>
				</Route>
			</Routes>
		</MainLayout>
	);
});

export default AdminMarketingRoutes;


