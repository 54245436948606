import React from "react";
import { observer } from "mobx-react-lite";
import {
	FormControl,
	TextProps,
	FormControlProps,
	Textarea,
	TextareaProps,
} from "@chakra-ui/react";
import { Label } from "../";

type TLabelColor = "primary" | "secondary";

export interface IProps extends Omit<TextareaProps, "value"> {
	onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
	labelText?: string;
	value?: string | string[];
	labelProps?: TextProps;
	errorText?: string | null;
	labelColor?: TLabelColor;
	formControlProps?: FormControlProps;
}

export const TextArea: React.FC<IProps> = observer((props) => {
	const {
		onChange,
		labelText,
		labelProps,
		value,
		errorText,
		labelColor,
		formControlProps,
		...rest
	} = props;

	return (
		<FormControl
			isInvalid={!!errorText}
			position="relative"
			{...formControlProps}
		>
			<Label
				marginBottom={1}
				color={labelColor === "primary" ? "primary.500" : "gray.500"}
				{...labelProps}
			>
				{labelText}
			</Label>
			<Textarea
				minH="200px"
				borderWidth={2}
				backgroundColor="gray.100"
				_hover={{
					borderWidth: 2,
					borderColor: "primary.700",
				}}
				onChange={onChange}
				value={value}
				defaultValue={value || undefined}
				{...rest}
			/>

		</FormControl>
	);
});
