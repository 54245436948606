import React from "react";
import {
	Flex,
	Stack,
	Heading,
	BoxProps,
} from "@chakra-ui/react";
import {
	Card,
	Loading,
} from "~/components";

export interface IProps {
	title?: {
		text: string;
		helper?: React.ReactElement;
	};
	button?: React.ReactElement;
	isTable?: boolean;
	children?: React.ReactNode;
	isLoading?: boolean;
	boxProps?: BoxProps;
	altPaddingForCard?: boolean;
}

export const CentralizedCard: React.FC<IProps> = (props) => {
	const {
		title,
		button,
		children,
		isTable,
		isLoading,
		boxProps,
	} = props;

	return (
		<Flex
			w={{base:"95%",md:"100%"}}
			mx="auto"
			flexDirection="column"
			alignItems="center"
			mt={title ? 0 : 25}
		>
			{title && (
				<Flex
					w="100%"
					mx={{base:2, md: 0}}
					maxW={{ base:"95%", md:"75%", lg: "70%"}}
					mb={5}
					alignItems="center"
					{...boxProps}
				>
					<Heading
						display="inline-block"
						size="lg"
						color="primary.500"
						mt={25}
					>
						{title.text}
					</Heading>
					{title.helper}
				</Flex>
			)}
			<Card
				w="100%"
				maxW={{
					base:"100%",
					md:"75%",
					lg:"70%",
				}}
				p={6}
				mx={3}
				borderRadius={12}
				boxShadow={isTable ? 0 : "lg"}
				bg="white"
				mb={10}
			>
				{
					isLoading ? (
						<Loading />
					) : (
						<Stack w="100%" spacing={8}>
							{children}
						</Stack>
					)
				}
			</Card>
			{button}
		</Flex>
	);
};
