import React from "react";
import { observer } from "mobx-react-lite";
import {
	Avatar,
	Flex,
	Td,
	Text,
} from "@chakra-ui/react";
import { TableModel } from "~/components";
import strings from "~/resources/strings";
import ImagePlaceHolder from "../../../../static/pick_image.svg";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import format from "~/resources/format";
import api from "~/resources/api";

const TableView: React.FC = () => {
	const pageStrings = strings.pages.posts.table;
	const { authStore } = useGlobalStore();

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<TableModel
				initStoreSettings={{
					apiGetAllMethod: authStore.currentAdminUser?.type === api.AdminUserType.marketing ? api.getAllPostsForMarketingUser : api.getAllPosts,
					deleteShelfInitValues: {
						apiMethod: api.deletePost,
					},
				}}
				onSuccessMessage={pageStrings.delete}
				onAdd
				tableTitle={pageStrings.title}
				header={pageStrings.header}
				renderRow={(item) => (
					<>
						<Td>
							<Flex alignItems="center">
								<Avatar
									w={35}
									h={35}
									src={item.image ? item.image.url : ImagePlaceHolder}
									marginRight={7}
								/>
								<Text>{item.title}</Text>
							</Flex>
						</Td>
						<Td>{item.slug}</Td>
						<Td>{format.date(item.createdAt)}</Td>
					</>
				)}
				hasEdit
				hasDelete={!authStore.currentAdminUser?.clinic}
			/>
		</Flex>
	);
};

export default observer(TableView);
