import { makeAutoObservable } from "mobx";
import { FetchModelShelf, LoaderShelf } from "@startapp/mobx-utils";
import PendingPostShelf from "~/shelves/PendingPostShelf";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";

export class Store {

	public fetchModelShelf: FetchModelShelf<api.Post>;
	public pendingPostShelf = new PendingPostShelf();
	public loader = new LoaderShelf();

	constructor(id: string) {
		makeAutoObservable(this);
		this.fetchModelShelf = new FetchModelShelf(id, ()=> api.getPostById(id),
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		) ;
	}

}
