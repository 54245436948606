import React from "react";
import { observer} from "mobx-react-lite";
import { Flex } from "@chakra-ui/react";

export const HomePage: React.FC = observer(() => (
	<Flex
		justifyContent="center"
		direction="column"
		mx="auto"
		w="100%"
	>
		Aqui é a home.
	</Flex>
));

