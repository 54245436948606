import React from "react";
import { observer } from "mobx-react-lite";
import { Grid, Heading } from "@chakra-ui/react";
import { DetailsRow } from "~/components";
import strings from "~/resources/strings";
import api from "~/resources/api";

export interface IProps {
	address: api.Address | undefined;
}

export const ClinicAddressDetails: React.FC<IProps> = observer((props) => {
	const { address } = props;
	const commonStrings = strings.common;

	return (
		<>
			<Heading fontSize="md" color="primary.500">{strings.common.fields.address}</Heading>
			<Grid gap={4} gridTemplateColumns="1fr 1fr">
				<DetailsRow
					label={commonStrings.fields.street}
					value={address?.street || ""}
				/>
				<DetailsRow
					label={commonStrings.fields.streetNumber}
					value={address?.streetNumber || ""}
				/>
				<DetailsRow
					label={commonStrings.fields.complementary}
					value={address?.complementary || ""}
				/>
				<DetailsRow
					label={commonStrings.fields.neighborhood}
					value={address?.neighborhood || ""}
				/>
				<DetailsRow
					label={commonStrings.fields.city}
					value={address?.city || ""}
				/>
				<DetailsRow
					label={commonStrings.fields.state}
					value={address?.state || ""}
				/>
				<DetailsRow
					label={commonStrings.fields.zipcode}
					value={address?.zipcode || ""}
				/>
			</Grid>
		</>
	);
});
