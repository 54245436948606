import "moment/locale/pt-br";
import LocalizedStrings from "localized-strings";
import format from "./format";

const strings = new LocalizedStrings({
	ptBR: {
		enum: {
			StateUF:{
				AC: "Acre",
				AL: "Alagoas",
				AP: "Amapá",
				AM: "Amazonas",
				BA: "Bahia",
				CE: "Ceará",
				DF: "Distrito Federal",
				ES: "Espírito Santo",
				GO: "Goiás",
				MA: "Maranhão",
				MT: "Mato Grosso",
				MS: "Mato Grosso do Sul",
				MG: "Minas Gerais",
				PA: "Pará",
				PB: "Paraíba",
				PR: "Paraná",
				PE: "Pernambuco",
				PI: "Piauí",
				RJ: "Rio de Janeiro",
				RN: "Rio Grande do Norte",
				RS: "Rio Grande do Sul",
				RO: "Rondônia",
				RR: "Roraima",
				SC: "Santa Catarina",
				SP: "São Paulo",
				SE: "Sergipe",
				TO: "Tocantins",
			},
		},
		components: {
			imagePicker: {
				selectAnImage: "Selecione uma imagem:",
				pdf: "PDF:",
				await: "Aguarde...",
			},
			tooltip: "Clique para visualizar a imagem",
		},
		actions: {
			add: "Adicionar",
			back: "Voltar",
			clear: "Limpar",
			cancel: "Cancelar",
		},
		nav: {
			home: "Home",
			adminMarketing: "Administradores Marketing",
			adminMarketingMaster: "Administradores Marketing Master",
			clinics: "Clínicas - Site",
			reprovedPosts: "Posts Reprovados",
			pendingPosts: "Posts Pendentes",
			vetsShowInSite: "Minha Equipe",
			logout: "Sair",
			posts: "BLOG",
		},
		moment: {
			day: "DD",
			dateMonth: "DD/MM",
			date: "DD/MM/YYYY",
			dateHour: "DD/MM/YYYY HH[h]",
			dateTime: "DD/MM/YYYY HH:mm",
		},
		error: {
			stillLoading: "Ainda Carregando, aguarde.",
			notLoggedIn: "Usuario não logado",
			default: "Houve um erro, tente novamente mais tarde.",
			currentUserIsNotMarketing: "O Usuário não é marketing.",
			currentUserIsNotAdminMaster: "O Usuário não é admin master.",
			currentUserIsNotAdminMarketingMaster: "O Usuário não é Administrador Marketing",
			selectImage:"É necessário selecionar uma imagem.",
		},
		common: {
			yes: "Sim",
			approve: "Aprovar",
			send: "Enviar",
			details: "Detalhes",
			edit: "Editar",
			delete: "Apagar",
			enable: "Habilitar",
			disable: "Bloquear",
			noResults: "Sem resultados.",
			no: "Não",
			create: "Criar",
			empty: "Campo vazio",
			detailsTitle: "Detalhes",
			fields: {
				name: "Nome",
				id: "ID",
				email: "Email",
				cnpj: "CNPJ",
				photo: "Foto",
				services: "Serviços",
				address: "Endereço",
				phone: "Telefone",
				streetNumber: "Número",
				street: "Rua",
				complementary: "Complemento",
				cpf: "CPF",
				city: "Cidade",
				state: "Estado",
				zipcode: "CEP",
				password: "Senha",
				neighborhood: "Bairro",
				clinicName: "Nome da clínica",
				text: "Texto:",
				slug: "Slug:",
				title: "Título:",
				description: "Descrição:",
				review: "Revisão:",
				author: "Autor do Post",
				createdAt: "Criado em",
				reviewOfAdmin: "Revisão do Admin",
				reasonForRejection: "Motivo da rejeição:",
			},
			mask: {
				date: "__/__/____",
				time: "__:__",
			},
			modal: {
				title: "Deletar",
				titleReprove: "Reprovar",
				titleReproveOrRefuse: "Aprovar ou recusar?",
				description: (itemName: string) => `Tem certeza que deseja deletar ${itemName}?`,
				descriptionApprove: "Tem certeza que deseja aprovar?",
				button: {
					approve: "Aprovar",
					finish: "Finalizar",
					refuse: "Recusar",
					remove: "Remover",
					delete: "Deletar",
					cancel: "Cancelar",
					block: "Bloquear",
					disable: "Bloquear",
					enable: "Desbloquear",
					confirm: "Confirmar",
				},
			},
			buttons: {
				add: "Adicionar",
				approve: "Aprovar",
				refuse: "Recusar",
				backButton: "Voltar",
				confirmButton: (isEdit?: boolean) => isEdit ? "Editar" : "Enviar",
				saveOrEdit: (isEdit?: boolean) => isEdit ? "Editar" : "Salvar",
			},
		},
		pages: {
			login: {
				loginIn: "Login",
				altImg: "Connect-vet logo",
				recoveryPassword: "Recuperar Senha",
				success: (userName: string) => `Bem vindo ${userName}.`,
				fields: {
					email: "E-mail",
					password: "Senha",
				},
			},
			recoveryPage: {
				success: "Bem vindo!",
				altImg: "Connect-vet logo",
				title: "Recuperação de Senha",
				newAccess: "Novo Acesso",
				sendSuccess: "E-mail enviado com sucesso!",
				recoverPasswordButton: "Enviar e-mail",
				newPasswordButton: "Alterar Senha",
				email: "Email",
				token: "Token",
				validToken: "Token válido!",
				confirmTokenButton: "Confirmar Token",
				tokenButton: "Já possui um token?",
				noTokenButton: "Não possuo token",
				newPassword: "Nova senha",
				confirmNewPassword: "Confirme sua nova senha",
				samePasswordError: "As senhas não correspondem",
				successPassword: "Senha alterada com sucesso",
				shortPassword: "Senha muito curta",
			},
			clinics: {
				table: {
					title: "Clínicas - Site",
					header: ["Clínica", "Código", "E-mail", "Telefone", "CNPJ"],
				},
				createOrEdit: {
					title: (isEdit?: boolean) => isEdit ? "Editar Clínica" : "Cadastrar Clínica",
					success: (isEdit?: boolean) => isEdit ? "Clínica editada com sucesso" : "Clínica criada com sucesso",
				},
			},
			posts: {
				table: {
					title: "Lista de Blogs",
					header: ["Postagens", "Slug", "Data", ""],
					delete: "Blog deletado com sucesso!",
					success: (blog: string) => `Blog ${blog} deletado com sucesso!`,
				},
				createOrEdit: {
					title: (isEdit?: boolean) => isEdit ? "Editar Postagem" : "Nova Postagem",
					success: (isEdit?: boolean) => isEdit ? "Post editado com sucesso" : "Post criado com sucesso",
					successMarketing: (isEdit?: boolean) => isEdit ? "Post editado com sucesso" : "Seu post foi enviado para aprovação do Administrador.",
					textAddImage: "Clique em adicionar para escolher uma imagem",
					titlePlaceholder: "Título da postagem",
					slugPlaceholder: "Slug da postagem",
				},
			},
			reprovedPosts: {
				table: {
					title: "Lista de Posts Reprovados",
					headers: [ "Clinica", "Título", "Slug", "Criado Em", ""],
				},
			},
			pendingPost: {
				title: "Postagens Pendentes",
				success: "Post aprovado!",
				refuse: "Post reprovado!",
				headers: ["Título", "Slug", "Autor do Post", "Criado em: ", ""],
			},
			vetsShowInSite: {
				table: {
					title: "Minha Equipe",
					delete: "Veterinário deletado com sucesso!",
					header: ["Nome do Veterinário", "Descrição", ""],
				},
				createOrEdit: {
					title: (isEdit?: boolean) => isEdit ? "Editar Veterinário" : "Nova Veterinário",
					success: (isEdit?: boolean) => isEdit ? "Veterinário editado com sucesso" : "Veterinário criado com sucesso",
				},
			},
			adminMarketing: {
				table: {
					title: "Lista de Administradores Marketing",
					header: ["Nome", "Email", "CPF", ""],
					delete: "Administrador deletado com sucesso!",
					totalText: "Administrador cadastrados",
					addButtonText: "Cadastrar Administrador",
				},
				createOrEdit: {
					title: (isEdit?: boolean) => isEdit ? "Editar Administrador" : "Cadastrar Administrador",
					success: (isEdit?: boolean) => isEdit ? "Administrador editado com sucesso" : "Administrador criado com sucesso",
				},
			},
			adminMarketingMaster: {
				table: {
					title: "Lista de Administradores Marketing Master",
					header: ["Nome", "Email", "CPF", ""],
					delete: "Administrador deletado com sucesso!",
					totalText: "Administrador cadastrados",
					addButtonText: "Cadastrar Administrador",
				},
				createOrEdit: {
					title: (isEdit?: boolean) => isEdit ? "Editar Administrador" : "Cadastrar Administrador",
					success: (isEdit?: boolean) => isEdit ? "Administrador editado com sucesso" : "Administrador criado com sucesso",
				},
			},
		},
		format,
	},

});

export default strings;
