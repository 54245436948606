const orange = {
	50: "#faf0e7",
	150: "#f5e1d0",
	200: "#f0d3b9",
	250: "#ebc4a1",
	300: "#e6b58a",
	350: "#e1a773",
	400: "#dc985b",
	450: "#d78944",
	500: "#d37b2d",
	550: "#bb6d28",
	600: "#a45f23",
	650: "#8c521e",
	700: "#754419",
};

const blue = {
	50: "#e5eaf0",
	150: "#ccd5e2",
	200: "#b3c1d3",
	250: "#b3c1d3",
	300: "#9aacc5",
	350: "#6883a8",
	400: "#4f6f99",
	450: "#365a8b",
	500: "#1d467d",
	550: "#193e6f",
	600: "#163661",
	650: "#132e53",
	700: "#102645",
};

export const colors = {
	primary: orange,
	orange,
	blue,
};
